import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectSelectedItem,
    setSelectedAppointment,
} from '../../features/appointment/appointmentSlice';
import { IsArrayWithLength } from '../../utilities/extensions';
import { Button, Modal } from 'antd';
import {
    useDeletePatientConsultMutation,
    useGetConsultNoteTypeOptionsQuery,
    usePatientConsultReportMutation,
} from '../../features/appointment/appointmentService';
import { bindActionCreators } from '@reduxjs/toolkit';
import { Utility } from '../../utilities/Utility';

const { confirm } = Modal;
const showDeleteConfirm = ({ onConfirm, item }) => {
    const {
        scheduledDateTime,
        consultReasonDisplay,
        teamDisplay,
        dateDisplay,
        startTimeDisplay,
        listOfPatients = [],
    } = item;
    const content =
        scheduledDateTime != null
            ? `Are you sure you want to delete the following consult: ${consultReasonDisplay} with ${teamDisplay} on ${dateDisplay} ${startTimeDisplay}?`
            : `Are you sure you want to delete the following Clinical Consult Request: ${consultReasonDisplay} for ${listOfPatients[0].value.patientNameDisplay} in ${teamDisplay}?`;
    return confirm({
        title: 'Are you sure?',
        content: content,
        onOk: onConfirm,
        okText: 'Delete',
        okButtonProps: { danger: true },
    });
};

const AppointmentDetails = ({ onEditMeeting }) => {
    const dispatch = useDispatch();
    const item = useSelector(selectSelectedItem);
    const {
        meetingCompleted,
        completedOnDisplay,
        completedBy,
        consultReasonDisplay,
        notes,
        consultNotes,
        teamDisplay,
        listOfPatients = [],
        scheduledDateTime = null,
        consultRequest: {
            userDisplay = '',
            userContactEmailDisplay = '',
            userContactPhoneDisplay = '',
        } = {},
        dateDisplay,
        startTimeDisplay,
        duration,
    } = item;
    const { data: options = [], isLoading: isConsultNotesLoading } =
        useGetConsultNoteTypeOptionsQuery();
    const [
        PatientConsultReport,
        { data, isLoading: isGeneratingReport, error },
    ] = usePatientConsultReportMutation();
    const hasDischargedPatient =
        listOfPatients.filter((i) =>
            i.value.patientStatusDisplay.includes('Discharged'),
        ).length > 0;
    const [DeletePatientConsult, { isLoading: deleteInProcess }] =
        useDeletePatientConsultMutation();
    const apACs = bindActionCreators({ setSelectedAppointment }, dispatch);

    const onDeletePatientConsult = useCallback(async () => {
        const result = await DeletePatientConsult(item.id).unwrap();
        if (result?.success) apACs.setSelectedAppointment({});
    }, [onEditMeeting]);

    useEffect(() => {
        if (error?.data?.title) {
            Utility.errorToast(
                'An error has occurred. Please, contact your administrator.',
            );
        }
    }, [error]);

    return consultReasonDisplay !== undefined ? (
        <div className="d-flex flex-column flex-grow-1 ms-3 mt-4">
            <div className="d-flex justify-content-between flex-grow-1l mb-2">
                <div className="h5">{consultReasonDisplay}</div>
                <div className="d-flex flex-column align-items-end">
                    {scheduledDateTime !== null && [
                        <div key="dateDisplay">
                            <strong>{dateDisplay}</strong>
                        </div>,
                        <div key="startTimeDisplay">{startTimeDisplay}</div>,
                        <div key="duration">{duration}m</div>,
                    ]}
                    <Button
                        className="mt-2"
                        type="primary"
                        onClick={(e) => onEditMeeting(item)}
                        danger={scheduledDateTime == null}
                        hidden={hasDischargedPatient}
                    >
                        {scheduledDateTime == null
                            ? 'Schedule Consult Request'
                            : 'Edit'}
                    </Button>
                </div>
            </div>
            <div className="d-flex flex-column label-value">
                <div>
                    <strong>Team:</strong> {teamDisplay}
                </div>
                <div>
                    <strong>Requesting User:</strong> {userDisplay}
                </div>
                <div>
                    <strong>Contact Email:</strong> {userContactEmailDisplay}
                </div>
                <div>
                    <strong>Contact Phone:</strong> {userContactPhoneDisplay}
                </div>
            </div>
            <div className="d-flex flex-column pt-3">
                <strong>Patient(s):</strong>
                <ul className="list-unstyled ps-2">
                    {IsArrayWithLength(listOfPatients) &&
                        listOfPatients.map((l, i) => (
                            <li key={i}>
                                {l.value.patientNameDisplay} - [
                                <i>{l.value.patientStatusDisplay}</i>]
                            </li>
                        ))}
                </ul>
            </div>
            <div className="d-flex flex-column pt-3">
                {notes !== null && (
                    <>
                        <strong>Notes:</strong>
                        <p>{notes}</p>
                    </>
                )}
                {meetingCompleted &&
                    options.map((i) => (
                        <React.Fragment key={i.key}>
                            <strong>{i.name}:</strong>
                            <p>
                                {
                                    consultNotes.find(
                                        (note) => note.key === i.value,
                                    )?.value
                                }
                            </p>
                        </React.Fragment>
                    ))}
            </div>
            {meetingCompleted && [
                <div key="ms" className="d-flex gap-1 pt-3">
                    <strong>Consult Status:</strong>
                    <p>Completed</p>
                </div>,
                <div key="cby" className="d-flex gap-1 pt-3">
                    <strong>Completed by:</strong>
                    <p>{completedBy}</p>
                </div>,
                <div key="cdate" className="d-flex gap-1 pt-3">
                    <strong>Completed on date & time:</strong>
                    <p>{completedOnDisplay}</p>
                </div>,
            ]}
            <div className="d-flex justify-content-end pt-3 w-100">
                {!meetingCompleted && (
                    <Button
                        type="primary"
                        onClick={(e) =>
                            showDeleteConfirm({
                                onConfirm: onDeletePatientConsult,
                                item,
                            })
                        }
                        loading={deleteInProcess}
                    >
                        Delete
                    </Button>
                )}
            </div>
            {/* Hide consult report button until we have endpoint working */}
            <div className="d-flex justify-content-start pt-3 w-100">
                {meetingCompleted && (
                    <Button
                        className="mt-2"
                        type="primary"
                        onClick={(e) => PatientConsultReport(item.id)}
                        danger={scheduledDateTime == null}
                        hidden={hasDischargedPatient}
                        loading={isGeneratingReport}
                    >
                        Consult Report
                    </Button>
                )}
            </div>
        </div>
    ) : (
        ''
    );
};

export default AppointmentDetails;
