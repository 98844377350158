import { createSlice } from '@reduxjs/toolkit';
import { patientAPI } from './patientService';
import {RootState} from "../appStore";
import { AnyObject } from 'yup/lib/types';
import { PatientDetailsResponse } from '../../models/PatientModels';

interface PatientState {
  patientResults: AnyObject;
  patientDetails: PatientDetailsResponse;
  loading: boolean;
  error: any;
  patientRecord: AnyObject;
  selectedPatientId:string | null;
  dropdownOptions: any;
  practitionerOptions: any;
  teamOptions: any;
  teamOptionTableData: any;
}

const initialState: PatientState = {
  patientResults: {},
  patientDetails: {} as PatientDetailsResponse,
  loading: false,
  error: {},
  patientRecord: {},
  selectedPatientId: null,
  dropdownOptions: [],
  practitionerOptions: [],
  teamOptions: [],
  teamOptionTableData: [],
};

const {
  matchPending: searchResultPending,
  matchRejected: searchResultRejected,
  matchFulfilled: searchResultFulfilled,
} = patientAPI.endpoints.patientSearchResult;

const {
  matchPending: patientDetailsPending,
  matchRejected: patientDetailsRejected,
  matchFulfilled: patientDetailsFulfilled,
} = patientAPI.endpoints.patientDetails;

const {
  matchPending: postPatientRecordPending,
  matchRejected: postPatientRecordRejected,
  matchFulfilled: postPatientRecordFulfilled,
} = patientAPI.endpoints.postPatientRecord;

const {
  matchPending: getDropdownOptionsListPending,
  matchRejected: getDropdownOptionsListRejected,
  matchFulfilled: getDropdownOptionsListFulfilled,
} = patientAPI.endpoints.getDropDownOptions;

const {
  matchPending: getPractiontionerOptionsListPending,
  matchRejected: getPractiontionerOptionsListRejected,
  matchFulfilled: getPractiontionerOptionsListFulfilled,
} = patientAPI.endpoints.getPractiontionerOptions;

const {
  matchPending: fetchTeamOptionsListPending,
  matchRejected: fetchTeamOptionsListRejected,
  matchFulfilled: fetchTeamOptionsListFulfilled,
} = patientAPI.endpoints.fetchTeamOptions;

const {
  matchPending: fetchTeamOptionsTableDataListPending,
  matchRejected: fetchTeamOptionsTableDataListRejected,
  matchFulfilled: fetchTeamOptionsTableDataListFulfilled,
} = patientAPI.endpoints.fetchTeamOptionsTableData;


export const SLICE_PATIENT_NAME = "patient";

const patientSlice = createSlice({
  name: 'patient',
  initialState,
  reducers: {
      updatePatientResult: (state, action) => {
          state.patientResults = action.payload;
      },
      setSelectedPatientId: (state, action) => {
          state.selectedPatientId = action.payload;
      }
  },
  extraReducers: (builder) => {
      builder
          .addMatcher(searchResultPending, (state) => {
              state.loading = true;
          })
          .addMatcher(searchResultRejected, (state, action) => {
              state.loading = false;
              state.error = action.payload;
          })
          .addMatcher(searchResultFulfilled, (state, action) => {
              state.patientResults = action.payload;
              state.loading = false;
          })

          .addMatcher(patientDetailsPending, (state) => {
              state.loading = true;
          })
          .addMatcher(patientDetailsRejected, (state, action) => {
              state.loading = false;
              state.error = action.payload;
          })
          .addMatcher(patientDetailsFulfilled, (state, action) => {
              state.patientDetails = action.payload;
              state.loading = false;
          })

          .addMatcher(postPatientRecordPending, (state) => {
              state.loading = true;
          })
          .addMatcher(postPatientRecordRejected, (state, action) => {
              state.loading = false;
              state.error = action.payload;
          })
          .addMatcher(postPatientRecordFulfilled, (state, action) => {
              state.patientRecord = action.payload;
              state.loading = false;
          })
          /////////////////////////////////////////////////////
          .addMatcher(getDropdownOptionsListPending, (state) => {
              state.loading = true;
          })
          .addMatcher(getDropdownOptionsListRejected, (state, action) => {
              state.loading = false;
              state.error = action.payload;
          })
          .addMatcher(getDropdownOptionsListFulfilled, (state, action) => {
              state.dropdownOptions = action.payload;
              state.loading = false;
          })



          .addMatcher(getPractiontionerOptionsListPending, (state) => {
              state.loading = true;
          })
          .addMatcher(getPractiontionerOptionsListRejected, (state, action) => {
              state.loading = false;
              state.error = action.payload;
          })
          .addMatcher(getPractiontionerOptionsListFulfilled, (state, action) => {
              state.practitionerOptions = action.payload;
              state.loading = false;
          })

          .addMatcher(fetchTeamOptionsListPending, (state) => {
              state.loading = true;
          })
          .addMatcher(fetchTeamOptionsListRejected, (state, action) => {
              state.loading = false;
              state.error = action.payload;
          })
          .addMatcher(fetchTeamOptionsListFulfilled, (state, action) => {
              state.teamOptions = action.payload;
              state.loading = false;
          })
          .addMatcher(fetchTeamOptionsTableDataListPending, (state) => {
              state.loading = true;
          })
          .addMatcher(fetchTeamOptionsTableDataListRejected, (state, action) => {
              state.loading = false;
              state.error = action.payload;
          })
          .addMatcher(fetchTeamOptionsTableDataListFulfilled, (state, action) => {
              state.teamOptionTableData = action.payload;
              state.loading = false;
          });
  },
});
export const selectPatientResult = (state: RootState) => state[SLICE_PATIENT_NAME];

export const selectPatientId = (state: RootState): string | null =>
  state.patient.selectedPatientId;


export const dropdownOptions = (state: RootState): string | null => {
  return state.patient.dropdownOptions;
};

export const practitionerOptions = (state: RootState): string | null => {
  return state.patient.practitionerOptions;
};

export const { updatePatientResult, setSelectedPatientId } = patientSlice.actions;

export default patientSlice;