import { createSlice } from '@reduxjs/toolkit';
import { DataFailuresResponse } from '../../models/FailuresResponse';
import { RootState } from '../appStore';
import { dataFailuresApi } from './failuresService';
import { defaultFailuresFilter } from '../../components/Schema/failuresSchema';

const pageResponse: DataFailuresResponse = {
   data: [],
   pageNumber: 1,
   totalPages: 0,
   totalCount: 0,
   pageSize: 10,
   hasPreviousPage: false,
   hasNextPage: false,
};

const initialState = {
   failures: {
      isLoading: false,
      filter: defaultFailuresFilter,
      pageResponse,
   },
};
const {matchPending,matchRejected,matchFulfilled} = dataFailuresApi.endpoints.dataFailures;

export const SLICE_FAILURES_NAME = 'failures';
export const failuresSlice = createSlice({
   name: SLICE_FAILURES_NAME,
   initialState,
   reducers: {
      setFailuresFilter: (state, { payload }) => {
         state.failures.filter = payload;
      },
   },
   extraReducers: (builder) => {
      builder
         .addMatcher(matchPending,
            ({ failures }, { payload }) => {
               failures.isLoading = true;
            },
         )
         .addMatcher(matchRejected,
            ({ failures }, { payload }) => {
               failures.isLoading = false;
            },
         )
         .addMatcher(matchFulfilled,
            ({ failures }, { payload }) => {
               failures.pageResponse = payload;
               failures.isLoading = false;
            },
         );
   },
});

export const { setFailuresFilter } = failuresSlice.actions;

export default failuresSlice.reducer;

export const selectDataFailuresState = (state: RootState) => state[SLICE_FAILURES_NAME];
export const selectFailuresState = (state: RootState) => state[SLICE_FAILURES_NAME].failures;
export const selectFailuresFilter = (state: RootState) => state[SLICE_FAILURES_NAME].failures.filter;
export const selectFailures = (state: RootState) => state[SLICE_FAILURES_NAME].failures.pageResponse;
