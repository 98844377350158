import { Tooltip } from 'antd';
import { formatDateTo } from '../../utilities/extensions';

function _copyAndSort(items, columnKey, isSortedDescending) {
   const key = columnKey;
   return items
      .slice(0)
      .sort((a, b) =>
         (isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1,
      );
}

export const columns = [
   {
      key: 'accountId',
      title: 'Account ID',
      dataIndex: ['userIdentifier'],
      ariaLabel: 'Account ID',
      width: 100,
      ellipsis: {
         showTitle: false,
      },
      render: (value) => (
         <Tooltip placement="topLeft" title={value}>
            {value}
         </Tooltip>
      ),
   },
   {
      key: 'accountName',
      title: 'Account Name',
      dataIndex: 'userEmail',
      ariaLabel: 'Account Name',
      width: 100,
      ellipsis: {
         showTitle: false,
      },
      render: (value) => (
         <Tooltip placement="topLeft" title={value}>
            {value}
         </Tooltip>
      ),
   },
   {
      key: 'lastAccessDate',
      title: 'Last Accessed',
      dataIndex: ['connectedOn'],
      ariaLabel: 'Last Accessed',
      width: 100,
      ellipsis: {
         showTitle: false,
      },
      render: (value) => {
         const formattedDate = formatDateTo(value, 'MM/dd/yyyy h:mm a');
         return (
            <Tooltip placement="topLeft" title={formattedDate}>
               {formattedDate}
            </Tooltip>
         );
      },
   },
   {
      key: 'deviceName',
      title: 'Device Name',
      dataIndex: ['deviceName'],
      ariaLabel: 'Device Name',
      width: 100,
      ellipsis: {
         showTitle: false,
      },
      render: (value) => (
         <Tooltip placement="topLeft" title={value}>
            {value}
         </Tooltip>
      ),
   },
];

export const skeletonColumns = [
   { key: 'ex', title: '', width: 50, fixed: 'left' },
   ...columns,
];
