import React, { useEffect, useState } from 'react';
import { Button, Typography, Space, Table, Tag, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import { RightOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { UsDateformat } from '../../utilities/extensions';
import '../../styles/_patientResult.scss';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import {
   selectPatientResult,
   updatePatientResult,
} from '../../features/patient/patientSlice';
import { usePatientDetailsMutation } from '../../features/patient/patientService';
const PatientResults = () => {
   const navigate = useNavigate();
   const [patientDetails] = usePatientDetailsMutation();
   const { Title, Text } = Typography;
   const [modalVisible, setModalVisible] = useState(false);
   const [selectedRecord, setSelectedRecord] = useState(null);
   const resultPatient = useSelector(selectPatientResult, shallowEqual);
   const dispatch = useDispatch();

   const getPatientDetails = async (_id) => {
      try {
         const response = await patientDetails(_id);
         return response;
      } catch (error) {
         return error;
      }
   };

   const showModal = (record) => {
      setSelectedRecord(record);
      setModalVisible(true);
   };

   const handleCancel = () => {
      setModalVisible(false);
   };

   const handleConfirm = async () => {
      const response = await getPatientDetails(selectedRecord.patientId);
      dispatch(updatePatientResult(selectPatientResult.patientId));
      navigate('/patient/' + selectedRecord.patientId, {
         state: {
            record: selectedRecord,
         },
      });
      setModalVisible(false);
   };

   // For the modal box
   const renderContent = () => {
      if (!selectedRecord) {
         return null;
      }

      return (
         <>
            <div className="display-flex">
               <ExclamationCircleOutlined className="icon-style" />
               <p className="para-styles">Confirm</p>
            </div>
            <p className="para-text">
               {`Confirm you want to look at ${selectedRecord.lastName}, ${selectedRecord.firstName} profile`}
            </p>
         </>
      );
   };

   const detailsStyle = (text) => {
      return (
         <Text strong className="text-color">
            {text}
         </Text>
      );
   };

   const headerTitleStyle = (text) => {
      return <Text className="header-class ">{text}</Text>;
   };

   const columns = [
      {
         title: headerTitleStyle('Name'),
         dataIndex: 'name',
         key: 'name',
         render: (key, record) => (
            <Text className="patient-fullname">
               {`${record.lastName}, ${record.firstName}`}
            </Text>
         ),
      },
      {
         title: headerTitleStyle('Birthday'),
         dataIndex: 'dateOfBirth',
         key: 'dateOfBirth',
         render: (dateOfBirth) => detailsStyle(UsDateformat(dateOfBirth)),
      },
      {
         title: headerTitleStyle('HCRx Patient ID#'),
         dataIndex: 'hrxPatientId',
         key: 'hrxPatientId',
         render: (text) => detailsStyle(text),
      },
      {
         title: headerTitleStyle('Medical Record#'),
         key: 'medicalRecordId',
         dataIndex: 'medicalRecordId',
         render: (text) => detailsStyle(text),
      },
      {
         title: headerTitleStyle('Status'),
         dataIndex: 'patientStatusDisplay',
         key: 'patientStatusDisplay',
         render: (text) => detailsStyle(text),
      },
      {
         title: '',
         key: 'action',
         render: (key, record) => (
            <div>
               <RightOutlined onClick={() => showModal(record)} />
            </div>
         ),
      },
   ];

   return (
      <>
         <div className="patient-result">
            <Title level={1} className="title-class">
               Patient Result ({resultPatient?.patientResults?.data.length})
            </Title>
            <Button
               className="clear-search"
               onClick={() => {
                  dispatch(updatePatientResult({}));
                  navigate('/search-patient');
               }}
            >
               Clear Search
            </Button>
         </div>
         <Table
            columns={columns}
            dataSource={resultPatient?.patientResults?.data}
            className="custom-table"
         />
         {selectedRecord && (
            <Modal
               visible={modalVisible}
               closable={false}
               footer={[
                  <Button key="cancel" onClick={handleCancel}>
                     CANCEL
                  </Button>,
                  <Button key="confirm" type="primary" onClick={handleConfirm}>
                     CONFIRM
                  </Button>,
               ]}
            >
               {renderContent()}
            </Modal>
         )}
      </>
   );
};

export default PatientResults;
