import {baseApiService} from "../baseApiService";
import {
    ConfirmEmailRequest,
    ForgotPasswordRequest,
    LoginRequest,
    MessageResponse,
    ResetPasswordRequest,
    UnsubscribeEmailRequest
} from "../../models/LoginRequest";
import {UserResponse} from "../../models/UserResponse";
import {Profile} from "../../models/Profile";

export const authApi = baseApiService.injectEndpoints({
    endpoints: (builder) => ({
        login: builder.mutation<UserResponse, LoginRequest>({
            query: (credentials) => ({
                url: 'tokens',
                method: 'POST',
                body: credentials,
            }),
        }),
        resetPassword: builder.mutation<MessageResponse, ResetPasswordRequest>({
            query: (resetData) => ({
                url: 'users/reset-password',
                method: 'POST',
                body: resetData,
            }),
        }),
        forgotPassword: builder.mutation<MessageResponse, ForgotPasswordRequest>({
            query: (fpData) => ({
                url: 'users/forgot-password',
                method: 'POST',
                body: fpData,
            }),
        }),
        profile: builder.mutation<string, Profile>({
            query: (token) => ({
                url: 'personal/profile',
                method: 'GET',
                headers: {
                    'authorization': `Bearer ${token}`,
                },
            }),
        }),
        protected: builder.mutation<{ message: string }, void>({
            query: () => 'protected',
        }),
        confirmEmail: builder.mutation<MessageResponse, ConfirmEmailRequest>({
            query: ({code,userId,tenant}) => ({
                url: `users/confirm-email?code=${code}&userId=${userId}&tenant=${tenant}`,
                method: 'GET'
            }),
        }),
        unsubscribeEmail: builder.mutation<MessageResponse, UnsubscribeEmailRequest>({
            query: ({userEmail,tenant}) => ({
                url: `users/unsubscribe-email?userEmail=${userEmail}&tenant=${tenant}`,
                method: 'GET'
            }),
        })
    }),
})

export const { useLoginMutation, useProfileMutation, useProtectedMutation, useResetPasswordMutation, useForgotPasswordMutation, useConfirmEmailMutation, useUnsubscribeEmailMutation } = authApi
