import { useState, useEffect } from 'react';
import { Typography, Form } from 'antd';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import '../../styles/_patientRecord.scss';
import { shallowEqual, useSelector } from 'react-redux';
import { selectPatientResult } from '../../features/patient/patientSlice';
import { usePatientDetailsMutation } from '../../features/patient/patientService';
import { AnyObject } from 'yup/lib/types';
import PatientAdmition from '../../components/Patient/PatientAdmition';
import PatientViewDetais from '../../components/Patient/PatientViewDetails';
interface PatientData {
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    hrxPatientId: string;
    medicalRecordId: string;
    patientStatusDisplay: string;
}

const PatientRecord = () => {
    const { Title } = Typography;
    const navigate = useNavigate();
    const location = useLocation();
    const { id: patientId } = useParams();

    const [updateMode, setUpdateMode] = useState(false);
    const [patientDetails] = usePatientDetailsMutation();
    const patient_details = useSelector(selectPatientResult, shallowEqual);
    let data: PatientData | AnyObject | any = patient_details?.patientDetails;

    const getPatientDetails = async (_id: any) => {
        try {
            const response = await patientDetails(_id);
            return response;
        } catch (error) {
            return error;
        }
    };

    const getPatientData = async (patientId: any) => {
        const response: any = await getPatientDetails(patientId);
        data = response.data;
    };

    useEffect(() => {
        if (location?.state?.action === 'EDIT') {
            setUpdateMode(true);
        }
        const fetchData = async () => {
            if (
                (Boolean(data) && JSON.stringify(data) === '{}') ||
                Boolean(data) === false
            ) {
                await getPatientData(patientId);
            }
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [form] = Form.useForm();
    const [isError, setIsError] = useState<boolean>(false);
    const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

    const modalContents = {
        doneModal: {
            label: 'Changes Saved',
            message: `Your changes have been saved.`,
            iconStyle: 'DoneModalIcon',
        },
        WarningModal: {
            label: 'Failed',
            message: `I will loose the changes if I continue`,
            iconStyle: 'WarningModalIcon',
        },
    };

    const onNavigation = () => {
        if (isError) {
            setShowErrorModal(true);
        } else {
            navigate(`/patient/${patientId}`);
        }
    };

    return (
        <>
            <div className="search-patient">
                <LeftOutlined
                    className="left-arrow"
                    onClick={onNavigation}
                    rev={undefined}
                />
                <Title level={1} className="title-class">
                    {'Patient Record'}
                </Title>
            </div>
            {updateMode ? (
                <PatientAdmition
                    form={form}
                    showMRN={false}
                    updateMode={true}
                    patientData={data}
                    modalContents={modalContents}
                    setIsError={setIsError}
                    showErrorModal={showErrorModal}
                />
            ) : (
                <PatientViewDetais
                    form={form}
                    patientData={data}
                    setUpdateMode={setUpdateMode}
                />
            )}
        </>
    );
};
export default PatientRecord;
