import React, { useEffect, useState } from 'react';
import { useSpinnerProvider } from '../../components/Spinner/SpinnerProvider';
import {
   Button,
   Input,
   Typography,
   Table,
   Tabs,
   Card,
   Dropdown,
   Menu,
   Row,
   Col,
} from 'antd';
import '../../styles/_dashboard.scss';
import { useNavigate } from 'react-router-dom';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import {
   LeftOutlined,
   DownOutlined,
   RightOutlined,
   PlusCircleOutlined,
   PlusOutlined,
   SearchOutlined,
} from '@ant-design/icons';
import {
   selectPatientResult,
   selectPatientId,
   updatePatientResult,
   //    dropdownOptions ,
   //    practitionerOptions
} from '../../features/patient/patientSlice';
import {
   useFetchTeamOptionsTableDataMutation,
   useFetchTeamOptionsMutation,
   usePatientDetailsMutation,
} from '../../features/patient/patientService';

const Dashboard = () => {
   const [patientDetails] = usePatientDetailsMutation();
   const dispatch = useDispatch();
   const { Title, Text } = Typography;
   const [selectedTeamOption, setSelectedTeamOption] = useState(null);
   const [options, setOptions] = useState({
      teamOptions: [],
      tableOptions: [],
      orgTableOptions: [],
   });
   const patientSliceState = useSelector(selectPatientResult, shallowEqual);
   const [fetchTeamOptionsTableData] = useFetchTeamOptionsTableDataMutation();
   const [fetchTeamOptions] = useFetchTeamOptionsMutation();
   const navigate = useNavigate();

   const handleApiRequest = async (requestFn, requestData) => {
      try {
         const response = await requestFn(requestData);
         return response;
      } catch (error) {
         return error;
      }
   };
   const [dataFetched, setDataFetched] = useState(false);
   const fetchData = async () => {
      if (!dataFetched) {
         try {
            const teamRes = await handleApiRequest(fetchTeamOptions, '');
            //const tableDataRes = await handleApiRequest(fetchTeamOptionsTableData, teamRes.data.data[0].teamId);

            //setSelectedTeamOption(teamRes?.data?.data[0])
            // Call API for table data based on team API

            setOptions({
               teamOptions: teamRes?.data?.data,
               tableOptions: [],
               orgTableOptions: [],
            });
            setDataFetched(true);
         } catch (error) {
            console.error('Error fetching data:', error);
         }
      }
   };
   useEffect(() => {
      fetchData();
   }, []);

   const handleGlobalSearch = () => {
      navigate('/search-patient');
   };

   const headerTitleStyle = (text) => {
      return <Text className="header-class ">{text}</Text>;
   };

   const detailsStyle = (text) => {
      return (
         <Text strong className="text-color">
            {text}
         </Text>
      );
   };
   const getPatientDetails = async (_id) => {
      try {
         const response = await patientDetails(_id);
         return response;
      } catch (error) {
         return error;
      }
   };

   const handRightClick = async (selectedrecord) => {
      const response = await getPatientDetails(selectedrecord.patientId);
      dispatch(updatePatientResult(selectedrecord.patientId));
      navigate('/patient/' + selectedrecord.patientId, {
         state: {
            record: selectedrecord,
         },
      });
   };

   const columns = [
      {
         title: headerTitleStyle('Name'),
         dataIndex: 'name',
         key: 'name',
         render: (key, record) => (
            <div className="font-bold">
               {`${record.lastName}, ${record.firstName}`}
            </div>
         ),
      },
      {
         title: headerTitleStyle('HCRx Patient ID#'),
         dataIndex: 'hrxPatientId',
         key: 'hrxPatientId',
         render: (hrxPatientId) => detailsStyle(hrxPatientId),
      },
      {
         title: headerTitleStyle('Medical Record#'),
         dataIndex: 'medicalRecordId',
         key: 'medicalRecordId',
         render: (medicalRecordId) => detailsStyle(medicalRecordId),
      },
      {
         title: '',
         key: 'action',
         render: (key, record) => (
            <div>
               <RightOutlined onClick={() => handRightClick(record)} />
            </div>
         ),
      },
   ];

   const handleSelect = async (opt) => {
      const tableDataRes = await handleApiRequest(
         fetchTeamOptionsTableData,
         opt.teamId,
      );
      setSelectedTeamOption(opt);
      const sortedData = [...tableDataRes?.data?.data]?.sort((a, b) =>
         a.lastName.localeCompare(b.lastName),
      );
      setOptions({
         ...options,
         tableOptions: sortedData,
         orgTableOptions: sortedData,
      });
   };

   const handleTableSearch = (e) => {
      const searchQuery = e.target.value && e.target.value.toLowerCase();
      if (!options.tableOptions) return;

      const filteredTableOptions = options.orgTableOptions.filter((option) => {
         const fullName =
            `${option.lastName}, ${option.firstName}`.toLowerCase();
         return fullName.includes(searchQuery.trim().toLowerCase());
      });

      setOptions({
         ...options,
         tableOptions: searchQuery
            ? filteredTableOptions
            : options.orgTableOptions,
      });
   };

   const dropDownStyle = (value) => {
      return value ? '#000000' : '#adb5bd';
   };

   const renderTeamPatient = () => {
      return (
         <div>
            <Row gutter={16}>
               <Col span={8}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                     <Dropdown
                        overlay={
                           <Menu>
                              {options?.teamOptions.map((option) => (
                                 <Menu.Item
                                    key={option.teamId}
                                    onClick={() => handleSelect(option)}
                                 >
                                    {option.name}
                                 </Menu.Item>
                              ))}
                           </Menu>
                        }
                        placement="bottomRight"
                        trigger={['click']}
                     >
                        <Button
                           className="dropdown-btn-dash d-flex flex-row justify-content-between"
                           style={{
                              width: '100%',
                              color: dropDownStyle(selectedTeamOption),
                           }}
                        >
                           <span>
                              {selectedTeamOption
                                 ? `${selectedTeamOption?.name}`
                                 : 'Select a Team'}
                           </span>
                           <DownOutlined
                              style={{ marginLeft: '10px', marginTop: '8px' }}
                              rev={undefined}
                           />
                        </Button>
                     </Dropdown>
                     <div
                        className="admit-patient-icon"
                        title="Admit New Patient"
                     >
                        <Button
                           type="primary"
                           shape="circle"
                           style={{
                              backgroundColor: '#6AC04B',
                              border: 'none',
                           }}
                           size="small"
                           icon={<PlusOutlined />}
                           onClick={() => navigate('/admit_patient')}
                        />
                     </div>
                  </div>
               </Col>
               <Col span={8}></Col>
               <Col span={8}>
                  <Input
                     className="input-container-dash"
                     style={{ paddingLeft: '20%' }}
                     addonBefore={<SearchOutlined />}
                     placeholder="e.g. Hemoglobin"
                     onChange={(e) => handleTableSearch(e)}
                  />
               </Col>
            </Row>
            <Table columns={columns} dataSource={options.tableOptions} />
         </div>
      );
   };

   const tabData = [
      {
         key: '1',
         title: 'Team Patients',
         render: () => {
            return renderTeamPatient();
         },
      },
      // {
      //    key: '2',
      //    title: 'Recently Accessed Patients',
      //    render: () => {
      //       return null;
      //    },
      // },
   ];

   return (
      <div>
         <div className="dashobard-box">
            <Title level={2}>Dashboard</Title>
            <SearchOutlined
               className="search-btn"
               onClick={handleGlobalSearch}
            />
         </div>
         <div className="tab-style">
            <Tabs defaultActiveKey={tabData[0].key}>
               {tabData.map((tab, index) => (
                  <Tabs.TabPane tab={tab.title} key={tab.key}>
                     <Card className="card-width">{tab.render()}</Card>
                  </Tabs.TabPane>
               ))}
            </Tabs>
         </div>
      </div>
   );
};

export default Dashboard;
