import { Rule } from 'antd/lib/form';
import { CSSProperties, ReactNode } from 'react';

export interface PageResponse {
    data: any;
    pageNumber: number;
    totalPages: number;
    totalCount: number;
    pageSize: number;
    hasPreviousPage: boolean;
    hasNextPage: boolean;
}

export interface PatientSearchResponse extends PageResponse {
    data: any;
}

export interface PatientResultDataResponse {
    hrxPatientId: string;
    patientId: string;
    medicalRecordId: string;
    hospiceId: string;
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    teamCode: string;
    clinicianHasAccess: Boolean;
    patientStatus: Number;
    patientStatusDisplay: string;
}

export interface PatientSearchRequest {
    hrxPatientId: string | null;
    firstName: string | null;
    lastName: string | null;
    dateOfBirth: string | null;
}

export interface PatientDetailsResponse {
    patientId: string;
    hrxPatientId: string;
    medicalRecordId: string;
    scriptSurePatientId: number;
    hospiceOrganizationId: string;
    teamCode: string;
    locationOfServiceId: string;
    consent: boolean;
    patientStatus: number;
    dischargedOnEmr: boolean;
    patientStatusDisplay: string;
    admissionAddress: {
        streetAddress: string;
        city: string;
        state: string;
        zipCode: string;
        county: string;
        country: string;
    };
    demographics: {
        firstName: string;
        middleName: string | null;
        lastName: string;
        dateOfBirth: string;
        socialSecurityNumber: string | null;
        gender: string;
        race: string | null;
        religion: string | null;
        language: string;
        citizenship: string;
        maritalStatus: string;
        phoneNumber: {
            home: string;
            office: string | null;
            mobile: string | null;
        };
        address: {
            streetAddress: string;
            city: string;
            state: string;
            zipCode: string;
            county: string;
            country: string;
        };
        notes: string | null;
    };
    diagnoses: any[];
    primaryCarePractitionerUserId: string;
    identifiers: {
        identifier: string;
        identifierType: string;
    }[];
    allergies: any[];
    locationOfServiceOptions: {
        id: string;
        subGroupId: string | null;
        name: string;
    }[];
    primaryCarePractitioner: {
        userId: string;
        firstName: string;
        lastName: string;
        npi: string;
    };
    eligibilityStartDate: string;
    eligibilityEndDate: string;
}

export interface PatientScriptSureCookieResponse {
    apiUrl: string;
    apiVersion: string;
    webAppUrl: string;
    sessiontoken: string;
    hasPrescriberAccess: boolean;
    cookie: {
        'connect.sid': string;
        'Max-Age': string;
        Domain: string;
        Path: string;
        Expires: string;
        Secure: string;
        SameSite: string;
    };
}
export interface PatientScriptSureCookieRequest {
    SessionToken: string;
    PatientId: string;
}

export interface PatientDischargeRequest {
    dischargeReason: number;
    date: string;
    note: string;
}

export interface PatientDischargeResponse {
    id: string;
}

export enum PatientStatusEnum {
    Invalid,
    ReferralNewPatient,
    Admitted,
    DischargedDeceased,
    DischargedRevoked,
    DischargedTransferred,
}

export interface PatientField {
    label: string;
    key: string;
    dataField: string[];
    placeHolder: string;
    mandatory: boolean;
    validator?: (rule: Rule, value: any) => Promise<any>;
    fieldType: string;
    note?: string | ReactNode;
    options?: Array<{ label: string; value: string; original?: any }>;
    onChange?: (
        value: string | React.ChangeEvent<HTMLInputElement>,
    ) => Promise<void>;
    style?: CSSProperties;
}
