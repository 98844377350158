import { Form } from 'antd';
import React, { ReactNode, CSSProperties } from 'react';

export const PatientFormItemView: React.FC<{
    name: string;
    label: string;
    fieldType: string;
    dataField: string[];
    note?: string | ReactNode;
    inputStyle?: CSSProperties;
    options?: Array<{ label: string; value: string; original?: any }>;
}> = ({ name, label, fieldType, dataField, note, inputStyle, options }) => {
    const fieldName = dataField ? [...dataField, name] : name;
    if (fieldType === 'none') return <></>;

    const getSelectedOption = (val: string) => {
        return options?.find((i) => i.value === val)?.label;
    };

    return (
        // See ant-form-item-extra css style for more information
        <Form.Item
            extra={note}
            style={{
                ...(inputStyle ? inputStyle : {}),
                ...(note ? { marginBottom: 0 } : {}),
            }}
        >
            <label className="custom-label">{label}</label>
            {fieldType === 'datepicker' ? (
                <Form.Item className="custom-div" noStyle shouldUpdate>
                    {({ getFieldValue }) =>
                        getFieldValue(fieldName)?.format('MM-DD-YYYY') || '-'
                    }
                </Form.Item>
            ) : fieldType === 'dropdown' ? (
                <Form.Item className="custom-div" noStyle shouldUpdate>
                    {({ getFieldValue }) =>
                        getSelectedOption(getFieldValue(fieldName)) || '-'
                    }
                </Form.Item>
            ) : (
                <Form.Item className="custom-div" noStyle shouldUpdate>
                    {({ getFieldValue }) => getFieldValue(fieldName) || '-'}
                </Form.Item>
            )}
        </Form.Item>
    );
};
