import { baseApiService } from '../baseApiService';
import {
   DataFailuresRequest,
   DataFailuresResponse,
   DataFailuresResendRequest
} from '../../models/FailuresResponse';

export const dataFailuresApi = baseApiService.injectEndpoints({
   endpoints: (builder) => ({
      dataFailures: builder.mutation<DataFailuresResponse, DataFailuresRequest>(
         {
            query: (req) => ({
               url: '/v1/datafailures/search',
               method: 'POST',
               body: req,
            }),
         },
      ),
      dataFailuresRetry: builder.mutation<boolean, DataFailuresResendRequest>({
         query: (req) => ({
            url: '/v1/datafailures/retry',
            method: 'POST',
            body: req,
         }),
      }),
   }),
});

export const { useDataFailuresMutation, useDataFailuresRetryMutation } =
   dataFailuresApi;
