import React, {useState, useEffect, useMemo} from 'react';
import {columns, skeletonColumns} from "./NotificationsData";
import { isNotNullOrUndefined} from "../../utilities/extensions";
import {shallowEqual, useSelector} from "react-redux";
import { selectNotificationsFailures, selectNotificationsFailuresFilter, selectNotificationsFailuresState } from "../../features/notification/notificationsSlice";
import {Button, Table, notification} from "antd";
import {IoChevronDown, IoChevronForwardSharp} from "react-icons/all";
import {defaultFailuresFilter} from "../../components/Schema/notificationsSchema";
import { useNotificationRetryMutation, useNotificationsFailuresMutation } from "../../features/notification/notificationsService";
import SkeletonTable from "../../components/Layout/Utilities/SkeletonTable";

const showNotification = (message, description) => {
    notification.open({
        message,
        description
    });
};

const FailuresTable = () => {

    const [actionSate, setActionSate] = useState([])

    const {data, pageNumber, totalPages, totalCount, pageSize, hasPreviousPage, hasNextPage, keyword} = useSelector(selectNotificationsFailures, shallowEqual);

    const filter = useSelector(selectNotificationsFailuresFilter, shallowEqual);
    const {isLoading} = useSelector(selectNotificationsFailuresState);

    const [notificationsFailures] = useNotificationsFailuresMutation();
    const [notificationRetry] = useNotificationRetryMutation();

    useEffect(()=>{ notificationsFailures({...defaultFailuresFilter}); },[]);

    const onPageChange = (page, pageSize) =>{ notificationsFailures( {...filter, pageNumber:page, pageSize:pageSize}); }

    const onShowSizeChange = (current, size) =>{ notificationsFailures({...filter, pageNumber:0, pageSize:size}); }

    const onChange = (pagination, filters, {column, columnKey, field, order}, {action}) =>{
        if(isNotNullOrUndefined(column) && action === "sort"){
            notificationsFailures({...filter, orderBy:[`${column.backField} ${order}`]});
        }
    };

    const failuresColumns = useMemo(()=>{
        return [...columns, { title: 'Actions', dataIndex: '', key: 'fActions', width: 150, align:'center',
            render: (text, record, index) => <Button onClick={ async e=>{
                setActionSate(prev=> [...prev, index]);
                let result = false;
                try { result = await notificationRetry({jobDetailId:record.jobDetailId}).unwrap();}catch{ }
                setActionSate([...actionSate.filter(i=> i!==index )]);
                showNotification("Notification",result ? `${record.notificationTypeDisplay} was sent to ${record.destinationAccount} successfully.` : `${record.notificationTypeDisplay} failed to send ${record.destinationAccount}.`)
            }} loading={actionSate.includes(index)} >Retry</Button>,
        }]
    },[data, actionSate]);

    return (
        <div className="d-flex flex-column">
            <SkeletonTable loading={isLoading} columns={skeletonColumns} active={isLoading}>
                <Table rowKey="id"
                       tableLayout="fixed"
                       className="table-ctrl"
                       columns={failuresColumns}
                       onChange={onChange}
                       dataSource={data} scroll={{ y: 500, x: 900 }}
                       pagination={{
                           position: ['bottomRight'],
                           showSizeChanger:true,
                           current: pageNumber,
                           total: totalCount,
                           pageSize: pageSize,
                           onChange:onPageChange,
                           onShowSizeChange:onShowSizeChange
                       }}
                       expandable={{
                           rowExpandable: record => isNotNullOrUndefined(record.notificationContent),
                           expandedRowRender: record =>  <div className="p-2 d-flex flex-column">
                               <div className="h6">Full Notification Content</div>
                               <div className="p-2 border html-details" dangerouslySetInnerHTML={{ __html: record.notificationContent }}/>
                           </div>,
                           expandIcon: ({ expanded, onExpand, record }) =>
                               expanded ? <IoChevronDown onClick={e => onExpand(record, e)} /> : <IoChevronForwardSharp onClick={e => onExpand(record, e)} />
                       }}/>
            </SkeletonTable>
        </div>
    );
}

export default FailuresTable;
