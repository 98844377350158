import { createSlice } from "@reduxjs/toolkit";
import {NotificationsRequest, NotificationsResponse} from "../../models/NotificationsResponse";
import {RootState} from "../appStore";
import {notificationsApi} from "./notificationsService";
import {defaultFailuresFilter, defaultReportsFilter} from "../../components/Schema/notificationsSchema";

/*
// endpoint: /api/v1/notifications/search
// filter
{
  //nullable
  "advancedSearch": { fields: ["JobDetail.HospiceId", "JobDetail.PatientId"], keyword: "0826286d"},
  //nullable
  "keyword": "",
  //nullable
  "orderBy": [],
  "pageNumber": 0,
  "pageSize": 0,
  "channels": [1, 2 ],
  "messageTypes": [ 1, 2, 3, 4 ],
  "dateRange": { "type": 1,  "startDate": "2022-06-12T18:30:00.000Z", "endDate": "2022-06-13T18:30:00.000Z"}
}
 */
//TODO: remove this and use notificationsService.ts
const pageResponse:NotificationsResponse = {
    data:[],
    pageNumber:1,
    totalPages:0,
    totalCount:0,
    pageSize:10,
    hasPreviousPage:false,
    hasNextPage:false
}

const initialState = {
    reports:{
        isLoading:false,
        filter: defaultReportsFilter,
        pageResponse
    },
    failures:{
        isLoading:false,
        filter: defaultFailuresFilter,
        pageResponse
    }
};

const {matchPending:reportsMatchPending,matchRejected:reportsMatchRejected, matchFulfilled:reportsMatchFulfilled} = notificationsApi.endpoints.notificationsReports;
const {matchPending:failuresMatchPending,matchRejected:failuresMatchRejected, matchFulfilled:failuresMatchFulfilled} = notificationsApi.endpoints.notificationsFailures;

export const SLICE_NOTIFICATIONS_NAME = "notifications";
export const notificationsSlice = createSlice({
    name: SLICE_NOTIFICATIONS_NAME,
    initialState,
    reducers: {
        setReportsFilter: (state,{payload}) => {
            state.reports.filter = payload;
        },
        setFailuresFilter: (state,{payload}) => {
            state.failures.filter = payload;
        }
    },
    extraReducers: (builder) => {
            //builder.addCase(increment, (state, action) => state + action.payload)
            builder.addMatcher(reportsMatchPending,({reports}, { payload }) => {
                reports.isLoading = true;
            }).addMatcher(reportsMatchRejected,({reports}, { payload }) => {
                reports.isLoading = false;
            }).addMatcher(reportsMatchFulfilled,({reports}, { payload }) => {
                reports.pageResponse = payload;
                reports.isLoading = false;
            }).addMatcher(failuresMatchPending,({failures}, { payload }) => {
                failures.isLoading = true;
            }).addMatcher(failuresMatchRejected,({failures}, { payload }) => {
                failures.isLoading = false;
            }).addMatcher(failuresMatchFulfilled,({failures}, { payload }) => {
                failures.pageResponse = payload;
                failures.isLoading = false;
            });
    },
});

export const { setFailuresFilter, setReportsFilter } = notificationsSlice.actions;

export default notificationsSlice.reducer;

export const selectNotificationsState = (state: RootState) => state[SLICE_NOTIFICATIONS_NAME];

export const selectNotificationsReportsState = (state: RootState) => state[SLICE_NOTIFICATIONS_NAME].reports;
export const selectNotificationsReportsFilter = (state: RootState) => state[SLICE_NOTIFICATIONS_NAME].reports.filter;
export const selectNotificationsReports = (state: RootState) => state[SLICE_NOTIFICATIONS_NAME].reports.pageResponse;

export const selectNotificationsFailuresState = (state: RootState) => state[SLICE_NOTIFICATIONS_NAME].failures;
export const selectNotificationsFailuresFilter = (state: RootState) => state[SLICE_NOTIFICATIONS_NAME].failures.filter;
export const selectNotificationsFailures = (state: RootState) => state[SLICE_NOTIFICATIONS_NAME].failures.pageResponse;




