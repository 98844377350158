import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

export const LoadingSpinner: React.FC<{ fontSize?: number }> = ({
   fontSize = 20,
}) => {
   return (
      <Spin
         className="text-primary"
         indicator={
            <LoadingOutlined
               style={{ fontSize: fontSize, marginRight: '0.5em' }}
               rev={undefined}
               spin
            />
         }
      />
   );
};
