import {
   AiOutlineAppstore,
   IoHomeOutline,
   IoNotificationsOutline,
   AiOutlineTeam,
   AiOutlineSchedule,
} from 'react-icons/all';
import React from 'react';
import {
   AccountTeamAdmin,
   AdminRoles,
   ClinicalConsultRoles,
} from '../../../global/constants';
import { IsArrayWithLength, UserHasRoles } from '../../../utilities/extensions';

const CoreSideMenuItems = [
   {
      key: 'dashboard',
      icon: <IoHomeOutline />,
      label: 'Dashboard',
      path: 'dashboard',
   },
   {
      key: 'account-team',
      icon: <AiOutlineAppstore />,
      label: 'Account Team',
      roles: AccountTeamAdmin,
      to: '/account-team',
      children: [
         {
            key: 'notifications',
            icon: <IoNotificationsOutline />,
            label: 'Notifications',
            roles: AccountTeamAdmin,
         },
         {
            key: 'data-integration-failures',
            icon: <IoNotificationsOutline />,
            label: 'Data Integration Failures',
            roles: AccountTeamAdmin,
         },
         {
            key: 'device-session-management',
            icon: <IoNotificationsOutline />,
            label: 'Device / Session Management',
            roles: AccountTeamAdmin,
         },
      ],
   },
   {
      key: 'clinical-team',
      icon: <AiOutlineTeam />,
      label: 'Clinical Team',
      roles: ClinicalConsultRoles,
      to: '/clinical-team',
      children: [
         {
            key: 'clinical-consults',
            icon: <AiOutlineSchedule />,
            label: 'Clinical Consults',
            roles: ClinicalConsultRoles,
         },
      ],
   },
];

const SideMenuItems = [];
export const MenuRoles = {};

export const ReLoadMenuItems = () => {
   ClearMenuItems();
   CoreSideMenuItems.forEach((m) => {
      let menu = undefined;
      if (m.roles === undefined || UserHasRoles(m.roles))
         menu = { ...m, children: [] };
      if (IsArrayWithLength(m.roles)) MenuRoles[`/${m.key}`] = [...m.roles];

      if (IsArrayWithLength(m.children)) {
         const children = m.children.filter((cm) =>
            IsArrayWithLength(cm.roles),
         );
         if (IsArrayWithLength(children)) {
            children.forEach((cm) => {
               MenuRoles[`/${m.key}/${cm.key}`] = [...cm.roles];
            });
         }
         m.children
            .filter((cm) => cm.roles === undefined || UserHasRoles(cm.roles))
            .forEach((cm) => {
               menu.children.push({ ...cm });
            });
      }
      if (menu !== undefined) {
         if (!IsArrayWithLength(menu.children)) delete menu.children;
         SideMenuItems.push(menu);
      }
   });
};

export const ClearMenuItems = () => {
   SideMenuItems.length = 0;
   Object.keys(MenuRoles).forEach((key) => {
      delete MenuRoles[key];
   });
};

if (!IsArrayWithLength(SideMenuItems) || !IsArrayWithLength(MenuRoles))
   ReLoadMenuItems();

export default SideMenuItems;
