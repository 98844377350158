import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../appStore';
import { dataSessionApi } from './sessionService';

interface PageResponse {
   data: any,
   pageNumber: number,
   totalPages: number,
   totalCount: number,
   pageSize: number,
   hasPreviousPage: boolean,
   hasNextPage: boolean,
}

const pageResponse:PageResponse = {
   data: [],
   pageNumber: 1,
   totalPages: 0,
   totalCount: 0,
   pageSize: 10,
   hasPreviousPage: false,
   hasNextPage: false,
};

const initialState = {
   sessions: {
      isLoading: false,
      filter: {},
      pageResponse,
   },
};

//TODO: remove this and use sessionService.ts
export const SLICE_SESSION_NAME = 'sessions';
export const sessionSlice = createSlice({
   name: SLICE_SESSION_NAME,
   initialState,
   reducers: {
      setSessionFilter: ({ sessions }, { payload }) => {
         sessions.filter = payload;
      },
      setSessions: ({ sessions }, { payload }) => {
         sessions.pageResponse = payload;
      },
      setConnected: ({ sessions }, { payload }) => {
         sessions.pageResponse.data.push(payload);
      },
      setDisConnected: (state, { payload }) => {
         const index = state.sessions.pageResponse.data.findIndex((item:any) => item.deviceId === payload.deviceId)
         state.sessions.pageResponse.data.splice(index, 1);
      }
   },
   extraReducers: (builder) => {
      builder
         .addMatcher(dataSessionApi.endpoints.dataSession.matchPending,({ sessions }, { payload }) => {
               sessions.isLoading = true;
            },
         )
         .addMatcher(dataSessionApi.endpoints.dataSession.matchRejected,({ sessions }, { payload }) => {
               sessions.isLoading = false;
            },
         )
         .addMatcher(dataSessionApi.endpoints.dataSession.matchFulfilled,({ sessions }, { payload }) => {
               sessions.pageResponse.data = payload;
               sessions.isLoading = false;
            },
         );
   },
});


export const { setSessionFilter, setSessions, setConnected, setDisConnected } = sessionSlice.actions;
export default sessionSlice.reducer;

export const selectSessionState = (state: RootState) => state[SLICE_SESSION_NAME].sessions;
export const selectSession = (state: RootState) => state[SLICE_SESSION_NAME].sessions.pageResponse;
