import { Card, Checkbox, Col, Divider, Form, Row, Typography } from 'antd';
import React from 'react';
import { PatientFormItem } from './PatientFormItem';
import { PatientField } from '../../models/PatientModels';
import { PatientFormItemView } from './PatientFormItemView';

interface AdmitionTab {
    key: string;
    title?: string;
    view: boolean;
    edit: boolean;
    viewCardTitles: {
        title: string;
        cols: {
            from: number;
            to: number;
        }[];
    }[];
}

export const PatientAdmitionForm: React.FC<{
    selectedTab: AdmitionTab;
    fields: Array<PatientField>;
    updateMode?: boolean;
}> = ({ selectedTab, fields, updateMode = false }) => {
    const { Title } = Typography;
    return (
        <div>
            <Row gutter={0}>
                {selectedTab.viewCardTitles.map((cardTitle, i) => {
                    const totalCols =
                        selectedTab.viewCardTitles.reduce((acc, i) => {
                            acc += i.cols.length;
                            return acc;
                        }, 0) || 0;
                    const colSpan = (cardTitle.cols.length * 24) / totalCols;
                    return (
                        <Col
                            span={colSpan}
                            {...(i === 0 && {
                                style: { marginLeft: '-14px' },
                            })}
                            key={i}
                        >
                            <Title
                                level={5}
                                {...(i === 0 && {
                                    style: {
                                        paddingLeft: '27px',
                                    },
                                })}
                            >
                                {cardTitle.title}
                            </Title>
                            {cardTitle.title && (
                                <>
                                    <Divider
                                        orientation="left"
                                        style={{
                                            minWidth: '99%',
                                            width: '99%',
                                            margin: '9px 0px 20px',
                                        }}
                                    ></Divider>
                                </>
                            )}
                        </Col>
                    );
                })}
            </Row>
            <Card className="card-width" bordered={false}>
                <Row gutter={8}>
                    {selectedTab.viewCardTitles.map(
                        (cardTitle, cardTitleIndex) => {
                            const totalCols =
                                selectedTab.viewCardTitles.reduce((acc, i) => {
                                    acc += i.cols.length;
                                    return acc;
                                }, 0) || 0;
                            const colSpan =
                                (cardTitle.cols.length * 24) / totalCols;

                            return (
                                <Col span={colSpan} key={cardTitleIndex}>
                                    <Row gutter={16}>
                                        {cardTitle.cols.map((col, i) => (
                                            <Col
                                                span={
                                                    24 / cardTitle.cols.length
                                                }
                                                key={i}
                                            >
                                                {fields
                                                    .slice(col.from, col.to)
                                                    .map((field, index) =>
                                                        updateMode ? (
                                                            <PatientFormItemView
                                                                key={index}
                                                                name={field.key}
                                                                label={
                                                                    field.label
                                                                }
                                                                fieldType={
                                                                    field.fieldType
                                                                }
                                                                dataField={
                                                                    field.dataField
                                                                }
                                                                note={
                                                                    field.note
                                                                }
                                                                inputStyle={
                                                                    field.style
                                                                }
                                                                options={
                                                                    field.options
                                                                }
                                                            />
                                                        ) : (
                                                            <PatientFormItem
                                                                key={index}
                                                                name={field.key}
                                                                label={
                                                                    field.label
                                                                }
                                                                required={
                                                                    field.mandatory
                                                                }
                                                                fieldType={
                                                                    field.fieldType
                                                                }
                                                                placeholder={
                                                                    field.placeHolder
                                                                }
                                                                dataField={
                                                                    field.dataField
                                                                }
                                                                validator={
                                                                    field.validator
                                                                }
                                                                note={
                                                                    field.note
                                                                }
                                                                options={
                                                                    field.options
                                                                }
                                                                onChange={
                                                                    field.onChange
                                                                }
                                                                inputStyle={
                                                                    field.style
                                                                }
                                                            />
                                                        ),
                                                    )}
                                            </Col>
                                        ))}
                                    </Row>
                                </Col>
                            );
                        },
                    )}
                </Row>
                {fields
                    .filter((i) => i.key === 'consent')
                    ?.map((e) => (
                        <Row gutter={[16, 0]} style={{ marginTop: '-4vh' }}>
                            <Col span={12} offset={8}>
                                <Form.Item
                                    name={e.key}
                                    noStyle
                                    valuePropName="checked"
                                >
                                    <Checkbox>{e.label}</Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                    ))}
                {!updateMode && selectedTab?.title === 'PCP' && (
                    <div style={{ marginTop: '30px', padding: '12px 0' }}>
                        <Row gutter={16}>
                            {fields
                                .filter((i) => i.fieldType === 'none')
                                .map((field, index) => (
                                    <Col
                                        key={index}
                                        span={index === 1 ? 12 : 8}
                                    >
                                        <Form.Item name={field.key}>
                                            <div>
                                                <label className="custom-label">
                                                    {field?.label}
                                                </label>
                                                <Form.Item noStyle shouldUpdate>
                                                    {({ getFieldValue }) =>
                                                        getFieldValue([
                                                            'primaryCarePractitioner',
                                                            field.key,
                                                        ]) || ''
                                                    }
                                                </Form.Item>
                                            </div>
                                        </Form.Item>
                                    </Col>
                                ))}
                        </Row>
                    </div>
                )}
            </Card>
        </div>
    );
};
