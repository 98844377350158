import { message } from "antd";
import { PatientDetailsResponse } from "../models/PatientModels";

export class Utility {

    public static checkWarnings = (warning: any) => {
      
        for (const [areaKey, areaValue] of Object.entries(warning) as [string, any]) {         
           for (const [fieldKey, fieldValue] of Object.entries(areaValue) as [string, any]) {            
              if (fieldValue.showWarning){
                 return true;
              }
           }
         }
        
        return false;
     }

    public static isNullOrUndefined(obj: any|void): boolean {
        return typeof obj === 'undefined' || obj === null;
    }

    public static isNullOrUndefinedOrEmpty(obj: any|void): boolean {
        if (Utility.isNullOrUndefined(obj)) {
            return true;
        }
        if (typeof(obj) === 'string' || obj instanceof String) {
            return (obj as string).valueOf() === '';
        } else if (obj instanceof Array) {
            return (obj as Array<any>).length === 0;
        }
        throw new Error('Not Supported Exception');
    }

    public static checkNullOrUndefined = (fields: any) => {
        const mandatoryFields = fields.filter((item: any) => item.mandatory);
        return mandatoryFields.some(
           (field: any) =>
              field.value === null ||
              field.value === undefined ||
              field.value === '',
        );
     };

    public static errorToast = (msg: any) => {
        message.error(msg);
    };

    public static getDemographicChangesCount = (fields: any, patientDetails: PatientDetailsResponse) => {
        const demographicKeysCount = 3; //['firstName', 'lastName', 'dateOfBirth'];
        const newFirstName = fields.filter((item: any) => item.key === 'firstName')[0];
        const newLastName = fields.filter((item: any) => item.key === 'lastName')[0];
        const newDOB = fields.filter((item: any) => item.key === 'dateOfBirth')[0];
        
        let count: number = 0;

        if (patientDetails.demographics.firstName !== newFirstName.value)   count++;
        if (patientDetails.demographics.lastName !== newLastName.value)   count++;
        if (patientDetails.demographics.dateOfBirth !== newDOB.value)   count++;
        
        return count;
     };
}
