import { configureStore, ConfigureStoreOptions } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import commonSlice, { SLICE_COMMON_NAME } from './common/commonUi';
import authReducer, { SLICE_AUTH_NAME } from '../features/auth/authSlice';
import { baseApiService } from './baseApiService';
import notificationsReducer, {
   SLICE_NOTIFICATIONS_NAME,
} from './notification/notificationsSlice';
import { notificationsApi } from './notification/notificationsService';
import failuresReducer, { SLICE_FAILURES_NAME } from './failure/failuresSlice';
import sessionReducer, { SLICE_SESSION_NAME } from './session/sessionSlice';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import signalRMiddleware from './middlewares/signalRMiddleware';
import appointmentReducer, {
   SLICE_APPOINTMENT_NAME,
} from './appointment/appointmentSlice';
import patientSlice,{SLICE_PATIENT_NAME} from './patient/patientSlice';

const reducers = combineReducers({
   counter: failuresReducer,
   patient: patientSlice.reducer,
});

const persistConfig = {
   key: 'root',
   storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const createStore = (
   options?: ConfigureStoreOptions['preloadedState'] | undefined,
) =>
   configureStore({
      reducer: {
         persistedReducer,
         [SLICE_COMMON_NAME]: commonSlice,
         [baseApiService.reducerPath]: baseApiService.reducer,
         [SLICE_AUTH_NAME]: authReducer,
         [notificationsApi.reducerPath]: notificationsApi.reducer,
         [SLICE_NOTIFICATIONS_NAME]: notificationsReducer,
         [SLICE_FAILURES_NAME]: failuresReducer,
         [SLICE_SESSION_NAME]: sessionReducer,
         [SLICE_APPOINTMENT_NAME]: appointmentReducer,
         [SLICE_PATIENT_NAME]: patientSlice.reducer,
      },
      middleware: (getDefaultMiddleware) =>
         getDefaultMiddleware().concat(
            baseApiService.middleware,
            notificationsApi.middleware,
            signalRMiddleware,
         ),
      devTools: true,
   });

export const store = createStore();

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export type RootState = ReturnType<typeof store.getState>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppSelector = () => useSelector;
