import {IsArrayWithLength, IsNotEmpty} from "../utilities/extensions";



export enum CommunicationChannelType {
    None = 0,
    Sms = 1,
    Email = 2
}
export interface CommunicationPreferenceResponse {
    success: boolean;
    channelDetails: string;
    userNotificationChannelId: string;
    notificationsEnabled: boolean;
    channelEnabled: boolean;
    optIn: boolean;
    verified: boolean;
    blocked: boolean;
    channelType: CommunicationChannelType;

    remoteChannelDetails: string;
    remoteChannelEnabled: boolean;
    remoteOptIn: boolean;
    isEditMode: boolean;
    canShowVerifiedIcon: boolean;
    canShowResendVerification: boolean;
}

export interface CommunicationPreferenceRequest {
    userNotificationChannelId: string;
    userFullName: string;
    channelDetails: string;
    notificationEnabled: boolean;
    channelEnabled: boolean;
    optIn: boolean;
    blocked: boolean;
    channelType: CommunicationChannelType;
    userId: string | null;
    processId: string | null;
}

export interface CommunicationPreferences extends Array<CommunicationPreferenceResponse>{}


export const ChannelTypeDisplay = (channelType: CommunicationChannelType):string => {
    switch (channelType) {
        case CommunicationChannelType.Sms:
            return "Sms";
        case CommunicationChannelType.Email:
            return "Email"
        default:
            return "None";
    }
}

export const ComputeCommunicationPreferences = (source:CommunicationPreferenceResponse[]) => {

    if(IsArrayWithLength(source)){
        // don't use [...cps] it points to same object
        const cps = source.map(cp=>({...cp}));
        cps.forEach(cp=>{
            cp.remoteChannelDetails = cp.channelDetails;
            cp.remoteChannelEnabled = cp.channelEnabled;
            cp.remoteOptIn = cp.optIn;
            cp.isEditMode = !IsNotEmpty(cp.channelDetails)
        })
        return cps
    }
    return source;
}

export const ResetCommunicationPreference = (source:CommunicationPreferenceResponse) => {
    source.channelDetails = source.remoteChannelDetails;
    source.isEditMode = false;
}


export const ToCommunicationPreferenceRequest = (cp:CommunicationPreferenceResponse):CommunicationPreferenceRequest =>
{
    return{
            channelDetails : cp.channelDetails,
            channelEnabled : cp.channelEnabled,
            blocked : cp.blocked,
            channelType : cp.channelType,
            notificationEnabled : cp.notificationsEnabled,
            optIn : cp.optIn,
            userNotificationChannelId : cp.userNotificationChannelId
    } as CommunicationPreferenceRequest;
}


export const ToCommunicationPreferenceResponseWithRemoteFields = (source:CommunicationPreferenceResponse, destination:CommunicationPreferenceResponse): CommunicationPreferenceResponse=>
{
    destination.channelDetails = source.channelDetails;
    destination.channelEnabled = source.channelEnabled;
    destination.blocked = source.blocked;
    destination.channelType = source.channelType;
    destination.optIn = source.optIn;
    destination.userNotificationChannelId = source.userNotificationChannelId;
    destination.remoteChannelEnabled = source.channelEnabled;
    destination.remoteChannelDetails = source.channelDetails;
    destination.remoteOptIn = source.optIn;
    destination.verified = source.verified;
    return destination;
}
