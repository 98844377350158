import { baseApiService } from '../baseApiService';
import {
    ClinicalConsultRequest,
    ClinicalConsultResponse,
    DurationReasons,
    PatientSearchResponse,
    Teams,
} from '../../models/AppointmentModels';
import { MessageResponse } from '../../models/SessionResponse';
import {
    CommunicationPreferenceRequest,
    CommunicationPreferenceResponse,
    CommunicationPreferences,
} from '../../models/CommunicationPreference';
import { EnumOptions } from '../../models/Options';

export const appointmentApiSlice = baseApiService
    .enhanceEndpoints({
        addTagTypes: ['ClinicalConsults', 'CommunicationPreferences'],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getClinicalConsults: builder.query<
                ClinicalConsultResponse,
                ClinicalConsultRequest
            >({
                query: (req) => ({
                    url: '/v1/ClinicalConsult/search',
                    method: 'POST',
                    body: req,
                }),
                providesTags: ['ClinicalConsults'],
            }),
            getDurationReasons: builder.query<DurationReasons, void>({
                query: () => '/v1/Team/meeting-bind-values',
            }),
            getGetAllTeams: builder.query<Teams, void>({
                query: () => '/v1/Team/all',
            }),
            getConsultNoteTypeOptions: builder.query<EnumOptions[], void>({
                query: () => `/v1/ClinicalConsult/consult-note-types`,
            }),
            getTeamPatients: builder.mutation<PatientSearchResponse, string>({
                query: (teamId) => `/v1/Team/${teamId}/patients`,
            }),
            addPatientConsult: builder.mutation<string, any>({
                query: (req) => ({
                    url: '/v1/ClinicalConsult',
                    method: 'POST',
                    body: req,
                }),
                invalidatesTags: ['ClinicalConsults'],
            }),
            deletePatientConsult: builder.mutation<MessageResponse, string>({
                query: (id) => ({
                    url: `/v1/ClinicalConsult/${id}`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['ClinicalConsults'],
            }),
            patientConsultReport: builder.mutation<any, any>({
                query: (id) => ({
                    url: `/v1/ClinicalConsult/${id}/report`,
                    method: 'POST',
                    responseHandler: async (response) => {
                        // Create a URL for the blob
                        const status = response.status;
                        if (status === 200)
                            window.open(
                                window.URL.createObjectURL(
                                    await response.blob(),
                                ),
                            );
                        return response.json();
                    },
                }),
            }),
            getCommunicationPreferences: builder.query<
                CommunicationPreferences,
                void
            >({
                query: () => '/v1/CommunicationPreferences',
                providesTags: ['CommunicationPreferences'],
            }),
            updateCommunicationPreferences: builder.mutation<
                CommunicationPreferenceResponse,
                CommunicationPreferenceRequest
            >({
                query: (req) => ({
                    url: '/v1/CommunicationPreferences',
                    method: 'POST',
                    body: req,
                }),
                invalidatesTags: ['ClinicalConsults'],
            }),
        }),
    });

export const {
    useGetClinicalConsultsQuery,
    useGetDurationReasonsQuery,
    useGetGetAllTeamsQuery,
    useGetConsultNoteTypeOptionsQuery,
    useGetTeamPatientsMutation,
    useAddPatientConsultMutation,
    useDeletePatientConsultMutation,
    usePatientConsultReportMutation,
    useGetCommunicationPreferencesQuery,
    useUpdateCommunicationPreferencesMutation,
} = appointmentApiSlice;
