import { baseApiService } from '../baseApiService';
import { DataSessionResendRequest } from '../../models/SessionResponse';

export const dataSessionApi = baseApiService.injectEndpoints({
   endpoints: (builder) => ({
      dataSession: builder.mutation<[], {}>({
         query: (req) => ({
            url: '/v1/devicemanagement/active-device-users',
            method: 'POST',
            body: req,
         }),
      }),
      sessionAction: builder.mutation<boolean, DataSessionResendRequest>({
         query: (req) => ({
            url: `/v1/devicemanagement`,
            method: 'POST',
            body: req,
         }),
      }),
   }),
});

export const { useDataSessionMutation, useSessionActionMutation } =
   dataSessionApi;
