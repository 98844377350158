import React, { useState, useEffect } from 'react';
import { Tabs, Card, Button, Form, FormInstance } from 'antd';
import { useNavigate } from 'react-router-dom';
import '../../styles/_patientRecord.scss';
import {
    useGetDropDownOptionsMutation,
    useGetPractiontionerOptionsMutation,
} from '../../features/patient/patientService';
import { useAuth } from '../../hooks/useAuth';
import {
    PatientDetailsResponse,
    PatientField,
} from '../../models/PatientModels';
import { PatientAdmitionForm } from './PatientAdmitionForm';
import moment from 'moment';
import { formatDate } from '../../utilities/extensions';

const PatientViewDetails: React.FC<{
    form: FormInstance<any>;
    patientData?: PatientDetailsResponse;
    setUpdateMode: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ form, patientData, setUpdateMode }) => {
    const navigate = useNavigate();
    const { profile } = useAuth();
    const [dropdown_options] = useGetDropDownOptionsMutation();
    const [practitioner_Options] = useGetPractiontionerOptionsMutation();
    const [countryOptions, setCountryOptions] = useState(Array<any>);
    const [languageOptions, setLanguageOptions] = useState(Array<any>);
    const [locationOfServiceOptions, setLocationOfServiceOptions] = useState(
        Array<any>,
    );

    const [practitionerOptions, setPractitionerOptions] = useState<Array<any>>(
        [],
    );

    const toggleEditMode = () => {
        setUpdateMode((prev) => !prev);
        setActiveKey('1');
    };

    useEffect(() => {
        if (patientData) {
            form.setFieldsValue({
                ...patientData,
                demographics: {
                    ...patientData.demographics,
                    dateOfBirth: patientData?.demographics?.dateOfBirth
                        ? moment(
                              formatDate(patientData.demographics.dateOfBirth),
                              'DD-MM-YYYY',
                          )
                        : undefined,
                },
                admissionAddress: {
                    ...patientData.admissionAddress,
                    country: 'US',
                },
                eligibilityStartDate: patientData?.eligibilityStartDate
                    ? moment(
                          formatDate(patientData.eligibilityStartDate),
                          'DD-MM-YYYY',
                      )
                    : undefined,
                teamCode:
                    patientData.teamCode === '' ? null : patientData.teamCode,
            });
        }
    }, [form, patientData]);

    const genderList = ['Male', 'Female', 'Other'];

    const [teamOptions, setTeamOptions] = useState<any[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            if (patientData) {
                const patientTeamId = teamOptions.find(
                    (team) => team.code === patientData.teamCode,
                )?.id;
                if (patientTeamId) await getPracticionerOptions(patientTeamId);

                const allLocationOptions =
                    patientData?.locationOfServiceOptions;

                if (allLocationOptions)
                    setLocationOfServiceOptions(allLocationOptions);
            }
            try {
                const langResponse = (await getDropDownOption()) as {
                    data: {
                        languageOptions: Record<string, string> | [];
                        teamOptions: Array<{
                            locationOfServiceOptions: Array<any>;
                        }>;
                        countryOptions: Array<any>;
                    };
                };
                const langMenuItems = Object.entries(
                    langResponse?.data?.languageOptions || {},
                ).map(([key, value]) => ({
                    key,
                    label: value,
                }));
                setLanguageOptions(langMenuItems);
                const teamOptionData = langResponse?.data?.teamOptions;
                setTeamOptions(teamOptionData);
                setCountryOptions(langResponse?.data?.countryOptions);
            } catch (error) {
                console.error('Error fetching dropdown options:', error);
            }
        };

        fetchData();
    }, [patientData]);

    const getPracticionerOptions = async (teamId: string) => {
        try {
            const pracResponse = (await getPractiontionerOptions(teamId)) as {
                data: Array<any>;
            };
            if (pracResponse?.data) setPractitionerOptions(pracResponse?.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const mainInfoFields: PatientField[] = [
        {
            label: 'First Name',
            key: 'firstName',
            dataField: ['demographics'],
            placeHolder: '',
            mandatory: false,
            fieldType: 'input',
        },
        {
            label: 'Gender',
            key: 'gender',
            dataField: ['demographics'],
            placeHolder: '',
            mandatory: false,
            fieldType: 'view',
            options: genderList.map((option) => ({
                value: option,
                label: option,
            })),
        },
        {
            label: 'Start of Care',
            key: 'eligibilityStartDate',
            dataField: [],
            placeHolder: '',
            mandatory: false,
            fieldType: 'datepicker',
        },

        {
            label: 'Last Name',
            key: 'lastName',
            dataField: ['demographics'],
            placeHolder: '',
            mandatory: false,
            fieldType: 'input',
        },
        {
            label: 'Date of Birth',
            key: 'dateOfBirth',
            dataField: ['demographics'],
            placeHolder: '',
            mandatory: false,
            fieldType: 'datepicker',
        },
        {
            label: 'Race',
            key: 'race',
            dataField: ['demographics'],
            placeHolder: '',
            mandatory: false,
            fieldType: 'view',
        },
        {
            label: 'Religion',
            key: 'religion',
            dataField: ['demographics'],
            placeHolder: '',
            mandatory: false,
            fieldType: 'view',
        },
        {
            label: 'Marital Status',
            key: 'maritalStatus',
            dataField: ['demographics'],
            placeHolder: '',
            mandatory: false,
            fieldType: 'view',
        },
    ];

    const admissionFields: PatientField[] = [
        {
            label: 'Street Address',
            key: 'streetAddress',
            dataField: ['admissionAddress'],
            placeHolder: '',
            mandatory: false,
            fieldType: 'view',
        },
        {
            label: 'City',
            key: 'city',
            dataField: ['admissionAddress'],
            placeHolder: '',
            mandatory: false,
            fieldType: 'view',
        },
        {
            label: 'State',
            key: 'state',
            dataField: ['admissionAddress'],
            placeHolder: '',
            mandatory: false,
            fieldType: 'view',
        },
        {
            label: 'Zip Code',
            key: 'zipCode',
            dataField: ['admissionAddress'],
            placeHolder: '',
            mandatory: false,
            fieldType: 'view',
        },
        {
            label: 'Country',
            key: 'country',
            dataField: ['admissionAddress'],
            placeHolder: '',
            mandatory: false,
            fieldType: 'view',
            options: countryOptions.map((option) => ({
                value: option.alpha2,
                label: option.name,
            })),
        },
        {
            label: 'Location of Service',
            key: 'locationOfServiceId',
            dataField: [],
            placeHolder: '',
            mandatory: false,
            fieldType: 'dropdown',
            options:
                (locationOfServiceOptions &&
                    locationOfServiceOptions.map((option) => ({
                        value: option.id,
                        label: option.name,
                    }))) ||
                [],
        },
    ];

    const practitionerFields: PatientField[] = [
        {
            label: 'First Name',
            key: 'firstName',
            dataField: ['primaryCarePractitioner'],
            placeHolder: '',
            mandatory: false,
            fieldType: 'view',
        },
        {
            label: 'Last Name',
            key: 'lastName',
            dataField: ['primaryCarePractitioner'],
            placeHolder: '',
            mandatory: false,
            fieldType: 'view',
        },
        {
            label: 'Physician NPI',
            key: 'npi',
            dataField: ['primaryCarePractitioner'],
            placeHolder: '',
            mandatory: false,
            fieldType: 'view',
        },
    ];

    const tabData = [
        {
            key: '1',
            title: 'Main info',
            view: true,
            edit: true,
            viewCardTitles: [
                {
                    title: 'Patient Information',
                    cols: [
                        { from: 0, to: 3 },
                        { from: 3, to: 5 },
                        { from: 5, to: 10 },
                    ],
                },
            ],
            fields: mainInfoFields,
        },
        {
            key: '2',
            title: 'Primary Admission',
            viewCardTitles: [
                {
                    title: '',
                    cols: [
                        { from: 0, to: 3 },
                        { from: 3, to: 7 },
                        // Extra cols for styling
                        { from: 0, to: 0 },
                    ],
                },
            ],
            edit: true,
            view: false,
            fields: admissionFields,
        },
        {
            key: '3',
            title: 'PCP',
            viewCardTitles: [
                {
                    title: 'Primary Care Practitioner',
                    cols: [
                        { from: 0, to: 4 },
                        // Extra cols for styling
                        { from: 0, to: 0 },
                    ],
                },
            ],
            edit: true,
            view: false,
            fields: practitionerFields,
        },
    ];
    const [activeKey, setActiveKey] = React.useState<string>('1');

    const getDropDownOption = async () => {
        try {
            const response = await dropdown_options('');
            return response;
        } catch (error) {
            return error;
        }
    };

    const getPractiontionerOptions = async (_id: string) => {
        try {
            const response = await practitioner_Options(_id);
            return response;
        } catch (error) {
            return error;
        }
    };

    const onKeyChange = (key: string) => {
        setActiveKey(key);
    };

    return (
        <div className="tab-styles">
            <Tabs
                defaultActiveKey={tabData[0].key}
                activeKey={activeKey}
                onChange={onKeyChange}
                tabBarExtraContent={
                    <div className={'display-flex'}>
                        <Button type="default" onClick={() => toggleEditMode()}>
                            Edit
                        </Button>
                    </div>
                }
            >
                {tabData.map((tab) => (
                    <Tabs.TabPane tab={tab.title} key={tab.key}>
                        <Card className="card-width">
                            <Form
                                className="patient-form"
                                name="editForm"
                                form={form}
                                preserve={true}
                            >
                                <PatientAdmitionForm
                                    selectedTab={tab}
                                    fields={tab.fields}
                                    updateMode={true}
                                />
                            </Form>
                        </Card>
                    </Tabs.TabPane>
                ))}
            </Tabs>
        </div>
    );
};
export default PatientViewDetails;
