import React, {useCallback, useEffect, useState} from "react";
import * as ets from "../../utilities/extensions";
import {Radio} from "antd";

const RadioList = ({data}) => {
    const {key, items, onChange, defaultState={}} = data;

    const [selected, setSelected] = useState(key in defaultState ? defaultState[key] : "");

    const onChoiceGroupSelectionChange = useCallback((e) => {
        setSelected(e.target.value);
    }, []);

    useEffect(() => { ets.isFunction(onChange) && onChange({[key]: selected}); }, [key,onChange, selected]);

    useEffect(() => {
        if(key in defaultState){
            setSelected( defaultState[key]);
        }
    }, [key,defaultState]);

    return (
        <Radio.Group onChange={onChoiceGroupSelectionChange} value={selected}>
            {
                items.map(o=> <Radio key={o.key} value={o.key}>{o.title}</Radio>)
            }
        </Radio.Group>

    );
};


export default RadioList;
