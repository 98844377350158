import React, { useEffect, useRef, useState } from 'react';
import { Button, Typography, Card, Spin, Modal } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
    LeftOutlined,
    EditOutlined,
    RightOutlined,
    ExclamationCircleOutlined,
} from '@ant-design/icons';
import '../../styles/_patientCard.scss';
import { UsDateformat, formatDate } from '../../utilities/extensions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
    selectPatientResult,
    updatePatientResult,
} from '../../features/patient/patientSlice';
import {
    useFetchTeamOptionsMutation,
    usePatientDetailsMutation,
    usePostPatientAllergyBypassUploadMutation,
    usePostPatientGetHelpMutation,
    usePostPatientScriptSureCookieMutation,
} from '../../features/patient/patientService';
import StatusPill from '../../components/Utils/StatusPill';
import TextArea from 'antd/lib/input/TextArea';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { logOut, setCredentials } from '../../features/auth/authSlice';
import { bindActionCreators } from 'redux';

interface PatientData {
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    hrxPatientId: string;
    medicalRecordId: string;
    patientStatusDisplay: string;
    dischargedOnEmr: boolean;
}

const PatientCard = () => {
    const { Title } = Typography;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [patientDetails] = usePatientDetailsMutation();
    const [teamDetails] = useFetchTeamOptionsMutation();
    const [patientScriptSureCookie] = usePostPatientScriptSureCookieMutation();
    const [patientAllergyBypassUpload] =
        usePostPatientAllergyBypassUploadMutation();
    const [patientGetHelp] = usePostPatientGetHelpMutation();
    const patient_details = useSelector(selectPatientResult, shallowEqual);
    const { id: patientId } = useParams();
    const [loading, setLoading] = useState<boolean>(false);
    const [scriptSureOpen, setScriptSureOpen] = useState<boolean>(false);
    const [allergyModalOpen, setAllergyModalOpen] = useState<boolean>(false);
    const [getHelpModalOpen, setGetHelpModalOpen] = useState<boolean>(false);
    const [sourceUrl, setSourceUrl] = useState<string>('');
    const [scriptSureLoading, setScriptSureLoading] = useState<boolean>(true);
    const [helpDescriptionValue, setHelpDescriptionValue] =
        useState<string>('');
    const [monthlyDaysOfCare, setMonthlyDaysOfCare] = useState<any>(0);
    const iframeRef: any = useRef(null);
    const bac = bindActionCreators({ setCredentials }, dispatch);

    let data: PatientData | undefined | any = patient_details?.patientDetails;
    let teamData: any = patient_details?.teamOptions;

    const closeGetHelpModal = () => {
        setLoading(false);
        setGetHelpModalOpen(false);
        setHelpDescriptionValue('');
    };

    const submitHelp = async (data: any) => {
        setLoading(true);
        try {
            let loginUserData: any = {};
            const localToken: any = localStorage.getItem('local-token');
            if (localToken !== null) {
                loginUserData = JSON.parse(localToken);
            }

            const sentData = {
                ErrorDescription: {
                    SessionToken:
                        loginUserData?.profile?.scriptSure?.sessiontoken,
                    WidgetDetails: sourceUrl,
                    Notes:
                        helpDescriptionValue === ''
                            ? null
                            : helpDescriptionValue,
                },
                HospiceOrganizationId: data?.hospiceOrganizationId,
                PatientId: data?.patientId,
            };
            if (!window.navigator.onLine) {
                withReactContent(Swal).fire({
                    icon: 'error',
                    title: 'You are Offline',
                    text: 'Please check your internet connection',
                    confirmButtonColor: 'rgb(33,150,243)',
                });
                closeGetHelpModal();
                return;
            }
            const response = await patientGetHelp(sentData);
            if (!response.hasOwnProperty('error')) {
                withReactContent(Swal).fire({
                    text: 'Request submitted successfully',
                    icon: 'success',
                    confirmButtonColor: 'rgb(33,150,243)',
                });
            } else {
                withReactContent(Swal).fire({
                    icon: 'error',
                    text: 'Support request failed',
                    confirmButtonColor: 'rgb(33,150,243)',
                });
            }
            closeGetHelpModal();
        } catch (error) {
            closeGetHelpModal();
            withReactContent(Swal).fire({
                icon: 'error',
                text: 'Support request failed',
            });
            console.log({ error });
        }
    };

    const getScriptSureData = async (SessionToken: any, PatientId: any) => {
        try {
            const response = await patientScriptSureCookie({
                SessionToken,
                PatientId,
            });
            return response;
        } catch (error) {
            console.log({ error });
        }
    };

    const redirectScriptSureUrl = async (data: any, gotoAllergyUrl = false) => {
        try {
            setLoading(true);
            let loginUserData: any = {};
            const localToken: any = localStorage.getItem('local-token');
            if (localToken !== null) {
                loginUserData = JSON.parse(localToken);
            }
            const scriptSurePatientId: string = data.scriptSurePatientId;
            if (!scriptSurePatientId) {
                alert(
                    'ePrescribe Authentication for Patient failed. Patient updates may still be processing. Try reloading the patient again if ePrescribe is failing.',
                );
                setLoading(false);
                return null;
            }
            const loggedUserPatientId: string = data.patientId;
            const loggedUserScriptSureseSsionToken: string =
                loginUserData.profile.scriptSure.sessiontoken;

            const response: any = await getScriptSureData(
                loggedUserScriptSureseSsionToken,
                loggedUserPatientId,
            );

            const scriptSureSessionToken: string = response.data.sessiontoken;
            const webAppUrl: string = response.data.webAppUrl;

            // Updates session token if expired.
            if (
                loginUserData.profile.scriptSure.sessiontoken !==
                response.data.sessiontoken
            ) {
                loginUserData.profile.scriptSure = response.data;
                bac.setCredentials(loginUserData);
            }

            if (!gotoAllergyUrl) {
                const sourceUrl: string = `${webAppUrl}/chart/${scriptSurePatientId}/prescriptions?sessiontoken=${scriptSureSessionToken}&darkmode=off`;
                setSourceUrl(sourceUrl);
            } else {
                const sourceUrl: string = `${webAppUrl}/widgets/allergy/${scriptSurePatientId}?sessiontoken=${scriptSureSessionToken}&darkmode=off`;
                setSourceUrl(sourceUrl);
            }
            setScriptSureOpen(true);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const gotoAllergy = () => {
        setAllergyModalOpen(false);
        redirectScriptSureUrl(data, true);
    };
    const confirmAllergyModal = async (data: any) => {
        setAllergyModalOpen(false);
        try {
            let loginUserData: any = {};
            const localToken: any = localStorage.getItem('local-token');
            if (localToken !== null) {
                loginUserData = JSON.parse(localToken);
            }
            const sentData = {
                ClinicianId: loginUserData.profile.id,
                PatientId: data.patientId,
                BypassedOn: new Date(),
            };
            const response = await patientAllergyBypassUpload(sentData);
            if (response) {
                redirectScriptSureUrl(data);
            }
        } catch (error) {
            console.log({ error });
        }
    };

    const onClickEprescribe = async (data: any) => {
        if (data?.allergiesConfirmed) {
            await redirectScriptSureUrl(data);
        } else {
            setAllergyModalOpen(true);
        }
    };

    const getPatientDetails = async (_id: any) => {
        try {
            const response = await patientDetails(_id);
            return response;
        } catch (error) {
            return error;
        }
    };
    const getTeamDetails = async () => {
        try {
            const response = await teamDetails('');
            return response;
        } catch (error) {
            return error;
        }
    };

    const getMonthlyDaysOfCare = (data: any) => {
        if (data) {
            if (
                (data?.patientStatusDisplay === 'Admitted' &&
                    typeof data?.eligibilityStartDate === 'string') ||
                typeof data?.eligibilityEndDate === 'string'
            ) {
                let date: any = new Date();
                const [currentYear, currentMonth, currentDate]: Array<any> = [
                    date.getFullYear(),
                    date.getMonth() + 1,
                    date.getDate(),
                ];

                if (data?.patientStatusDisplay === 'Admitted') {
                    const [
                        eligibilityStartYear,
                        eligibilityStartMonth,
                        eligibilityStartDay,
                    ]: Array<any> = (data?.eligibilityStartDate)
                        .slice(0, 10)
                        .split('-');

                    if (
                        currentYear - eligibilityStartYear === 0 &&
                        currentMonth - eligibilityStartMonth === 0
                    ) {
                        setMonthlyDaysOfCare(
                            currentDate - eligibilityStartDay + 1,
                        );
                    } else {
                        setMonthlyDaysOfCare(currentDate);
                    }
                } else {
                    const [
                        eligibilityEndYear,
                        eligibilityEndMonth,
                        eligibilityEndDay,
                    ] = (data?.eligibilityEndDate).slice(0, 10).split('-');
                    if (
                        currentYear - eligibilityEndYear === 0 &&
                        currentMonth - eligibilityEndMonth === 0
                    ) {
                        setMonthlyDaysOfCare(
                            currentDate - eligibilityEndDay + 1,
                        );
                    }
                }
            }
        }
    };

    const getPatientData = async (patientId: any) => {
        const response: any = await getPatientDetails(patientId);
        data = response;
        dispatch(updatePatientResult(patientId));
        setLoading(false);
        getMonthlyDaysOfCare(response?.data);
    };

    const getTeamData = async () => {
        const response = await getTeamDetails();
        data = response;
        // dispatch(updatePatientResult(patientId));
        setLoading(false);
    };

    const renderModalContent = () => {
        return (
            <>
                <div className="display-flex">
                    <ExclamationCircleOutlined
                        rev={undefined}
                        className="WarningModalIcon"
                    />
                    <p className="para-styles">Drug History</p>
                </div>
                <div className="error-btn">
                    You have not completed allergies information, would you like
                    to bypass this and access ePrescribe?
                </div>
            </>
        );
    };
    const renderGetHelpModalContent = () => {
        return (
            <Spin spinning={loading} size="large">
                <div className="display-flex">
                    <p className="para-styles">Get Help</p>
                </div>
                <div className="error-btn m-1 mb-3">
                    Please provide a brief description of the issue you are
                    facing with the e-prescribe functionality
                </div>
                <div>
                    <TextArea
                        rows={4}
                        placeholder="Description"
                        value={helpDescriptionValue}
                        onChange={(e) =>
                            setHelpDescriptionValue(e.target.value)
                        }
                    />
                </div>
            </Spin>
        );
    };

    const backNavigateFromScriptSureIframe = async () => {
        setLoading(true);
        await getPatientData(patientId);
        setScriptSureOpen(false);
    };

    useEffect(() => {
        getPatientData(patientId);
        setLoading(true);
        getMonthlyDaysOfCare(data);

        if (
            (Boolean(teamData) && JSON.stringify(teamData) === '{}') ||
            teamData.length === 0
        ) {
            getTeamData();
            setLoading(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getPatientTeamName = () => {
        if (teamData?.data && teamData?.data.length > 0) {
            return teamData?.data.find(
                (team: any) => team.code === data.teamCode,
            );
        }
        return { name: 'N/A' };
    };

    return (
        <Spin spinning={loading} size="large">
            <div className="search-patient">
                <div className="patient-name">
                    <LeftOutlined
                        className="left-arrow mt-4"
                        onClick={() =>
                            scriptSureOpen
                                ? backNavigateFromScriptSureIframe()
                                : navigate('/dashboard')
                        }
                        rev={undefined}
                    />
                    <Title level={1} className="title-class">
                        {scriptSureOpen
                            ? data?.demographics?.firstName + ' / ePrescribe'
                            : data?.demographics?.firstName}
                    </Title>
                </div>
                {scriptSureOpen && (
                    <div className="get-help">
                        <button
                            type="button"
                            style={{ backgroundColor: 'rgb(33,150,243)' }}
                            className="btn btn-primary btn-sm"
                            onClick={() => setGetHelpModalOpen(true)}
                        >
                            Get Help
                        </button>
                    </div>
                )}
            </div>

            {!scriptSureOpen && (
                <div className="patient-team ms-4">
                    <span className="label">Patient’s Team:</span>
                    <span className="team-name ms-1">
                        {getPatientTeamName()?.name}
                    </span>
                </div>
            )}
            {scriptSureOpen && (
                <div className="scriptsure-iframe" style={{ height: '100vh' }}>
                    <iframe
                        src={sourceUrl}
                        id="scriptsure-iframe"
                        ref={iframeRef}
                        width="100%"
                        loading={'lazy'}
                        height="100%"
                        title="HospisRx"
                        onLoad={() => setScriptSureLoading(false)}
                    />
                </div>
            )}
            {scriptSureLoading && scriptSureOpen && (
                <Spin spinning={scriptSureLoading} size="large" />
            )}
            {scriptSureOpen ? null : (
                <div className="card-style">
                    <Card bordered={false} style={{ width: '78%' }}>
                        <div className="info-hedaer mb-3 mt-3">
                            <div className="border-none">
                                <Title
                                    className="d-inline-block"
                                    level={5}
                                    style={{
                                        paddingLeft: '12px',
                                        fontWeight: '400',
                                    }}
                                >
                                    Patient Status
                                </Title>
                                <StatusPill
                                    className="patient-display-status"
                                    title={data?.patientStatusDisplay}
                                />

                                {data?.dischargedOnEmr && (
                                    <StatusPill
                                        className="patient-discharged-on-emr-display-status"
                                        title={'Discharged on EMR'}
                                    />
                                )}

                                {[1, 2].includes(data?.patientStatus) && (
                                    <div className="view-btn-card">
                                        <label
                                            onClick={() =>
                                                navigate(
                                                    `/patient_record/${data.patientId}`,
                                                    {
                                                        state: {
                                                            action: 'VIEW',
                                                        },
                                                    },
                                                )
                                            }
                                        >
                                            View
                                        </label>
                                        <EditOutlined
                                            onClick={() =>
                                                navigate(
                                                    `/patient_record/${data.patientId}`,
                                                    {
                                                        state: {
                                                            action: 'EDIT',
                                                        },
                                                    },
                                                )
                                            }
                                            className="view-btn"
                                            rev={undefined}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="display-flex">
                            <Card
                                bordered={false}
                                className="info-element-class"
                            >
                                <Title level={5}>Personal Info</Title>
                                <p>
                                    First Name: {data?.demographics?.firstName}
                                </p>
                                <p>Last Name: {data?.demographics?.lastName}</p>
                                <p>
                                    Date of Birth:{' '}
                                    {UsDateformat(
                                        data?.demographics?.dateOfBirth,
                                    )}
                                </p>
                                <p>HCRx Patient ID: {data?.hrxPatientId}</p>
                            </Card>
                            <Card
                                bordered={false}
                                style={{ width: 270, marginTop: 32 }}
                            >
                                <p>Medical Record: {data?.medicalRecordId}</p>
                                <p>
                                    Start of Care Date:{' '}
                                    {UsDateformat(data?.eligibilityStartDate)}
                                </p>
                                <p>
                                    Current Month Days of Care:{' '}
                                    {monthlyDaysOfCare}
                                </p>
                            </Card>
                            <Card bordered={false} style={{ width: 270 }}>
                                <Title level={5}>
                                    Primary Care Practitioner
                                </Title>
                                <p>
                                    First Name:{' '}
                                    {data?.primaryCarePractitioner?.firstName}
                                </p>
                                <p>
                                    Last Name:{' '}
                                    {data?.primaryCarePractitioner?.lastName}
                                </p>
                                <p>
                                    Credential:{' '}
                                    {data?.primaryCarePractitioner?.userId}
                                </p>
                            </Card>
                        </div>
                        <div className="container-class mt-4">
                            <div className="button-container">
                                {/* <Button className="button">Request Consultation</Button>
                        <Button className="button">
                           Review Medication History
                        </Button> */}
                                {data.patientStatus === 2 && (
                                    <Link
                                        to={`/patient-discharge/${patientId}`}
                                    >
                                        <Button type="primary">
                                            Discharge Patient
                                        </Button>
                                    </Link>
                                )}
                                {(data?.patientStatus === 0 ||
                                    data?.patientStatus > 2) && (
                                    <Link
                                        to={`/patient/${data.patientId}/re-admit`}
                                    >
                                        <Button type="primary">Re-Admit</Button>
                                    </Link>
                                )}
                            </div>
                        </div>
                    </Card>
                    <Card
                        bordered={false}
                        style={{ width: '20%', background: 'none' }}
                    >
                        <Title level={5} className="mt-3">
                            ePrescribe Function
                        </Title>
                        <div>
                            <div
                                className="ePrescribe-btn"
                                onClick={() => onClickEprescribe(data)}
                            >
                                <label className="ePrescribe-btn-margin">
                                    Access ePrescribe
                                </label>
                                <RightOutlined rev={undefined} />
                            </div>
                            <StatusPill
                                className={
                                    data?.allergiesConfirmed
                                        ? 'allergy-complete'
                                        : 'allergy-pending'
                                }
                                title={
                                    data?.allergiesConfirmed
                                        ? 'Allergies info complete'
                                        : 'Allergies info incomplete'
                                }
                            />
                        </div>
                    </Card>
                </div>
            )}
            {allergyModalOpen && (
                <Modal
                    open={allergyModalOpen}
                    closable={false}
                    footer={[
                        <Button
                            key="gotoallergy"
                            type="primary"
                            className="go-allergy-btn"
                            onClick={() => gotoAllergy()}
                        >
                            {'Go to allergies'}
                        </Button>,
                        <Button
                            key="confirm"
                            type="primary"
                            className="confirm-allergy-btn"
                            onClick={() => confirmAllergyModal(data)}
                        >
                            {'Confirm'}
                        </Button>,
                    ]}
                >
                    {renderModalContent()}
                </Modal>
            )}
            {getHelpModalOpen && (
                <Modal
                    open={getHelpModalOpen}
                    closable={false}
                    footer={[
                        <Button
                            key="gotoallergy"
                            disabled={loading}
                            type="primary"
                            className="cancel-btn"
                            onClick={() => closeGetHelpModal()}
                        >
                            {'Cancel'}
                        </Button>,
                        <Button
                            key="Submit"
                            disabled={loading}
                            type="primary"
                            className="submit-help"
                            onClick={() => submitHelp(data)}
                        >
                            {'Submit'}
                        </Button>,
                    ]}
                >
                    {renderGetHelpModalContent()}
                </Modal>
            )}
        </Spin>
    );
};

export default PatientCard;
