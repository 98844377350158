

import { createSlice } from "@reduxjs/toolkit";
import {appointmentApiSlice} from "./appointmentService";
import {RootState} from "../appStore";
import {ClinicalConsult} from "../../models/AppointmentModels";
import {IsNotUndefinedOrNotNull} from "../../utilities/extensions";

const initialState = {
    selectedItem: {id : "0"} as ClinicalConsult
};

const {matchFulfilled} = appointmentApiSlice.endpoints.getClinicalConsults

export const SLICE_APPOINTMENT_NAME = "appointment";
export const appointmentSlice = createSlice({
    name: SLICE_APPOINTMENT_NAME,
    initialState,
    reducers: {
        setSelectedAppointment: (state,{payload}) => {
            state.selectedItem = payload;
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(matchFulfilled,(state, { payload }) => {
           if(IsNotUndefinedOrNotNull(state.selectedItem) && state.selectedItem.id != "0") {
               const sItem = payload.data?.find(i=> i.id == state.selectedItem.id);
               if(sItem != undefined) state.selectedItem = sItem;
           }
        })
    }
});

export const { setSelectedAppointment } = appointmentSlice.actions;

export default appointmentSlice.reducer;

export const selectAppointmentState = (state: RootState) => state[SLICE_APPOINTMENT_NAME];
export const selectSelectedItem = (state: RootState) => state[SLICE_APPOINTMENT_NAME].selectedItem;
