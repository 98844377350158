//don't mix filter with pageResponse:NotificationsResponse, filter uses only pageNumber and pageSize only
import {
    IsArrayWithLength,
    isNotNullOrUndefined,
    isObjectAndNotNull,
    ObjectKeysFilter
} from "../../utilities/extensions";

export const defaultReportsFilter = { pageNumber:1, pageSize:10, channels:[], dateRange: { type:0 }, messageTypes:[]};
//status 16 is NotificationStatusEnum Failed
export const defaultFailuresFilter = { pageNumber:1, pageSize:10, channels:[], dateRange: { type:0 }, messageTypes:[], Status:[16]};

export const notificationsSearchFields = ["DestinationAccount", "NotificationContent", "JobDetail.HospiceOrganization.HospiceRefId"]


const NotificationsSchema = [

    {
        key:"channels",
        type:"checkList",
        title:"Channels",
        items:[
            {
                key:"sms",
                title:"SMS",
                value:1
            },
            {
                key:"email",
                title:"Email",
                value:2
            }
        ]
    },
    {
        key:"messageTypes",
        type:"checkList",
        title:"Message Types",
        items:[
            {
                key:"fulfillmentStatus",
                title:"Fulfillment",
                value:1
            },
            {
                key:"physicianDenied",
                title:"PhysicianDenied",
                value:2
            },
            {
                key:"claimNotAccepted",
                title:"ClaimNotAccepted",
                value:3
            },
            {
                key:"claimNotAdjudicated",
                title:"ClaimNotAdjudicated",
                value:4
            }
        ]
    },
    {
        key:"dateRange",
        type:"dateRange",
        title:"Date Range",
        items:[
            {
                key:"24hours",
                title:"Last 24 hours",
                value:1
            },
            {
                key:"7Days",
                title:"Last 7 Days",
                value:2
            },
            {
                key:"custom",
                title:"Custom Date Range",
                value:3,
                items:[
                    {
                        key:"startDate",
                        title:"Start date",
                        placeholder:"Select start data"
                    },
                    {
                        key:"endDate",
                        title:"End date",
                        placeholder:"Select end data"
                    }
                ]
            }
        ]
    }
]

export default NotificationsSchema;

export const notificationsFilterKeys = NotificationsSchema.map(i=>i.key);

export const notificationsFilterCount = (filterData) => {
    let count = 0;
    if(filterData !== null && filterData !== undefined){
        const onlyFilterObject = ObjectKeysFilter(filterData,notificationsFilterKeys);
        for (const [_, value] of Object.entries(onlyFilterObject)) {
            if (Array.isArray(value)) count += value.length;
            if (isObjectAndNotNull(value) && isNotNullOrUndefined(value['type']) && value['type'] !== 0) count += 1;
        }
    }
    return count;
};


export const notificationsFilterTags = (filterData) => {
    let tags = [];
    if(filterData !== null && filterData !== undefined){
        const onlyFilterObject = ObjectKeysFilter(filterData,notificationsFilterKeys);
        for (const [key, value] of Object.entries(onlyFilterObject)) {
            const sObj = NotificationsSchema.find(i=>i.key === key);
            // example channels
            if (IsArrayWithLength(value) && sObj !== undefined && IsArrayWithLength(sObj.items)){
                const items = sObj.items.filter(i=> value.includes(i.value)).map(i=>({...i, pKey:key}))
                tags = [...tags, ...items];
            }
            // example dateRange
            else if (sObj !== undefined && isObjectAndNotNull(value) && isNotNullOrUndefined(value['type']) && value['type'] !== 0){
                const item = sObj.items.find(i=> i.value === value['type']);
                tags = [...tags, {...item,pKey:key} ];
            }
        }
    }
    return tags;
};

//tag {  key:"sms", title:"SMS", value:1, pKey:"channels"}
export const notificationsFilterRemoveTag = (tag, filterData) => {
    if(filterData !== null && filterData !== undefined) {
        let item = filterData[tag.pKey];
        if (Array.isArray(item)){
            filterData[tag.pKey] = item.filter(i=>i !== tag.value);
        }
        if (isObjectAndNotNull(item) && isNotNullOrUndefined(item['type']) && item['type'] !== 0){
            filterData[tag.pKey] = {type:0};
        }
    }
    return filterData;
}


/*
const DefaultSchema = [

    {
        key:"channel",
        type:"checkList",
        title:"Channel",
        items:[
            {
                key:"sms",
                title:"SMS",
                value:"sms"
            },
            {
                key:"email",
                title:"Email",
                value:"email"
            },
            {
                key:"mms",
                title:"Mss",
                value:"mss"
            }
        ]
    },
    {
        key:"option",
        type:"radioList",
        title:"Gender",
        items:[
            {
                key:"male",
                title:"Male",
                value:"male"
            },
            {
                key:"female",
                title:"Female",
                value:"female"
            },
            {
                key:"other",
                title:"Other",
                value:"other"
            }
        ]
    },
    {
        key:"startWith",
        type:"textBoxList",
        title:"Start with",
        items:[
            {
                key:"firstName",
                title:"First name start with",
                placeholder:"Start first name with",
            },
            {
                key:"lastName",
                title:"Last name start with",
                placeholder:"Start last name with"
            }
        ]
    },
    {
        key:"dateRange",
        type:"dateRange",
        title:"Date Range",
        items:[
            {
                key:"24hours",
                title:"Last 24 hours",
                value:"last24Hours"
            },
            {
                key:"7Days",
                title:"Last 7 Days",
                value:"last7Days"
            },
            {
                key:"custom",
                title:"Custom Date Range",
                value:"custom",
                items:[
                    {
                        key:"startDate",
                        title:"Start date",
                        placeholder:"Select start data"
                    },
                    {
                        key:"endDate",
                        title:"End date",
                        placeholder:"Select end data"
                    }
                ]
            }
        ]
    }
]
 */
