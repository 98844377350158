//don't mix filter with pageResponse:FailuresResponse, filter uses only pageNumber and pageSize only
import {
   IsArrayWithLength,
   isNotNullOrUndefined,
   isObjectAndNotNull,
   ObjectKeysFilter,
} from '../../utilities/extensions';

//status 16 is FailureStatusEnum Failed
export const defaultFailuresFilter = {
   pageNumber: 1,
   pageSize: 10,
   channels: [],
   dateRange: { type: 0 },
   messageTypes: [],
   Status: [16],
};

export const failuresSearchFields = [
   'Service',
   'Severity',
   'Reason',
   'Direction',
];

const FailuresSchema = [
   {
      key: 'services',
      type: 'checkList',
      title: 'Service',
      items: [
         {
            key: 'redox',
            title: 'Redox',
            value: 'Redox',
         },
         {
            key: 'rxlogic',
            title: 'RxLogic',
            value: 'RxLogic',
         },
         {
            key: 'scriptSure',
            title: 'ScriptSure',
            value: 'ScriptSure',
         },
         {
            key: 'twilio',
            title: 'Twilio',
            value: 'Twilio',
         },
         {
            key: 'sendGrid',
            title: 'SendGrid',
            value: 'SendGrid',
         },
      ],
   },
   {
      key: 'eventTypes',
      type: 'checkList',
      title: 'Event Type',
      items: [
         {
            key: 'InboundWebhookPreProcess',
            title: 'Inbound Webhook PreProcess',
            value: 20,
         },
         {
            key: 'PatientAdminNewPatientInbound',
            title: 'Patient Admin New Patient (Inbound)',
            value: 10,
         },
         {
            key: 'PatientAdminNewPatientOutbound',
            title: 'Patient Admin New Patient (Outbound)',
            value: 4,
         },
         {
            key: 'PatientAdminPatientUpdateInbound',
            title: 'Patient Admin Patient Update (Inbound)',
            value: 9,
         },
         {
            key: 'PatientAdminPatientUpdateOutbound',
            title: 'Patient Admin Patient Update (Outbound)',
            value: 7,
         },
         {
            key: 'RedoxInbound',
            title: 'Redox Inbound',
            value: 2,
         },
         {
            key: 'RedoxOutbound',
            title: 'Redox Outbound',
            value: 1,
         },
         {
            key: 'RedoxOutboundAllergy',
            title: 'Redox Outbound Allergy',
            value: 16,
         },
         {
            key: 'RedoxOutboundClinicialSummary',
            title: 'Redox Outbound Clinical Summary Request',
            value: 14,
         },
         {
            key: 'RedoxOutboundDiagnosis',
            title: 'Redox Outbound Diagnosis',
            value: 18,
         },
         {
            key: 'RedoxOutboundDischargePatient',
            title: 'Redox Outbound Discharge Patient',
            value: 21,
         },
         {
            key: 'RedoxOutboundDiscontinueMedication',
            title: 'Redox Outbound Discontinue Medication',
            value: 19,
         },
         {
            key: 'RedoxOutboundMedication',
            title: 'Redox Outbound Medication',
            value: 13,
         },
         {
            key: 'RxLogicEligibilityOutbound',
            title: 'RxLogic Eligibility Upload',
            value: 8,
         },
         {
            key: 'RxLogicOutboundDischargePatient',
            title: 'RxLogic Outbound Discharge Patient',
            value: 22,
         },
         {
            key: 'SendEmail',
            title: 'Send Email',
            value: 3,
         },
         {
            key: 'SendSms',
            title: 'Send Sms',
            value: 5,
         },
         {
            key: 'ScriptSureAllergyNotificationInbound',
            title: 'ScriptSure Allergy Notification',
            value: 15,
         },
         {
            key: 'ScriptSureDiagnosisNotificationInbound',
            title: 'ScriptSure Diagnosis Notification',
            value: 17,
         },
         {
            key: 'ScriptSureNcpdpNotificationInbound',
            title: 'ScriptSure NCPDP Notification',
            value: 25,
         },
         {
            key: 'ScriptSureOutboundDischargePatient',
            title: 'ScriptSure Outbound Discharge Patient',
            value: 26,
         },
         {
            key: 'ScriptSurePatientOutbound',
            title: 'ScriptSure Patient Outbound',
            value: 11,
         },
         {
            key: 'ScriptSurePrescriptionNotificationInbound',
            title: 'ScriptSure Prescription Notification',
            value: 12,
         },
         {
            key: 'ConsultSentConfirmationMessage',
            title: 'Send Consult Queued Confirmation Message',
            value: 24,
         },
         {
            key: 'ThirdPartyStatusCheck',
            title: 'Third Party Status Check',
            value: 6,
         },
         {
            key: 'QueueConsultRequestToPharmacist',
            title: 'Queue Consult Request',
            value: 23,
         },
      ],
   },
   {
      key: 'dateRange',
      type: 'dateRange',
      title: 'Date Range',
      items: [
         {
            key: '24hours',
            title: 'Last 24 hours',
            value: 1,
         },
         {
            key: '7Days',
            title: 'Last 7 Days',
            value: 2,
         },
         {
            key: 'custom',
            title: 'Custom Date Range',
            value: 3,
            items: [
               {
                  key: 'startDate',
                  title: 'Start date',
                  placeholder: 'Select start data',
               },
               {
                  key: 'endDate',
                  title: 'End date',
                  placeholder: 'Select end data',
               },
            ],
         },
      ],
   },
];

export default FailuresSchema;

export const failuresFilterKeys = FailuresSchema.map((i) => i.key);

export const failuresFilterCount = (filterData) => {
   let count = 0;
   if (filterData !== null && filterData !== undefined) {
      const onlyFilterObject = ObjectKeysFilter(filterData, failuresFilterKeys);
      for (const [_, value] of Object.entries(onlyFilterObject)) {
         if (Array.isArray(value)) count += value.length;
         if (
            isObjectAndNotNull(value) &&
            isNotNullOrUndefined(value['type']) &&
            value['type'] !== 0
         )
            count += 1;
      }
   }
   return count;
};

export const failuresFilterTags = (filterData) => {
   let tags = [];
   if (filterData !== null && filterData !== undefined) {
      const onlyFilterObject = ObjectKeysFilter(filterData, failuresFilterKeys);
      for (const [key, value] of Object.entries(onlyFilterObject)) {
         const sObj = FailuresSchema.find((i) => i.key === key);
         // example channels
         if (
            IsArrayWithLength(value) &&
            sObj !== undefined &&
            IsArrayWithLength(sObj.items)
         ) {
            const items = sObj.items
               .filter((i) => value.includes(i.value))
               .map((i) => ({ ...i, pKey: key }));
            tags = [...tags, ...items];
         }
         // example dateRange
         else if (
            sObj !== undefined &&
            isObjectAndNotNull(value) &&
            isNotNullOrUndefined(value['type']) &&
            value['type'] !== 0
         ) {
            const item = sObj.items.find((i) => i.value === value['type']);
            tags = [...tags, { ...item, pKey: key }];
         }
      }
   }
   return tags;
};

//tag {  key:"sms", title:"SMS", value:1, pKey:"channels"}
export const failuresFilterRemoveTag = (tag, filterData) => {
   if (filterData !== null && filterData !== undefined) {
      let item = filterData[tag.pKey];
      if (Array.isArray(item)) {
         filterData[tag.pKey] = item.filter((i) => i !== tag.value);
      }
      if (
         isObjectAndNotNull(item) &&
         isNotNullOrUndefined(item['type']) &&
         item['type'] !== 0
      ) {
         filterData[tag.pKey] = { type: 0 };
      }
   }
   return filterData;
};
