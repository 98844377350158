import { Typography } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import '../../styles/_patientRecord.scss';
import PatientAdmition from '../../components/Patient/PatientAdmition';
import { useForm } from 'antd/lib/form/Form';
import { shallowEqual, useSelector } from 'react-redux';
import { usePatientDetailsMutation } from '../../features/patient/patientService';
import { selectPatientResult } from '../../features/patient/patientSlice';
import { AnyObject } from 'yup/lib/types';
import { useEffect, useMemo } from 'react';
import moment from 'moment';

interface PatientData {
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    hrxPatientId: string;
    medicalRecordId: string;
    patientStatusDisplay: string;
}

const ReAdmitPatient = () => {
    const { Title } = Typography;
    const navigate = useNavigate();
    const { id: patientId } = useParams();

    const [patientDetails] = usePatientDetailsMutation();
    const patient_details = useSelector(selectPatientResult, shallowEqual);
    let data: PatientData | AnyObject | any = patient_details?.patientDetails;

    const getPatientDetails = async (_id: any) => {
        try {
            const response = await patientDetails(_id);
            return response;
        } catch (error) {
            return error;
        }
    };

    const getPatientData = async (patientId: any) => {
        const response: any = await getPatientDetails(patientId);
        data = response.data;
    };

    useEffect(() => {
        const fetchData = async () => {
            if (
                (Boolean(data) && JSON.stringify(data) === '{}') ||
                Boolean(data) === false
            ) {
                await getPatientData(patientId);
            }
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [form] = useForm();

    const patientData = useMemo(() => {
        if (patient_details?.patientDetails)
            return {
                ...data,
                patientStatus: 2,
                eligibilityStartDate: moment().format('YYYY-MM-DD'),
            };
    }, [patient_details?.patientDetails]);

    const onNavigation = () => {
        navigate(`/patient/${patientId}`);
    };

    const modalContents = {
        doneModal: {
            label: 'Success!',
            message: `Patient information update was successfully submitted.`,
            iconStyle: 'DoneModalIcon',
        },
        WarningModal: {
            label: 'Error',
            message:
                'Patient Information was not successfully updated. Please try again.',
            iconStyle: 'WarningModalIcon',
        },
    };

    return (
        <>
            <div className="search-patient">
                <LeftOutlined
                    className="left-arrow"
                    onClick={onNavigation}
                    rev={undefined}
                />
                <Title level={1} className="title-class">
                    {'Re-Admit Patient'}
                </Title>
            </div>
            <PatientAdmition
                form={form}
                patientData={patientData}
                showMRN={false}
                modalContents={modalContents}
            />
        </>
    );
};
export default ReAdmitPatient;
