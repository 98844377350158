import React, { useEffect, useMemo, useState } from 'react';
import { columns } from './SessionManageData';
import { useSelector } from 'react-redux';
import { Button, Table } from 'antd';
import { selectSession } from '../../features/session/sessionSlice';
import {
   useDataSessionMutation,
   useSessionActionMutation,
} from '../../features/session/sessionService';
import Search from 'antd/es/input/Search';
import { IoFilterOutline } from 'react-icons/all';

const SessionManageTable = () => {
   const [sendCmd, setSendCmd] = useState(0);
   const { data } = useSelector(selectSession);
   const [sessionAction, { isLoading: isSending }] = useSessionActionMutation();
   const [getsessionData, setGetSessiondData] = useState([]);
   const [dataSession] = useDataSessionMutation();

   const defaultSessionData = useMemo(() => {
      return data.flatMap((i) =>
         i.connections.map((c) => ({
            userIdentifier: i.userIdentifier,
            firstName: i.firstName,
            lastName: i.lastName,
            userEmail: i.userEmail,
            connectedOn: c.connectedOn,
            deviceType: c.deviceType,
            deviceId: c.deviceInfo.deviceId,
            deviceName: c.deviceInfo.name,
         })),
      );
   }, [data]);

   useEffect(() => {
      setGetSessiondData(defaultSessionData);
   }, [defaultSessionData]);

   useEffect(() => {
      dataSession();
   }, []);

   const sessionColumns = useMemo(() => {
      return [
         ...columns,
         {
            title: 'Actions',
            dataIndex: '',
            key: 'fActions',
            width: 150,
            align: 'center',
            render: (text, record, index) => (
               <div className="d-flex">
                  <Button
                     key={`sign-out-${index}`}
                     onClick={async (e) => {
                        setSendCmd(1);
                        const msgData = {
                           messageType: 0,
                           source: JSON.stringify({
                              deviceId: record.deviceId,
                              type: 0,
                              userId: record.userIdentifier,
                           }),
                        };
                        try {
                           await sessionAction(msgData);
                        } catch {}
                        setSendCmd(0);
                     }}
                     loading={isSending && sendCmd === 1}
                  >
                     Sign Out
                  </Button>
                  <Button
                     key={`revoke-access-${index}`}
                     className="ms-2"
                     onClick={async (e) => {
                        setSendCmd(2);
                        const msgData = {
                           messageType: 0,
                           source: JSON.stringify({
                              deviceId: record.deviceId,
                              type: 1,
                              userId: record.userIdentifier,
                           }),
                        };
                        try {
                           await sessionAction(msgData);
                        } catch {}
                        setSendCmd(0);
                     }}
                     loading={isSending && sendCmd === 2}
                  >
                     Revoke Access
                  </Button>
               </div>
            ),
         },
      ];
   }, [data, isSending, isSending]);

   const searchHandle = (e) => {
      if (e.target.value.length >= 1) {
         setGetSessiondData(
            defaultSessionData.filter((val) => {
               return (
                  val.deviceName?.toLowerCase().indexOf(e.target.value) !==
                     -1 ||
                  val.userEmail?.toLowerCase().indexOf(e.target.value) !== -1
               );
            }),
         );
      } else {
         setGetSessiondData(data);
      }
   };

   return (
      <div className="d-flex flex-column">
         <div className="d-flex justify-content-between pt-2 pb-2">
            <div className="d-flex">
               <Search
                  placeholder="Search"
                  className="failure-search"
                  onChange={searchHandle}
               />
            </div>
            <Button icon={<IoFilterOutline />} text="Filter" />
         </div>
         <Table
            pagination={{
               defaultPageSize: 10,
               defaultCurrent: 1,
               className: 'custom-pagination-label',
               showTotal: (totalCount, range) =>
                  `${range[0]}-${range[1]} of ${totalCount} items`,
            }}
            rowKey="deviceId"
            tableLayout="fixed"
            className="table-ctrl"
            columns={sessionColumns}
            dataSource={getsessionData}
            scroll={{ y: 500, x: 900 }}
         />
      </div>
   );
};

export default SessionManageTable;
