import React, {useEffect, useState} from 'react';
import {Collapse, DatePicker, List, Switch} from 'antd';
import VirtualList from "rc-virtual-list";
import {useDispatch, useSelector} from "react-redux";
import {bindActionCreators} from "@reduxjs/toolkit";
import { selectSelectedItem, setSelectedAppointment } from "../../features/appointment/appointmentSlice";
import classnames from 'classnames';
import {IsArrayWithLength, IsNotUndefinedOrNotNull} from "../../utilities/extensions";
import {BorderOutlined, CheckSquareOutlined} from "@ant-design/icons";
import moment from "moment/moment";
import {useGetClinicalConsultsQuery} from "../../features/appointment/appointmentService";

const { Panel } = Collapse;
const dateNow = moment();
const startWeekDate = dateNow.startOf('isoWeek').format("L");
const endWeekDate = dateNow.endOf('isoWeek').format("L");

const AppointmentList = () => {

    const dispatch = useDispatch();
    const [month, setMonth] = useState(dateNow.format("MMMM"));
    const [canShowCompletedConsult, setCanShowCompletedConsult] = useState(false);
    const [week, setWeek] = useState({startDateTime:startWeekDate, endDateTime:endWeekDate, dateTime:moment()});
    const apACs = bindActionCreators({setSelectedAppointment}, dispatch);
    const selectedItem = useSelector(selectSelectedItem);

    const {data:{data:uPatientConsults}=[] , isLoading:uIsLoading, refetch:reFetchUConsults, isUninitialized:isUninitializedUConsults} = useGetClinicalConsultsQuery({onlyClinicalConsultRequests:true});
    const {data:{data:patientConsults}=[],  isLoading:sIsLoading, refetch:reFetchConsults, isUninitialized:isUninitializedConsults} = useGetClinicalConsultsQuery({onlyClinicalConsultRequests:false, startDateTime:week.startDateTime, endDateTime:week.endDateTime});

    const customFormat = value => IsNotUndefinedOrNotNull(value) ? `week of ${value.format("MMMM")} ${value.startOf('week').format("Do")}`: "";

    const handleDateChange = (value, dateString) => {
        apACs.setSelectedAppointment({id:0});
        if(value !== null){
            const dataVal = value;
            setMonth(dataVal.format("MMMM"));
            setWeek({startDateTime:dataVal.startOf('week').format("L"), endDateTime: dataVal.endOf('week').format("L"), dateTime:dataVal});
        }
        else{
            setMonth(moment().format("MMMM"));
            setWeek({startDateTime:startWeekDate, endDateTime:endWeekDate, dateTime:moment()});
        }

    }

    const RenderAppointment = (item)=> {
        const {id, dateDisplay, consultReasonDisplay, teamDisplay, createdOnDisplay, isUrgent, scheduledDateTime=null, listOfPatients=[], reasonColor='#FFFFFF', duration=null} = item;
        const bColorStyle = { borderLeft:`solid ${ scheduledDateTime != null ? reasonColor : "var(--ant-error-color)"} 10px`};
        const mainClass = classnames('list-item shadow-sm mb-2 p-2 rounded-3 card-border',{ selected: id === selectedItem.id})
        return <List.Item key={id.toString()} className={mainClass}
                          style={bColorStyle}
                          onClick={e=> apACs.setSelectedAppointment(item)}>
            <div className="d-flex flex-column w-100">
                <div className="d-flex">
                    {dateDisplay || <h6 className="text-danger">REQUEST NEEDS SCHEDULED</h6>}
                </div>
                <div className="d-flex flex-column">
                    <div className="d-flex flex-column">
                        <ul className="w-100 mb-0">
                            <li>{consultReasonDisplay}</li>
                            <li>
                                <div className="d-flex justify-content-between">
                                    <div className="flex-grow-1">{teamDisplay}</div>
                                    {
                                        item.meetingCompleted && <div className="mx-2 completed">Completed</div>
                                    }
                                    { duration && scheduledDateTime != null  ? <div>{duration}m</div> : "" }
                                </div>
                            </li>
                            {
                                scheduledDateTime == null &&
                                [
                                    <li key={`${id}-listOfPatients`}>Patient: {listOfPatients[0].value.patientNameDisplay}</li>,
                                    <li key={`${id}-isUrgent`}><div className="d-flex align-items-center">Urgent: {isUrgent ? <CheckSquareOutlined /> : <BorderOutlined /> }</div></li>,
                                    <li key={`${id}-createdOnDisplay`}>Date Requested: {createdOnDisplay}</li>
                                ]
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </List.Item>
    }

    useEffect(()=>{
        //just for force refetch on component load each time
        //fix for Bug 1428: New Consult Request Not Displaying Unless Page is Refreshed
        if(!isUninitializedUConsults)reFetchUConsults();
        if(!isUninitializedConsults)reFetchConsults();
    },[]);

    const onCompletedSwitchChange = (checked) => {
        setCanShowCompletedConsult(checked);
        apACs.setSelectedAppointment({id:0});
    };

    const pcList = IsArrayWithLength(patientConsults) ? (canShowCompletedConsult ? patientConsults : patientConsults.filter(i=> !i.meetingCompleted)):[];

    return (
        <div className="lists-wrapper d-flex flex-column">
            {
                IsArrayWithLength(uPatientConsults) &&
                <Collapse ghost collapsible="header" defaultActiveKey="1">
                    <Panel header={`Clinical Consult Requests (${uPatientConsults.length})`} key="1">
                        <List className='list-wrapper un-schedule d-flex flex-column'>
                            <VirtualList
                                data={uPatientConsults}
                                itemHeight={47}
                                itemKey="id"
                                className="p-0">
                                {(item) => RenderAppointment(item)}
                            </VirtualList>
                        </List>
                    </Panel>
                </Collapse>
            }
            <div className="d-flex flex-column justify-content-start m-2">
                <h5 className="align-self-start">{month}</h5>
                <div className="d-flex align-items-center">
                    <DatePicker className="align-self-start"
                                picker="week"
                                value={week.dateTime}
                                format={customFormat}
                                onChange={handleDateChange} />
                    <div className="mx-2">
                        Completed Consults
                    </div>
                    <Switch onChange={onCompletedSwitchChange}  />
                </div>
            </div>
            {
                IsArrayWithLength(patientConsults) &&
                <List className='list-wrapper d-flex flex-column'>
                    <VirtualList
                        data={pcList}
                        itemHeight={47}
                        itemKey="id"
                        className="p-2">
                        {(item) => RenderAppointment(item)}
                    </VirtualList>
                </List>
            }
        </div>
    )
}

export default AppointmentList;
