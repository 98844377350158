import React, {useCallback, useEffect, useState} from 'react';
import {isNotNullOrUndefined} from "../../utilities/extensions";
import {DatePicker, Radio} from "antd";


const DateRange = ({data}) => {

    const {title, key, items, onChange,  defaultState={}} = data;
    const [localState, setLocalState] = useState(defaultState[key] || {type:0});

    const onChoiceGroupSelectionChange = useCallback(({target:{value}}) => {
        setLocalState(prev=>({...prev, type: value}));
    }, [key]);


    const onSelectDate = useCallback((date, dateString, pikerKey) => {
        setLocalState(prev => ({...prev, [pikerKey]:date}));
    }, []);


    useEffect(() => {
        onChange({[key]:{...localState}});
    }, [localState]);

    useEffect(() => {
        const  type = defaultState[key] || {type:0};
        setLocalState(prev=> ({...prev, ...type}));
    }, [defaultState]);

    return (
        <>
            <Radio.Group onChange={onChoiceGroupSelectionChange} value={localState.type || 0}>
                {
                    items.map(o => <Radio key={o.key} value={o.value}>{o.title}</Radio>)
                }
            </Radio.Group>
            {
                Array.isArray(items.find(i => i.value === localState.type)?.items) &&
                <div className="mt-3 d-flex flex-column">
                    {
                        items.find(i=>i.value === localState.type)?.items.map(el=> <DatePicker
                            key={el.key}
                            className="mb-3"
                            placeholder={el.placeholder}
                            ariaLabel={el.title}
                            label={el.title}
                            value={ isNotNullOrUndefined(localState) ? localState[el.key] || null : null}
                            onChange={(d, ds)=>onSelectDate(d, ds, el.key)}/>
                        )
                    }
                </div>
            }
        </>
    );
};

export default DateRange;

