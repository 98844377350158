import React, {useEffect, useCallback, useState, useMemo} from 'react';
import { Link } from "react-router-dom";
import { useNotificationsFailuresMutation, useNotificationsReportsMutation } from "../../features/notification/notificationsService";
import NotificationsSchema, {
    defaultFailuresFilter,
    defaultReportsFilter, notificationsFilterRemoveTag, notificationsFilterTags,
    notificationsSearchFields
} from "../../components/Schema/notificationsSchema";
import {Breadcrumb, Button, Tag, Tabs} from "antd";
import {IoChevronForwardOutline, IoFilterOutline, IoHomeSharp} from "react-icons/all";
import Search from "antd/es/input/Search";
import FilterPanel from "../../components/FilterPanel/FilterPanel";
import FailuresTable from "./FailuresTable";
import ReportsTable from "./ReportsTable";
import {debounce} from "lodash";
import {
    selectNotificationsFailuresFilter,
    selectNotificationsReportsFilter, setFailuresFilter, setReportsFilter
} from "../../features/notification/notificationsSlice";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {IsArrayWithLength} from "../../utilities/extensions";
import {bindActionCreators} from "@reduxjs/toolkit";

const { TabPane } = Tabs;
export const NOTIFICATION_REPORTS = "reports";
export const NOTIFICATION_FAILURES = "failures";



const Notifications = () => {
    // const { data, isSuccess, isFetching, error, isError, isLoading } = useGetNotificationsQuery();
    const dispatch = useDispatch();
    const reportsFilter = useSelector(selectNotificationsReportsFilter, shallowEqual);
    const failuresFilter = useSelector(selectNotificationsFailuresFilter, shallowEqual);
    const [notificationsReports] = useNotificationsReportsMutation();
    const [notificationsFailures] = useNotificationsFailuresMutation();

    const nACS = useMemo( () => bindActionCreators({ setReportsFilter, setFailuresFilter},dispatch),
        [dispatch]
    );

    const [activeTabKey, setActiveTabKey] = useState(NOTIFICATION_REPORTS);

    const [openReportsDrawer, setOpenReportsDrawer] = useState(false);

    const [openFailuresDrawer, setOpenFailuresDrawer] = useState(false);

    const searchEventHandler = (e) => {
        const searchKey = e.target.value;
        const input = typeof searchKey === "string" ? searchKey.trim() : searchKey || "";
        if(activeTabKey === NOTIFICATION_REPORTS){
            notificationsReports({...reportsFilter, advancedSearch: { fields: notificationsSearchFields, keyword: input}})
        }else{
            notificationsFailures({...failuresFilter, advancedSearch: { fields: notificationsSearchFields, keyword: input}})
        }
    };
    const debouncedSearchHandler = useCallback(debounce(searchEventHandler, 300) , [activeTabKey, reportsFilter, failuresFilter]);
    useEffect(() => { return () => { return debouncedSearchHandler?.cancel()} }, [activeTabKey, reportsFilter, failuresFilter]);

    const updateFilterState = (isReset, fd) => {
        if(activeTabKey === NOTIFICATION_REPORTS){
            setOpenReportsDrawer(false);
            nACS.setReportsFilter(isReset ? {...defaultReportsFilter} : {...fd});
            notificationsReports(isReset ? {...defaultReportsFilter} : {...defaultReportsFilter, ...fd});
        }else{
            setOpenFailuresDrawer(false);
            nACS.setFailuresFilter(isReset ? {...defaultFailuresFilter} : {...fd});
            notificationsFailures(isReset ? {...defaultFailuresFilter} : {...defaultFailuresFilter, ...fd});
        }
    }

    const onFilterApply = useCallback((fd)=>{
        updateFilterState(false, fd);
    },[activeTabKey,openReportsDrawer, reportsFilter]);

    const onFilterClear = useCallback(()=>{
        setOpenReportsDrawer(false);
        // updateFilterState(true);
    // },[activeTabKey,openFailuresDrawer, failuresFilter]);
        },[]);

    const onOpenFilterDrawer = useCallback(()=>{
        if(activeTabKey === NOTIFICATION_REPORTS){
            nACS.setReportsFilter({...reportsFilter});
            setOpenReportsDrawer(true);
        }else{
            nACS.setFailuresFilter({...failuresFilter});
            setOpenFailuresDrawer(true);
        }
    },[activeTabKey, reportsFilter, failuresFilter]);

    const onTabChange = useCallback((activeKey) => {
        setActiveTabKey(activeKey);
    },[]);

    const onTagClose = useCallback((e,t)=>{
       const inFilter = activeTabKey === NOTIFICATION_REPORTS ? reportsFilter : failuresFilter;
       const filter =  notificationsFilterRemoveTag(t,{...inFilter});
       const fullFilter = {...inFilter,...filter};
       updateFilterState(false, fullFilter);
    },[activeTabKey, reportsFilter, failuresFilter])

    const filterTags = useMemo(()=> {
        return  notificationsFilterTags(activeTabKey === NOTIFICATION_REPORTS ? reportsFilter : failuresFilter);
    },[activeTabKey, reportsFilter, failuresFilter]);

    return (
        <div className="d-flex flex-column">
            <Breadcrumb separator={<IoChevronForwardOutline />}>
                <Breadcrumb.Item key="root">
                    <Link to="/" >
                        <IoHomeSharp />
                    </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item key="/account-team">
                    <Link to="/account-team">
                        Account Team
                    </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Notifications</Breadcrumb.Item>
            </Breadcrumb>
            <section className="pt-2 flex-column">
                <div className="d-flex justify-content-between pt-2 pb-2">
                   <div className="d-flex">
                       <Search placeholder="Search"
                               className="notification-search"
                               onChange={debouncedSearchHandler}  />

                      <div className="d-flex ps-2">
                          {
                              IsArrayWithLength(filterTags) &&
                              filterTags.map(t=>{
                                  return <Tag key={t.key}
                                              onClose={e=>onTagClose(e,t)}
                                              closable >
                                      { t.title }
                                  </Tag>
                              })
                          }
                      </div>
                   </div>
                    <Button icon={<IoFilterOutline />}
                            text="Filter"
                            onClick={onOpenFilterDrawer}/>
                </div>
                <Tabs onChange={onTabChange}
                      defaultActiveKey={NOTIFICATION_REPORTS}
                      items={[
                          { label: "Reporting", key: NOTIFICATION_REPORTS, children: <ReportsTable />},
                          { label: "Failures", key: NOTIFICATION_FAILURES, children: <FailuresTable />}
                        ]}/>
            </section>
            <FilterPanel openDrawer={openReportsDrawer}
                         className="p-0 panel-content"
                         schema={NotificationsSchema}
                         filterData={reportsFilter}
                         onApply={onFilterApply}
                         onClear={onFilterClear}
                         onCloseDrawer={()=> setOpenReportsDrawer(false)} />
            <FilterPanel openDrawer={openFailuresDrawer}
                         className="p-0 panel-content"
                         schema={NotificationsSchema}
                         filterData={failuresFilter}
                         onApply={onFilterApply}
                         onClear={onFilterClear}
                         onCloseDrawer={()=> setOpenFailuresDrawer(false)} />
        </div>
    );
};

export default Notifications;
