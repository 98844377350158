import {HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import {AdminRoles, GLOBAL_CONFIG} from "../../global/constants";
import LocalTokenService from "../../services/localTokenService";
import {setSessions, setConnected, setDisConnected} from "../session/sessionSlice";
import {JsonTryParse} from "../../utilities/uiExtensions";
import {IsArrayWithLength, UserHasRoles, tokenRoles} from "../../utilities/extensions";
export const BaseSocketUrl = GLOBAL_CONFIG.BASE_SOCKET_URL;

const DeviceMessage = "DeviceMessage";

const ConnMetaToSession = (cm) =>{
    const {deviceInfo} = cm;
    return {
        userIdentifier: cm.userIdentifier,
        userEmail: cm.userEmail,
        firstName: cm.firstName,
        lastName: cm.lastName,
        deviceId: deviceInfo.deviceId,
        deviceName: deviceInfo.name
    }
};

const ConnToSession = (c,cm) =>{
    const {deviceInfo} = cm;
    return {
        userIdentifier:c.userIdentifier,
        userEmail:c.userEmail,
        firstName:c.firstName,
        lastName:c.lastName,
        deviceId:deviceInfo.deviceId,
        deviceName:deviceInfo.name
    }
}

const onDeviceMessageReceived = (res, getState, dispatch) => {
    const {messageType, deviceType, source} = res;
    switch (messageType){
        case 2:
            const pageRes = {
                data: [],
                pageNumber: 1,
                totalPages: 1,
                totalCount: 0,
                pageSize: 10,
                hasPreviousPage: false,
                hasNextPage: false
            };
            const cons = JsonTryParse(source, null);
            if(IsArrayWithLength(cons)){
                cons.forEach(c=>{
                    c.connections.forEach(cm=>{
                        const sData = ConnToSession(c,cm);
                        pageRes.data.push(sData);
                    })
                })
            }
            dispatch(setSessions(pageRes));
            break;
        case 3:
            const cmData = JsonTryParse(source, null);
            if(cmData != null){
                const sData = ConnMetaToSession(cmData);
                dispatch(setConnected(sData));
            }
            break;
        case 4:
            const dmData = JsonTryParse(source, null);
            if(dmData != null){
                const sData = ConnMetaToSession(dmData);
                dispatch(setDisConnected(sData));
            }
            break;
    }
};

const startSocketConnection = connection => connection.start()
    .then(() => console.info('SignalR Connected'))
    .catch(err => console.error('SignalR Connection Error: ', err));

let connection;

//https://learn.microsoft.com/en-us/aspnet/core/signalr/configuration?view=aspnetcore-6.0&tabs=javascript
const signalRMiddleware = ({ getState, dispatch }) => next => async (action) => {
    const {type, payload:{ token } = {}} = action;
    const deviceId = LocalTokenService.getDeviceId();
    if (type === "auth/setCredentials" && UserHasRoles(AdminRoles, token)){

        const connectionUrl = `${BaseSocketUrl}/notifications?tenant=root&DeviceType=2&DeviceId=${deviceId}`;

        connection = new HubConnectionBuilder()
            .withUrl(connectionUrl, {
                logMessageContent: true,
                // logger: LogLevel.Trace,
                // Sending headers in the browser doesn't work for WebSockets or the ServerSentEvents stream.
                // so used query string
                // headers: { "tenant": "root" },
                accessTokenFactory: () => token
            })
            .withAutomaticReconnect()
            .build();

        connection.on(DeviceMessage,(msg) => onDeviceMessageReceived(msg, getState, dispatch));

        // ReEstablish the connection if connection dropped
        // connection.onclose(() => setTimeout(startSocketConnection(connection), 5000));
        startSocketConnection(connection);
    }
    if(type === "auth/logOut"){
        setTimeout(() => connection?.stop(), 500);
    }
    return next(action);
};

export default signalRMiddleware;
