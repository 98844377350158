import { Tooltip} from "antd";
import {formatDateTo} from "../../utilities/extensions";

function _copyAndSort(items, columnKey, isSortedDescending) {
    const key = columnKey;
    return items.slice(0).sort((a, b) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}

/*
const onColumnClick = (ev, column) => {
    const { columns, items } = this.state;
    const newColumns = columns.slice();
    const currColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol) => {
        if (newCol === currColumn) {
            currColumn.isSortedDescending = !currColumn.isSortedDescending;
            currColumn.isSorted = true;
            this.setState({
                announcedMessage: `${currColumn.name} is sorted ${
                    currColumn.isSortedDescending ? 'descending' : 'ascending'
                }`,
            });
        } else {
            newCol.isSorted = false;
            newCol.isSortedDescending = true;
        }
    });
    const newItems = _copyAndSort(items, currColumn.fieldName,  currColumn.isSortedDescending);
    this.setState({
        columns: newColumns,
        items: newItems,
    });
};*/

export const columns = [
    {
        key: 'channel',
        title: 'Channel',
        ariaLabel: 'Channel',
        backField: 'NotificationType',
        dataIndex: 'notificationTypeDisplay',
        fixed: 'left',
        width: 150,
    },
    {
        key: 'destinationAccount',
        title: 'Destination Account',
        dataIndex: 'destinationAccount',
        backField: 'destinationAccount',
        ariaLabel: 'Destination Account',
        sorter: true,
        ellipsis: {
            showTitle: false,
        },
        render: value => (
            <Tooltip placement="topLeft" title={value}>
                {value}
            </Tooltip>
        ),
    },
    {
        key: 'createdOn',
        title: 'Date/Time (EST)',
        dataIndex: 'createdOn',
        backField: 'createdOn',
        ariaLabel: 'Date/Time (EST)',
        width: 150,
        sorter: true,
        ellipsis: {
            showTitle: false,
        },
        render: createdOn => {
          const formattedDate =  formatDateTo(createdOn, "MM/dd/yyyy h:mm a");
          return  <Tooltip placement="topLeft" title={formattedDate}>
                {formattedDate}
            </Tooltip>
        }
    },
    {
        key: 'patientId',
        title: 'Patient ID',
        dataIndex: 'patientId',
        ariaLabel: 'Patient ID',
        width: 180,
        ellipsis: {
            showTitle: false,
        },
        render: value => (
            <Tooltip placement="topLeft" title={value}>
                {value}
            </Tooltip>
        ),
    },
    {
        key: 'teamId',
        title: 'Team ID',
        dataIndex: 'teamId',
        backField: 'JobDetail.Patient.TeamId',
        ariaLabel: 'Team ID',
        width: 150,
        sorter: true,
        ellipsis: {
            showTitle: false,
        },
        render: value => (
            <Tooltip placement="topLeft" title={value}>
                {value}
            </Tooltip>
        ),
    },
    {
        key: 'messageTypeDisplay',
        title: 'Message Type',
        dataIndex: 'messageTypeDisplay',
        ariaLabel: 'Message Type',
        ellipsis:false,
        width:180,
        render: value => (
            <Tooltip placement="topLeft" title={value}>
                {value}
            </Tooltip>
        ),
    },
    {
        key: 'notificationStatusDisplay',
        title: 'Status',
        dataIndex: 'notificationStatusDisplay',
        ariaLabel: 'Status',
        width: 150,
        ellipsis: {
            showTitle: false,
        },
        render: value => (
            <Tooltip placement="topLeft" title={value}>
                {value}
            </Tooltip>
        ),
    }
];

export const skeletonColumns = [{key:'ex', title:'', width:50, fixed:'left'},...columns];
