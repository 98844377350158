import React, {useEffect}  from 'react';
import {columns, skeletonColumns} from "./NotificationsData";
import {isNotNullOrUndefined} from "../../utilities/extensions";
import {shallowEqual, useSelector} from "react-redux";
import { selectNotificationsReports, selectNotificationsReportsFilter, selectNotificationsReportsState } from "../../features/notification/notificationsSlice";
import { Table } from "antd";
import {IoChevronDown, IoChevronForwardSharp} from "react-icons/all";
import {defaultReportsFilter} from "../../components/Schema/notificationsSchema";
import {useNotificationsReportsMutation} from "../../features/notification/notificationsService";
import SkeletonTable from "../../components/Layout/Utilities/SkeletonTable.tsx";



const ReportsTable = () => {

    const {data, pageNumber, totalPages, totalCount, pageSize, hasPreviousPage, hasNextPage, keyword} = useSelector(selectNotificationsReports, shallowEqual);

    const filter = useSelector(selectNotificationsReportsFilter, shallowEqual);
    const {isLoading} = useSelector(selectNotificationsReportsState);


    const [notificationsReports] = useNotificationsReportsMutation();

    useEffect(()=>{
        notificationsReports({...defaultReportsFilter});
    },[]);

    const onPageChange = (page, pageSize) => {
        notificationsReports( {...filter, pageNumber:page,pageSize:pageSize});
    }

    const onShowSizeChange = (current, size) =>{
        notificationsReports({...filter, pageNumber:0, pageSize:size});
    }

    const onChange = (pagination, filters, {column, columnKey, field, order}, {action}) =>{
        if(isNotNullOrUndefined(column) && action === "sort"){
            notificationsReports({...filter, orderBy:[`${column.backField} ${order}`]});
        }
    };

    return (
        <div className="d-flex flex-column">
            <SkeletonTable loading={isLoading} columns={skeletonColumns} active={isLoading}>
                <Table rowKey="id"
                       tableLayout="fixed"
                       className="table-ctrl"
                       columns={columns}
                       onChange={onChange}
                       dataSource={data} scroll={{ x: 900 }}
                       pagination={{
                           position: ['bottomRight'],
                           showSizeChanger:true,
                           current: pageNumber,
                           total: totalCount,
                           pageSize: pageSize,
                           onChange:onPageChange,
                           onShowSizeChange:onShowSizeChange
                       }}
                       expandable={{
                           rowExpandable: record => isNotNullOrUndefined(record.notificationContent),
                           expandedRowRender: record =>  <div className="p-2 d-flex flex-column">
                               <div className="h6">Full Notification Content</div>
                               <div className="p-2 border html-details" dangerouslySetInnerHTML={{ __html: record.notificationContent }}/>
                           </div>,
                           expandIcon: ({ expanded, onExpand, record }) =>
                               expanded ? <IoChevronDown onClick={e => onExpand(record, e)} /> : <IoChevronForwardSharp onClick={e => onExpand(record, e)} />
                       }}/>
            </SkeletonTable>
        </div>
    );
}

export default ReportsTable;
