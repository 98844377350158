import React, {useEffect, useState} from 'react';
import {Breadcrumb, Button, Switch, Input, Modal, Skeleton} from "antd";
import {IoChevronForwardOutline, IoHomeSharp} from "react-icons/all";
import {Link} from "react-router-dom";
import {useGetCommunicationPreferencesQuery, useUpdateCommunicationPreferencesMutation} from "../../features/appointment/appointmentService";
import {IsArrayWithLength, IsNotEmpty} from "../../utilities/extensions";
import {
    ChannelTypeDisplay, CommunicationChannelType,
    ComputeCommunicationPreferences,
    ResetCommunicationPreference, ToCommunicationPreferenceRequest, ToCommunicationPreferenceResponseWithRemoteFields
} from "../../models/CommunicationPreference";
import {openNotificationWithIcon, SingleError} from "../../utilities/uiExtensions";
import {CheckCircleOutlined, CheckOutlined} from "@ant-design/icons";


const UserNotifications = () => {
    const [commPres, setCommPres] = useState([]);
    const [isBusy, setIsBusy] = useState(false);

    const {data , isLoading} = useGetCommunicationPreferencesQuery();
    const [updateCommunicationPreferences] = useUpdateCommunicationPreferencesMutation();

    const onSwitchChange = async (cc, checked) => {
       const cp = commPres.find(i=> cc.channelType === i.channelType);
       cp.channelEnabled = checked;
       setCommPres([...commPres]);
       await UpsertCommunicationPreferenceAsync(cp, false, true);
    };

    const onChannelDetailsChanged = (value, cpSource)=>{
        const cp = commPres.find(i=> cpSource.channelType === i.channelType);
        cp.channelDetails = value;
        setCommPres([...commPres]);
    };

    const onEdit = (e, cpSource) => {
        const cp = commPres.find(i=> cpSource.channelType === i.channelType);
        cp.isEditMode = true;
        setCommPres([...commPres]);
    };

    const onCancel = (e,cpSource)=>{
        const cp = commPres.find(i=> cpSource.channelType === i.channelType);
        ResetCommunicationPreference(cp);
        setCommPres([...commPres]);
    };

    const onResendVerification = async (e,cpSource) =>  await UpsertCommunicationPreferenceAsync(cpSource, true);

    const UpsertCommunicationPreferenceAsync = async (cpSource, resendVerification = false, onlyChannelEnabled = false) =>{
        if(cpSource == null || resendVerification && !IsNotEmpty(cpSource.channelDetails)) return;
        const cpr = ToCommunicationPreferenceRequest(cpSource);
        setIsBusy(true);
        try {
            const response =  await updateCommunicationPreferences(cpr).unwrap();
            const ccp = commPres.find(i => i.channelType === response.channelType);
            if (ccp !== undefined){
                ToCommunicationPreferenceResponseWithRemoteFields(response,ccp);
                ccp.isEditMode = !IsNotEmpty(ccp.remoteChannelDetails); //|| false;
                setCommPres([...commPres]);
                if (!onlyChannelEnabled){
                    let verificationMsgBody = "Communication Preference has been saved successfully.";
                    if (resendVerification)
                    {
                        verificationMsgBody = ccp.channelType === CommunicationChannelType.Email ?
                            `An email confirmation link was just sent to ${ccp.channelDetails}. Please click on the link within 24 hours to verify your email` :
                            `An SMS was just sent to ${ccp.channelDetails}. Please reply as 'YES' to verify your phone number`;
                    }
                    Modal.info({ title:"Communication Preference", content:verificationMsgBody, onOk() {}});
                }
            }
        }catch (err){
            openNotificationWithIcon('error', 'Error', SingleError(err));
        }
        setIsBusy(false);
    }

    useEffect(()=>{
        setIsBusy(isLoading);
        IsArrayWithLength(data) ? setCommPres(ComputeCommunicationPreferences(data)) : setCommPres([]);
    },[data, isLoading])

    return (
        <div className="d-flex flex-column">
            <section className="d-flex justify-content-between mb-3 bg-white">
                <Breadcrumb separator={<IoChevronForwardOutline />}>
                    <Breadcrumb.Item key="root">
                        <Link to="/" >
                            <IoHomeSharp />
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item key="clinical-team">
                        <Link to="/settings">
                            Settings
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Notifications</Breadcrumb.Item>
                </Breadcrumb>
            </section>
            <section className="d-flex flex-column pt-2">
                {
                    isLoading && <Skeleton className="m-3" />
                }
                {
                    !isLoading && IsArrayWithLength(commPres) &&
                    <div className="ant-collapse ant-collapse-icon-position-start w-100">
                        {
                           commPres.map(c => {
                                const ctStr = ChannelTypeDisplay(c.channelType);
                                const placeholder = `Enter ${ctStr}`;
                                const canShowResendVerification = !c.isEditMode && c.verified;
                                const canShowVerifiedIcon = !c.isEditMode && c.verified;
                                const verifiedIcon = canShowVerifiedIcon ? <CheckCircleOutlined className="success-color" /> : <span />;
                                return <div key={c.channelType} className="ant-collapse-item ant-collapse-no-arrow">
                                    <div className="ant-collapse-header">
                                        <span className="ant-collapse-header-text">{ctStr} Notification</span>
                                        <div className="ant-collapse-extra">
                                            <Switch disabled={isBusy} defaultChecked={c.channelEnabled} onChange={state=>onSwitchChange(c,state)}  />
                                        </div>
                                    </div>
                                    {
                                        c.channelEnabled &&
                                        <div className="ant-collapse-content ant-collapse-content-active">
                                            <div className="ant-collapse-content-box">
                                                <div className="d-flex flex-column">
                                                    <div className="d-flex">
                                                        <Input placeholder={placeholder}
                                                               defaultValue={c.channelDetails}
                                                               value={c.channelDetails}
                                                               suffix={verifiedIcon}
                                                               disabled={!c.isEditMode}
                                                               onChange={e=>onChannelDetailsChanged(e.target.value, c)}/>
                                                        {
                                                            IsNotEmpty(c.remoteChannelDetails) &&
                                                            <Button type="primary" className="m-1" onClick={e=>onEdit(e,c)}>Edit</Button>
                                                        }
                                                    </div>
                                                    <div className="d-flex mt-2 align-items-center">
                                                        {
                                                            c.optIn &&
                                                            <div className="d-flex m-2 align-items-center">
                                                                <CheckOutlined className="success-color" />
                                                                <span className="ms-2">User Opt-in</span>
                                                            </div>
                                                        }
                                                        {
                                                            canShowResendVerification &&
                                                            <Button type="primary" onClick={e=>onResendVerification(e,c)}>Resend Verification</Button>
                                                        }
                                                    </div>
                                                    {
                                                        c.isEditMode &&
                                                        <div className="d-flex mt-2 align-items-center">
                                                            <Button loading={isBusy} type="primary" onClick={e=>UpsertCommunicationPreferenceAsync(c)} >Save</Button>
                                                            <Button disabled={isBusy} type="primary" className="ms-2" onClick={e=>onCancel(e,c)} >Cancel</Button>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            })
                        }
                    </div>
                }
            </section>
        </div>
    );
};

export default UserNotifications;
