import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useDataFailuresMutation } from '../../features/failure/failuresService';
import FailuresSchema, {
   defaultFailuresFilter,
   failuresFilterRemoveTag,
   failuresFilterTags,
   failuresSearchFields,
} from '../../components/Schema/failuresSchema';
import { Breadcrumb, Button, Tag } from 'antd';
import {
   IoChevronForwardOutline,
   IoFilterOutline,
   IoHomeSharp,
} from 'react-icons/all';
import Search from 'antd/es/input/Search';
import FilterPanel from '../../components/FilterPanel/FilterPanel';
import FailuresTable from './FailuresTable';
import { debounce } from 'lodash';
import {
   selectFailuresFilter,
   setFailuresFilter,
} from '../../features/failure/failuresSlice';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { IsArrayWithLength } from '../../utilities/extensions';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useStore } from 'react-redux'

const Failures = () => {
   const dispatch = useDispatch();
   const failuresFilter = useSelector(selectFailuresFilter, shallowEqual);
   const [dataFailures] = useDataFailuresMutation();
   const store = useSelector((state)=>state);
   const nACS = useMemo(
      () => bindActionCreators({ setFailuresFilter }, dispatch),
      [dispatch],
   );

   
   const [openFailuresDrawer, setOpenFailuresDrawer] = useState(false);

   const searchEventHandler = (e) => {
      
      const searchKey = e.target.value;
      const input =
         typeof searchKey === 'string' ? searchKey.trim() : searchKey || '';
              
      dataFailures({
         ...failuresFilter,
         advancedSearch: {
            fields: failuresSearchFields,
            keyword: input,
         },
      });
   };

   const debouncedSearchHandler = useCallback(
      debounce(searchEventHandler, 300),
      [failuresFilter],
   );

   useEffect(() => {
      return () => {
         return debouncedSearchHandler?.cancel();
      };
   }, [failuresFilter]);


   useEffect(()=>{
      if(store.persistedReducer.counter.failures.filter.services != undefined || store.persistedReducer.counter.failures.filter.eventTypes != undefined || store.persistedReducer.counter.failures.filter.dateRange.type !=0){
         if(store.persistedReducer.counter.failures.filter.services.length > 0 || store.persistedReducer.counter.failures.filter.eventTypes > 0 || Object.keys(store.persistedReducer.counter.failures.filter.dateRange) !=[]){
            nACS.setFailuresFilter(store.persistedReducer.counter.failures.filter)
               dataFailures({...defaultFailuresFilter,...store.persistedReducer.counter.failures.filter});
         }
         else{
            dataFailures({ ...defaultFailuresFilter});
         }
      }
      else{
         dataFailures({ ...defaultFailuresFilter});
      }
   },[])


   const updateFilterState = (isReset, fd) => {
      setOpenFailuresDrawer(false);
      nACS.setFailuresFilter(
         isReset ? { ...defaultFailuresFilter } : { ...fd },
      );
      dataFailures(
         isReset
            ? { ...defaultFailuresFilter }
            : { ...defaultFailuresFilter, ...fd },
      );
   };

   const onFilterApply = useCallback((fd) => {
      updateFilterState(false, fd);
   }, []);

   const onFilterClear = useCallback(() => {
      setOpenFailuresDrawer(false);
      // updateFilterState(false);
   }, []);

   const onOpenFilterDrawer = useCallback(() => {
      nACS.setFailuresFilter({ ...failuresFilter });
      setOpenFailuresDrawer(true);
   }, [failuresFilter]);

   const onTagClose = useCallback(
      (e, t) => {
         const inFilter = failuresFilter;
         const filter = failuresFilterRemoveTag(t, { ...inFilter });
         const fullFilter = { ...inFilter, ...filter };
         updateFilterState(false, fullFilter);
      },
      [failuresFilter],
   );

   const filterTags = useMemo(() => {
      return failuresFilterTags(failuresFilter);
   }, [failuresFilter]);

   return (
      <div className="d-flex flex-column">
         <Breadcrumb separator={<IoChevronForwardOutline />}>
            <Breadcrumb.Item key="root">
               <Link to="/">
                  <IoHomeSharp />
               </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item key="/account-team">
               <Link to="/account-team">Account Team</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Data Integration Failures</Breadcrumb.Item>
         </Breadcrumb>

         <section className="pt-2 flex-column">
            <div className="d-flex justify-content-between pt-2 pb-2">
               <div className="d-flex">
                  <Search
                     placeholder="Search"
                     className="failure-search"
                     onChange={debouncedSearchHandler}
                  />

                  <div className="d-flex ps-2">
                     {IsArrayWithLength(filterTags) &&
                        filterTags.map((t) => {
                           return (
                              <Tag
                                 key={t.key}
                                 onClose={(e) => onTagClose(e, t)}
                                 closable
                              >
                                 {t.title}
                              </Tag>
                           );
                        })}
                  </div>
               </div>
               <Button
                  icon={<IoFilterOutline />}
                  text="Filter"
                  onClick={onOpenFilterDrawer}
               />
            </div>

            <FailuresTable />
         </section>

         <FilterPanel
            openDrawer={openFailuresDrawer}
            className="p-0 panel-content"
            schema={FailuresSchema}
            filterData={failuresFilter}
            onApply={onFilterApply}
            onClear={onFilterClear}
            onCloseDrawer={() => setOpenFailuresDrawer(false)}
            name ="faiures"
         />
      </div>
   );
};

export default Failures;
