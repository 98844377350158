import { baseApiService } from '../baseApiService';
import {
   PatientSearchResponse,
   PatientSearchRequest,
   PatientDetailsResponse,
   PatientScriptSureCookieResponse,
   PatientScriptSureCookieRequest,
   PatientDischargeRequest,
   PatientDischargeResponse,
} from '../../models/PatientModels';

export const patientAPI = baseApiService.injectEndpoints({
   endpoints: (builder) => ({
      patientSearchResult: builder.mutation<
         PatientSearchResponse,
         PatientSearchRequest
      >({
         query: (req) => {
            return {
               url: '/v1/patient/search',
               method: 'POST',
               body: req,
            };
         },
      }),
      patientDetails: builder.mutation<PatientDetailsResponse, any>({
         query: (_id) => ({
            url: `/v1/patient/${_id}`,
            method: 'GET',
         }),
      }),
      postPatientRecord: builder.mutation<
         PatientDetailsResponse,
         PatientDetailsResponse
      >({
         query: (req) => {
            return {
               url: '/v1/patient',
               method: 'POST',
               body: req,
            };
         },
      }),
      dischargePatient: builder.mutation<
         PatientDischargeResponse,
         { id: string; req: PatientDischargeRequest }
      >({
         query: ({ id, req }) => {
            return {
               url: `/v1/patient/${id}/discharge`,
               method: 'PUT',
               body: req,
            };
         },
      }),
      getDropDownOptions: builder.mutation<any, any>({
         query: () => {
            return {
               url: `/v1/patient/dropdown-options`,
               method: 'GET',
            };
         },
      }),
      getPractiontionerOptions: builder.mutation<any, any>({
         query: (req) => {
            return {
               url: `/v1/team/${req}/prescribers`,
               method: 'GET',
            };
         },
      }),
      fetchTeamOptions: builder.mutation<any, any>({
         query: (req) => {
            return {
               url: `/v1/Team`,
               method: 'GET',
            };
         },
      }),
      fetchTeamOptionsTableData: builder.mutation<any, any>({
         query: (req) => {
            return {
               url: `/v1/team/patients?teamId=${req}`,
               method: 'GET',
            };
         },
      }),
      postPatientScriptSureCookie: builder.mutation<
         PatientScriptSureCookieResponse,
         PatientScriptSureCookieRequest
      >({
         query: (req) => {
            return {
               url: '/personal/script-sure-cookie',
               method: 'POST',
               body: req,
            };
         },
      }),
      postPatientAllergyBypassUpload: builder.mutation<any, any>({
         query: (req) => {
            return {
               url: '/v1/bypass/upload',
               method: 'POST',
               body: req,
            };
         },
      }),
      postPatientGetHelp: builder.mutation<any, any>({
         query: (req) => {
            return {
               url: '/v1/logging/scriptsureiframe',
               method: 'POST',
               body: req,
            };
         },
      }),
   }),
});

export const {
   usePatientSearchResultMutation,
   usePatientDetailsMutation,
   usePostPatientRecordMutation,
   useDischargePatientMutation,
   useGetDropDownOptionsMutation,
   useGetPractiontionerOptionsMutation,
   useFetchTeamOptionsMutation,
   useFetchTeamOptionsTableDataMutation,
   usePostPatientScriptSureCookieMutation,
   usePostPatientAllergyBypassUploadMutation,
   usePostPatientGetHelpMutation,
} = patientAPI;
