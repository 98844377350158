import React, {useEffect, useState} from "react";
import * as ets from "../../utilities/extensions";
import {isObjectAndNotNull} from "../../utilities/extensions";
import {Collapse, Input} from "antd";

//default: {{key:value}, {key:value}};
const TextBoxList = ({data}) => {
    const {title, key, items, onChange, defaultState={}} = data;
    const [expanded, setExpanded] = useState(key);
    const [values, setValues] = useState(key in defaultState ? defaultState[key] : {});

    const handleAccordionChange = (accordingId) => {
        //setExpanded(isExpanded ? accordingId : "0");
    };

    const onTextFieldChange = (e,newValue)=>{
        setValues(prev=>({...prev, ...newValue}))
    }

    useEffect(() => { ets.isFunction(onChange) && onChange({[key]: values}); }, [values]);

    useEffect(() => {
        if(key in defaultState){
            const comDefaultState = defaultState[key];
            const keys = items.map(i=>i.key);
            if(Array.isArray(keys)){
                const dateValueWithKeys = keys.reduce((pv, k) => ({...pv, [k]: comDefaultState[k]}),{});
                Object.keys(dateValueWithKeys).forEach((k) => dateValueWithKeys[k] == null && delete dateValueWithKeys[k]);
                setValues(prev => ({...prev,...dateValueWithKeys}));
            }
        }
    }, [defaultState, items]);

    return (
        <Collapse isExpaned={expanded === key}
                  accordianId={key}
                  onChange={handleAccordionChange}>
            <Collapse header={title}>
                {
                    items.map(i=> <div key={i.key}>
                        <Input key={i.key}
                                   label={i.title}
                                   placeholder={i.placeholder}
                                   value={isObjectAndNotNull(values) ? values[i.key] || "": ""}
                                   onChange={(e,v)=>onTextFieldChange(e,{[i.key]:v})}/>
                    </div> )
                }
            </Collapse>
        </Collapse>

    );
};


export default TextBoxList;
