import React, { useState, useCallback} from 'react';
import {Button, Drawer, Breadcrumb} from 'antd';
import AppointmentDetails from './AppointmentDetails';
import CreateMeetingForm from './CreateMeetingForm';
import AppointmentList from "./AppointmentList";
import {IoChevronForwardOutline, IoHomeSharp} from "react-icons/all";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectSelectedItem} from "../../features/appointment/appointmentSlice";

const AppointmentMeeting = () => {

    const [openDrawer, setOpenDrawer] = useState(false);
    const [drawerClinicalConsult, setDrawerClinicalConsult] = useState({id:0});
    const selectedItem = useSelector(selectSelectedItem);

    const onEditMeeting = useCallback(() =>{
        setDrawerClinicalConsult(selectedItem);
        setOpenDrawer(true);
    }, [selectedItem])

    const onNewMeeting = useCallback(() => {
        setDrawerClinicalConsult({id:0});
        setOpenDrawer(true);
    },[])

    const onClosDrawer = useCallback(()=> setOpenDrawer(false),[])

    return (
        <div className="d-flex flex-column">
            <section className="d-flex justify-content-between mb-3 bg-white">
                <Breadcrumb separator={<IoChevronForwardOutline />}>
                    <Breadcrumb.Item key="root">
                        <Link to="/" >
                            <IoHomeSharp />
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item key="clinical-team">
                        <Link to="/clinical-team">
                            Account Team
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Clinical Consults</Breadcrumb.Item>
                </Breadcrumb>
                <Button type="primary" onClick={onNewMeeting}>Create Consult</Button>
            </section>

            <section className="d-flex pt-2">
                <AppointmentList />
                <AppointmentDetails onEditMeeting={onEditMeeting}></AppointmentDetails>
            </section>
            <Drawer title={drawerClinicalConsult.id === 0 ? "Create Consult" : drawerClinicalConsult.duration > 0 ? "Edit Consult" : "Schedule Consult Request"} getContainer={false}
                    placement="right"
                    onClose={() => setOpenDrawer(false)} open={openDrawer}
                    destroyOnClose={true}>
                <CreateMeetingForm clinicalConsult={drawerClinicalConsult} onClosDrawer={onClosDrawer} />
            </Drawer>
        </div>
    );
}

export default AppointmentMeeting;
