import { Tooltip } from 'antd';
import { formatDateTo } from '../../utilities/extensions';

function _copyAndSort(items, columnKey, isSortedDescending) {
   const key = columnKey;
   return items
      .slice(0)
      .sort((a, b) =>
         (isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1,
      );
}

export const columns = [
   {
      key: 'service',
      title: 'Service',
      dataIndex: 'service',
      backField: 'ThirdPartyService.ServiceName',
      ariaLabel: 'Service',
      width: 100,
      ellipsis: {
         showTitle: false,
      },
      render: (value) => (
         <Tooltip placement="topLeft" title={value}>
            {value}
         </Tooltip>
      ),
   },
   {
      key: 'direction',
      title: 'Direction',
      dataIndex: 'direction',
      ariaLabel: 'Direction',
      width: 100,
      ellipsis: {
         showTitle: false,
      },
      render: (value) => (
         <Tooltip placement="topLeft" title={value}>
            {value}
         </Tooltip>
      ),
   },
   {
      key: 'severity',
      title: 'Severity',
      dataIndex: 'severity',
      ariaLabel: 'Severity',
      width: 100,
      ellipsis: {
         showTitle: false,
      },
      render: (value) => (
         <Tooltip placement="topLeft" title={value}>
            {value}
         </Tooltip>
      ),
   },
   {
      key: 'eventTypeDisplay',
      title: 'Event Type',
      dataIndex: 'eventTypeDisplay',
      ariaLabel: 'Event Type',
      width: 150,
      ellipsis: {
         showTitle: false,
      },
      render: (value) => (
         <Tooltip placement="topLeft" title={value}>
            {value}
         </Tooltip>
      ),
   },
   {
      key: 'hrxPatientId',
      title: 'HCRx Patient ID',
      dataIndex: 'hrxPatientId',
      ariaLabel: 'HCRx Patient ID',
      width: 100,
      ellipsis: {
         showTitle: false,
      },
      render: (value) => (
         <Tooltip placement="topLeft" title={value}>
            {value}
         </Tooltip>
      ),
   },
   {
      key: 'teamId',
      title: 'Team ID',
      dataIndex: 'teamId',
      ariaLabel: 'Team ID',
      width: 100,
      ellipsis: {
         showTitle: false,
      },
      
      render: (value) => (
         <Tooltip placement="topLeft" title={value}>
            {value}
         </Tooltip>
      ),
   },
   {
      key: 'dateTime',
      title: 'Date/Time (EST)',
      dataIndex: 'dateTime',
      // backField: 'dateTime',
      ariaLabel: 'Date/Time (EST)',
      width: 180,
      sorter: (a, b,sortAble) => {
         return a.dateTime > b.dateTime; 
      },
      ellipsis: {
         showTitle: true,
      },
      render: (createdOn) => {
         const formattedDate = formatDateTo(createdOn, 'MM/dd/yyyy h:mm a');
         return (
            <Tooltip placement="topLeft" title={formattedDate}>
               {formattedDate}
            </Tooltip>
         );
      },
   },
];

export const skeletonColumns = [
   { key: 'ex', title: '', width: 50, fixed: 'left' },
   ...columns,
];
