import {baseApiService} from "../baseApiService"
import {
    NotificationResendRequest,
    NotificationsRequest,
    NotificationsResponse
} from "../../models/NotificationsResponse";

export const notificationsApi = baseApiService.injectEndpoints({
    endpoints: (builder) => ({
        notificationsReports: builder.mutation<NotificationsResponse, NotificationsRequest>({
            query: (req) => ({
                url: '/v1/notifications/search',
                method: 'POST',
                body: req
            }),
        }),
        notificationsFailures: builder.mutation<NotificationsResponse, NotificationsRequest>({
            query: (req) => ({
                url: '/v1/notifications/search',
                method: 'POST',
                body: req
            }),
        }),
        notificationRetry: builder.mutation<boolean, NotificationResendRequest>({
            query: (req) => ({
                url: '/v1/notifications/retry',
                method: 'POST',
                body: req
            }),
        })
    }),
})

export const { useNotificationsReportsMutation, useNotificationsFailuresMutation, useNotificationRetryMutation } = notificationsApi

