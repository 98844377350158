import { Typography, Form } from 'antd';
import { useNavigate } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import '../../styles/_patientRecord.scss';
import PatientAdmition from '../../components/Patient/PatientAdmition';
import { useState } from 'react';

const AdmitPatient = () => {
    const { Title } = Typography;
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [duplicatedMRN, setDuplicatedMRN] = useState('');

    const onNavigation = () => {
        navigate('/dashboard');
    };

    const modalContents = {
        doneModal: {
            label: 'Success!',
            message: `Patient was submitted succesfully`,
            iconStyle: 'DoneModalIcon',
        },
        WarningModal: {
            label: duplicatedMRN ? `Duplicated MRN` : `Error`,
            message: duplicatedMRN
                ? `Patient with MRN: ${duplicatedMRN} already exists in Hospice Organization. 
            Unable to create patient. Please review MRN and try again `
                : `Patient Information was not successfully updated. Please try again.`,
            iconStyle: 'WarningModalIcon',
        },
    };

    return (
        <>
            <div className="search-patient">
                <LeftOutlined
                    className="left-arrow"
                    onClick={onNavigation}
                    rev={undefined}
                />
                <Title level={1} className="title-class">
                    {'Admit Patient'}
                </Title>
            </div>
            <PatientAdmition
                form={form}
                showMRN={true}
                modalContents={modalContents}
                setDuplicatedMRN={setDuplicatedMRN}
            />
        </>
    );
};
export default AdmitPatient;
