import React, {useEffect, useState} from 'react';
import {Button, Collapse, Drawer, Space} from 'antd';
import FilterBlock from "./FilterBlock";
import {IoCheckmarkSharp, IoCloseSharp} from "react-icons/all";
import {notificationsFilterCount} from "../Schema/notificationsSchema";
import {failuresFilterCount} from "../Schema/failuresSchema";


const { Panel } = Collapse;

const FilterPanel = ({openDrawer, onCloseDrawer, schema, filterData, onApply, onClear,name }) => {

    const [count, setCount] = useState(0);
    const [localFilterData, setLocalFilterData] = useState({...filterData});
    const [localFilterSchema, setlocalFilterSchema] = useState({...schema});
    // const [status, setstatus] = useState(false);

    useEffect(()=>{
        let sCount = notificationsFilterCount(localFilterData);
        setCount(sCount);
    },[localFilterData]);

    // useEffect(()=>{
    //     return(()=>{
    //        setstatus(false);
    //     })
    // },[status]);

    useEffect(()=>{
        if(name === "faiures"){ 
        let sCount = failuresFilterCount(localFilterData);
        setCount(sCount);
        }
    },[localFilterData]);

    // useEffect(()=>{
    //     let sCount = failuresFilterCount(localFilterData);
    //     setCount(sCount);
    // },[localFilterData]);

    return (
        <Drawer extra={
                        <Space>
                            <Button type="text" icon={<IoCheckmarkSharp/>} onClick={e=>onApply(localFilterData)}>
                                Apply {count > 0 && `(${count})`}
                            </Button>
                            <Button type="text" icon={<IoCloseSharp />} onClick={e=>onClear()}>Cancel</Button>
                        </Space>
                }
                className="filter-wrap"
                title="Filter"
                closable={false}
                visible={openDrawer}
                onClose={onCloseDrawer}>

            <div className="d-flex flex-column">
                <Collapse defaultActiveKey={schema.map(k=>k.key)}>
                        {
                            schema.map(i=>
                                <Panel header={i.title} key={i.key}>
                                    {
                                        FilterBlock({...i, onChange:(v)=>{
                                                setLocalFilterData(prev => ({...prev, ...v}));
                                            }, defaultState:filterData})
                                    }
                                </Panel>
                            )
                        }
                </Collapse>
            </div>
        </Drawer>
    );
};

export default FilterPanel;

