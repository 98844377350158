import React from 'react';
import CheckList from "./CheckList";
import NoneBlock from "./NoneBlock";
import DateRange from "./DateRange";
import RadioList from "./RadioList";
import TextBoxList from "./TextBoxList";

const Components = {
    checkList: CheckList,
    radioList: RadioList,
    textBoxList: TextBoxList,
    dateRange: DateRange
};

const FilterBlock = (data) => {
    const {type, key} = data;
    const Com = Components[type];

    const result = data.items.reduce((finalArray, current) => {
        let obj = finalArray.find((item) => item.title === current.title);
        if (obj) {
          return finalArray;
        }
        return finalArray.concat([current]);
      }, []);
   let newItems = {
    items:result
   }
   let newData = {...data,...newItems}
       if (typeof Com !== "undefined"){

        return React.createElement(Com, {key, data:newData });
    }
    return <NoneBlock key={key} data={newData} />;
}

export default FilterBlock;
