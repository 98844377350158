import React from 'react';
import {Breadcrumb} from "antd";
import {IoChevronForwardOutline, IoHomeSharp} from "react-icons/all";
import {Link} from "react-router-dom";

const Settings = () => {
    return (
        <div className="d-flex flex-column">
            <section className="d-flex justify-content-between mb-3 bg-white">
                <Breadcrumb separator={<IoChevronForwardOutline />}>
                    <Breadcrumb.Item key="root">
                        <Link to="/" >
                            <IoHomeSharp />
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Settings</Breadcrumb.Item>
                </Breadcrumb>
            </section>
            <section className="d-flex pt-2">
                <p>Settings place holder</p>
            </section>
        </div>
    );
}

export default Settings;
