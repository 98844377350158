export const GLOBAL_CONFIG = window.globalConfig;
export const CONSTANT_NAME = 'value';
export const STORAGE_KEY = 'authentication';

export const ROLE_HRX_AccountTeamAdmin = 'HRX_AccountTeamAdmin';
export const ROLE_HRX_ClinicalTeamMember = 'HRX_ClinicalTeamMember';
export const ROLE_ADMIN = 'Admin';

export const ClinicalConsultRoles = [ROLE_HRX_ClinicalTeamMember];
export const AdminRoles = [ROLE_ADMIN];
export const AccountTeamAdmin = [ROLE_HRX_AccountTeamAdmin];
