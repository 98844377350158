import React, { useState, useEffect, useMemo } from 'react';
import { columns, skeletonColumns } from './FailuresData';
import { isNotNullOrUndefined } from '../../utilities/extensions';
import { shallowEqual, useSelector } from 'react-redux';
import {
   selectFailures,
   selectFailuresState,
   selectFailuresFilter,
} from '../../features/failure/failuresSlice';
import { Button, Table, notification } from 'antd';
import { IoChevronDown, IoChevronForwardSharp } from 'react-icons/all';
import { defaultFailuresFilter } from '../../components/Schema/failuresSchema';
import {
   useDataFailuresMutation,
   useDataFailuresRetryMutation,
} from '../../features/failure/failuresService';
import SkeletonTable from '../../components/Layout/Utilities/SkeletonTable';
const showNotification = (message, description) => {
   notification.open({
      message,
      description,
   });
};

const FailuresTable = () => {
   const [actionSate, setActionSate] = useState([]);
   const filter = useSelector(selectFailuresFilter, shallowEqual);
   const { isLoading } = useSelector(selectFailuresState);
   const [dataFailures] = useDataFailuresMutation();
   const [dataFailuresRetry] = useDataFailuresRetryMutation();
   const { data, pageNumber, totalCount, pageSize } = useSelector(
      selectFailures,
      shallowEqual,
   );



   const onPageChange = (page, pageSize) => {
      dataFailures({
         ...filter,
         pageNumber: page,
         pageSize: pageSize,
      });

   };



   const onShowSizeChange = (current, size) => {
      dataFailures({ ...filter, pageNumber: 0, pageSize: size });

   };

   const onChange = (
      pagination,
      filters,
      { column, columnKey, field, order },
      { action },
   ) => {
      if (isNotNullOrUndefined(column) && action === 'sort') {
         dataFailures({
            ...filter,
            orderBy: [`${column.backField} ${order}`],
         });
      }

   };

   const failuresColumns = useMemo(() => {
      return [
         ...columns,
         {
            title: 'Actions',
            dataIndex: '',
            key: 'fActions',
            width: 150,
            align: 'center',
            render: (text, record, index) => (
               <Button
                  onClick={async (e) => {
                     setActionSate((prev) => [...prev, index]);
                     let result = false;
                     try {
                        result = await dataFailuresRetry({
                           hangfireJobId: record.hangfireJobId,
                        }).unwrap();
                     } catch (error) {}
                     setActionSate([...actionSate.filter((i) => i !== index)]);
                     showNotification(
                        'Notification',
                        result
                           ? `${record.hangfireJobId} was sent to ${record.eventTypeDisplay} successfully.`
                           : `${record.hangfireJobId} failed to send ${record.eventTypeDisplay}.`,
                     );
                  }}
                  loading={actionSate.includes(index)}
               >
                  Retry
               </Button>
            ),
         },
      ];
   }, [data, actionSate]);





   return (
      <div className="d-flex flex-column">
         <SkeletonTable
            loading={isLoading}
            columns={skeletonColumns}
            active={isLoading}
         >
            <Table

                rowKey="jobDetailId"
               tableLayout="fixed"
               className="table-ctrl"
               columns={failuresColumns}
               onChange={onChange}
               dataSource={data}
               pagination={{
                  className: 'custom-pagination-label',
                  showTotal: (totalCount, range) =>
                     `${range[0]}-${range[1]} of ${totalCount} items`,
                  position:['bottomRight'],
                  showSizeChanger:true,
                current:pageNumber,
                total:totalCount,
                onChange:onPageChange,
                onShowSizeChange:onShowSizeChange

               }}
               scroll={{ y: 500, x: 900 }}
               expandable={{
                  rowExpandable: (record) =>
                     isNotNullOrUndefined(record.reason),
                  expandedRowRender: (record) => (
                     <div className="p-2 d-flex flex-column">
                        <div className="h6">Reason</div>
                        <div
                           className="p-2 border html-details"
                           dangerouslySetInnerHTML={{
                              __html: record.reason
                           }}
                        />
                        <div className="h6">Exception Message</div>
                        <div
                           className="p-2 border html-details"
                           dangerouslySetInnerHTML={{
                              __html: record.exceptionMessage
                           }}
                        />
                        <div className="h6">Exception Details</div>
                        <div
                           className="p-2 border html-details"
                           dangerouslySetInnerHTML={{
                              __html: record.exceptionDetails
                           }}
                        />
                     </div>
                  ),
                  expandIcon: ({ expanded, onExpand, record }) =>
                     expanded ? (
                        <IoChevronDown onClick={(e) => onExpand(record, e)} />
                     ) : (
                        <IoChevronForwardSharp
                           onClick={(e) => onExpand(record, e)}
                        />
                     ),
               }}
            />
         </SkeletonTable>
      </div>
   );
};

export default FailuresTable;
