import React, { useState, useEffect } from 'react';
import { Tabs, Card, Button, Form, Modal, FormInstance } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import {
    CheckCircleOutlined,
    ExclamationCircleOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import '../../styles/_patientRecord.scss';
import {
    formatDate,
    phoneNumberValidator,
    maxLengthValidator,
    stateLengthValidator,
    zipCodeValidator,
} from '../../utilities/extensions';
import {
    useGetDropDownOptionsMutation,
    useGetPractiontionerOptionsMutation,
    usePostPatientRecordMutation,
} from '../../features/patient/patientService';
import { AnyObject } from 'yup/lib/types';
import { useAuth } from '../../hooks/useAuth';
import { Utility } from '../../utilities/Utility';
import {
    PatientDetailsResponse,
    PatientField,
} from '../../models/PatientModels';
import { Rule } from 'antd/lib/form';
import { MRN_NO_MATCH } from '../../utilities/validationMessages';
import { PatientAdmitionForm } from './PatientAdmitionForm';

interface PatientData {
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    hrxPatientId: string;
    medicalRecordId: string;
    patientStatusDisplay: string;
}

const PatientAdmition: React.FC<{
    form: FormInstance<any>;
    patientData?: PatientDetailsResponse;
    showMRN: boolean;
    updateMode?: boolean;
    setDuplicatedMRN?: React.Dispatch<React.SetStateAction<string>>;
    setIsError?: React.Dispatch<React.SetStateAction<boolean>>;
    showErrorModal?: boolean;
    modalContents: {
        doneModal: {
            label: string;
            message: string;
            iconStyle: string;
        };
        WarningModal: {
            label: string;
            message: string;
            iconStyle: string;
        };
    };
}> = ({
    form,
    patientData,
    showMRN = true,
    updateMode,
    modalContents,
    setDuplicatedMRN,
    setIsError,
    showErrorModal,
}) => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { profile } = useAuth();
    const [dropdown_options] = useGetDropDownOptionsMutation();
    const [practitioner_Options] = useGetPractiontionerOptionsMutation();
    const [postPatientRecord, { isLoading }] = usePostPatientRecordMutation();
    const [countryOptions, setCountryOptions] = useState(Array<any>);
    const [languageOptions, setLanguageOptions] = useState(Array<any>);
    const [teamOptions, setTeamOptions] = useState<any[]>([]);
    const [locationOfServiceOptions, setLocationOfServiceOptions] = useState(
        Array<any>,
    );
    const [showModal, setShowModal] = useState<boolean>(false);
    // Used when patientData is not present
    const [patientId, setPatientId] = useState<string>('');
    useEffect(() => {
        if (id) setPatientId(id);
    }, [id]);

    const data: PatientData | AnyObject = {
        patientId: null,
        hrxPatientId: null,
        medicalRecordId: null,
        patientStatus: 2,
        scriptSurePatientId: null,
        medicalRecordId_copy: null,
        hospiceOrganizationId: null,
        teamCode: null,
        locationOfServiceId: null,
        consent: false,
        patientStatusDisplay: null,
        admissionAddress: {
            streetAddress: null,
            city: null,
            state: null,
            zipCode: null,
            country: 'US',
        },
        demographics: {
            firstName: null,
            middleName: null,
            lastName: null,
            dateOfBirth: null,
            socialSecurityNumber: null,
            gender: null,
            race: null,
            religion: null,
            language: null,
            citizenship: null,
            maritalStatus: null,
            phoneNumber: {
                home: null,
                office: null,
                mobile: null,
            },
            address: {
                streetAddress: null,
                city: null,
                state: null,
                zipCode: null,
                country: null,
            },
            notes: null,
        },
        diagnoses: [],
        primaryCarePractitionerUserId: null,
        identifiers: [
            {
                identifier: null,
                identifierType: null,
            },
            {
                identifier: null,
                identifierType: null,
            },
        ],
        allergies: [],
        locationOfServiceOptions: [
            {
                id: null,
                subGroupId: null,
                name: null,
            },
            {
                id: null,
                subGroupId: null,
                name: null,
            },
        ],
        primaryCarePractitioner: {
            userId: null,
            firstName: null,
            lastName: null,
            npi: null,
        },
        eligibilityStartDate: null,
        eligibilityEndDate: null,
    };

    const [practitionerOptions, setPractitionerOptions] = useState<Array<any>>(
        [],
    );

    useEffect(() => {
        if (patientData) {
            form.setFieldsValue({
                ...patientData,
                demographics: {
                    ...patientData.demographics,
                    dateOfBirth: patientData?.demographics?.dateOfBirth
                        ? moment(
                              formatDate(patientData.demographics.dateOfBirth),
                              'DD-MM-YYYY',
                          )
                        : undefined,
                },
                admissionAddress: {
                    ...patientData.admissionAddress,
                    country: 'US',
                },
                eligibilityStartDate: patientData?.eligibilityStartDate
                    ? moment(
                          formatDate(patientData.eligibilityStartDate),
                          'DD-MM-YYYY',
                      )
                    : undefined,
            });
        }
    }, [form, patientData, teamOptions]);

    useEffect(() => {
        // Re-Admition
        if (patientData && !updateMode && teamOptions.length) {
            const teamOption = teamOptions?.[0];
            onTeamChange(teamOption?.code);
            form.setFieldValue('teamCode', teamOption?.code);
            setLocationOfServiceOptions(teamOption?.locationOfServiceOptions);
            form.setFieldValue(
                'locationOfServiceId',
                teamOption?.locationOfServiceOptions?.[0]?.id,
            );
        }
    }, [patientData, teamOptions, updateMode]);

    useEffect(() => {
        // Re-Admition
        if (patientData && !updateMode && practitionerOptions.length) {
            const pcp = practitionerOptions?.[0]?.userId;
            if (pcp) {
                form.setFieldValue('primaryCarePractitionerUserId', pcp);
                onPCPChange(pcp);
            }
        }
    }, [patientData, practitionerOptions, updateMode]);

    const genderList = ['Male', 'Female', 'Other'];

    const [status, setStatus] = useState({
        done: false,
        error: false,
        changes: false,
    });
    const [isDirtyFields, setIsDirtyFields] = useState<boolean>(false);

    useEffect(() => {
        if (setIsError) setIsError(isDirtyFields);
        if (isDirtyFields && showErrorModal) {
            setStatus({
                done: false,
                error: true,
                changes: false,
            });
        }
    }, [isDirtyFields, showErrorModal, setIsError]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const langResponse = (await getDropDownOption(
                    data.hospiceOrganizationId,
                )) as {
                    data: {
                        languageOptions: Record<string, string> | [];
                        teamOptions: Array<{
                            locationOfServiceOptions: Array<any>;
                        }>;
                        countryOptions: Array<any>;
                    };
                };
                const langMenuItems = Object.entries(
                    langResponse?.data?.languageOptions || {},
                ).map(([key, value]) => ({
                    key,
                    label: value,
                }));
                setLanguageOptions(langMenuItems);
                const teamOptionData = langResponse?.data?.teamOptions;
                setTeamOptions(teamOptionData);
                setCountryOptions(langResponse?.data?.countryOptions);
            } catch (error) {
                console.error('Error fetching dropdown options:', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            if (updateMode && patientData) {
                const patientTeamId = teamOptions.find(
                    (team) => team.code === patientData.teamCode,
                )?.id;

                if (patientTeamId) await getPracticionerOptions(patientTeamId);

                const allLocationOptions =
                    patientData?.locationOfServiceOptions;
                if (allLocationOptions)
                    setLocationOfServiceOptions(allLocationOptions);
            }
        };

        fetchData();
    }, [teamOptions, updateMode, patientData]);

    const onTeamChange = async (
        value: string | React.ChangeEvent<HTMLInputElement>,
    ) => {
        form.setFieldValue('locationOfServiceId', null);
        const team = teamOptions.find((team) => team.code === value);

        await getPracticionerOptions(team?.id);
        setLocationOfServiceOptions(team?.locationOfServiceOptions);
        // Reset locationOfService value
        form.setFieldValue(
            'locationOfServiceOptions',
            team?.locationOfServiceOptions,
        );
        form.setFieldValue(['identifiers', 1], {
            identifier: value,
            identifierType: 'TeamId',
        });
    };

    const onPCPChange = async (
        value: string | React.ChangeEvent<HTMLInputElement>,
    ) => {
        const practitioner = practitionerOptions.find(
            (i) => i.userId === value,
        );

        form.setFieldValue(
            ['primaryCarePractitioner', 'npi'],
            practitioner.npi,
        );
        form.setFieldValue(
            ['primaryCarePractitioner', 'lastName'],
            practitioner.lastName,
        );
        form.setFieldValue(
            ['primaryCarePractitioner', 'firstName'],
            practitioner.firstName,
        );
    };

    const getPracticionerOptions = async (teamId: string) => {
        try {
            const pracResponse = (await getPractiontionerOptions(teamId)) as {
                data: Array<any>;
            };

            if (pracResponse?.data) setPractitionerOptions(pracResponse?.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const buttonOnClick = () => {
        setStatus({
            done: false,
            error: false,
            changes: false,
        });
        if (setDuplicatedMRN) setDuplicatedMRN('');
        if (status.done) {
            navigate('/patient/' + patientId);
        }
    };

    const mainInfoFields: PatientField[] = [
        ...(showMRN
            ? [
                  {
                      label: 'MRN(Medical Record Number)',
                      key: 'medicalRecordId_copy',
                      dataField: [],
                      placeHolder: 'Enter Medical Record Number',
                      mandatory: true,
                      fieldType: 'input',
                      note: 'Note: Do not enter prefix',
                  },
                  {
                      label: 'Confirm MRN',
                      key: 'medicalRecordId',
                      dataField: [],
                      placeHolder: 'Confirm Medical Record Number',
                      mandatory: true,
                      validator: (rule: Rule, value: any) =>
                          medicalRecordIdValidator(value),
                      fieldType: 'input',
                      onChange: async (
                          value: string | React.ChangeEvent<HTMLInputElement>,
                      ) => {
                          if (typeof value === 'string') return;

                          form.setFieldValue(['identifiers', 0], {
                              identifier: value?.target?.value,
                              identifierType: 'MRN',
                          });
                      },
                  },
              ]
            : []),
        {
            label: 'First Name',
            key: 'firstName',
            dataField: ['demographics'],
            placeHolder: 'Enter First Name',
            mandatory: true,
            validator: (rule: Rule, value: any) => maxLengthValidator(value),
            fieldType: 'input',
        },
        {
            label: 'Middle Name',
            key: 'middleName',
            dataField: ['demographics'],
            placeHolder: 'Enter Middle Name',
            mandatory: false,
            validator: (rule: Rule, value: any) => maxLengthValidator(value),
            fieldType: 'input',
        },
        {
            label: 'Last Name',
            key: 'lastName',
            dataField: ['demographics'],
            placeHolder: 'Enter Last Name',
            mandatory: true,
            validator: (rule: Rule, value: any) => maxLengthValidator(value),
            fieldType: 'input',
        },
        {
            label: 'Date of Birth',
            key: 'dateOfBirth',
            dataField: ['demographics'],
            placeHolder: 'Enter Date of Birth',
            mandatory: true,
            fieldType: 'datepicker',
        },
        {
            label: 'Language',
            key: 'language',
            dataField: ['demographics'],
            placeHolder: 'Enter Language',
            mandatory: false,
            fieldType: 'dropdown',
            options: languageOptions.map((option) => ({
                value: option.key,
                label: option.label,
            })),
        },
        {
            label: 'Gender',
            key: 'gender',
            dataField: ['demographics'],
            placeHolder: 'Enter Gender',
            mandatory: true,
            fieldType: 'dropdown',
            options: genderList.map((option) => ({
                value: option,
                label: option,
            })),
        },
        {
            label: 'Phone Number',
            key: 'home',
            dataField: ['demographics', 'phoneNumber'],
            placeHolder: 'Enter Phone Number',
            mandatory: true,
            validator: (rule: Rule, value: any) =>
                phoneNumberValidator(value, 'phone'),
            fieldType: 'input',
        },
        {
            label: 'Mobile',
            key: 'mobile',
            dataField: ['demographics', 'phoneNumber'],
            placeHolder: 'Enter Mobile Number',
            mandatory: false,
            validator: (rule: Rule, value: any) =>
                phoneNumberValidator(value, 'phone'),
            fieldType: 'input',
        },
        {
            label: 'Work',
            key: 'office',
            dataField: ['demographics', 'phoneNumber'],
            placeHolder: 'Enter Office Number',
            mandatory: false,
            validator: (rule: Rule, value: any) =>
                phoneNumberValidator(value, 'phone'),
            fieldType: 'input',
        },
        ...(!updateMode
            ? [
                  {
                      label: 'Start of Care',
                      key: 'eligibilityStartDate',
                      dataField: [],
                      placeHolder: 'Enter Start of Care',
                      mandatory: true,
                      fieldType: 'datepicker',
                  },
                  {
                      label: 'Patient consents to download medical history',
                      key: 'consent',
                      dataField: [],
                      placeHolder: '',
                      mandatory: false,
                      fieldType: 'checkbox',
                  },
              ]
            : []),
    ];

    const admissionFields: PatientField[] = [
        {
            label: 'Street Address',
            key: 'streetAddress',
            dataField: ['admissionAddress'],
            placeHolder: 'Enter Street Address ',
            mandatory: true,
            fieldType: 'input',
        },

        {
            label: 'State',
            key: 'state',
            dataField: ['admissionAddress'],
            placeHolder: 'Enter State',
            mandatory: true,
            fieldType: 'input',
            validator: (rule, value) => stateLengthValidator(value),
        },
        {
            label: 'Country',
            key: 'country',
            dataField: ['admissionAddress'],
            placeHolder: 'Enter Country',
            mandatory: true,
            fieldType: 'dropdown',
            options: countryOptions.map((option) => ({
                value: option.alpha2,
                label: option.name,
            })),
        },
        {
            label: 'City',
            key: 'city',
            dataField: ['admissionAddress'],
            placeHolder: 'Enter City ',
            mandatory: true,
            fieldType: 'input',
        },
        {
            label: 'Zip Code',
            key: 'zipCode',
            dataField: ['admissionAddress'],
            placeHolder: 'Enter Zip Code',
            mandatory: true,
            validator: (rule: Rule, value: any) => zipCodeValidator(value),
            fieldType: 'input',
        },
        ...(!updateMode
            ? [
                  {
                      label: 'Designated Team',
                      key: 'teamCode',
                      dataField: [],
                      placeHolder: 'Enter Designated Team',
                      mandatory: true,
                      fieldType: 'dropdown',
                      options: teamOptions.map((option) => ({
                          value: option.code,
                          label: option.name,
                      })),
                      onChange: onTeamChange,
                  },
              ]
            : []),
        {
            label: 'Location of Service',
            key: 'locationOfServiceId',
            dataField: [],
            placeHolder: 'Enter Location of Service',
            mandatory: true,
            fieldType: 'dropdown',
            options:
                (locationOfServiceOptions &&
                    locationOfServiceOptions.map((option) => ({
                        value: option.id,
                        label: option.name,
                    }))) ||
                [],
        },
    ];

    const practitionerFields: PatientField[] = [
        {
            label: 'Primary Care Practitioner',
            key: 'primaryCarePractitionerUserId',
            dataField: [],
            placeHolder: 'Select Practitioner',
            mandatory: true,
            fieldType: 'dropdown',
            style: { width: '100%', maxWidth: '350px' },
            note: !updateMode ? (
                <div style={{ display: 'flex' }}>
                    <span>*</span>
                    <span>
                        Team on previous page must be selected before primary
                        care practitioner options are available
                    </span>
                </div>
            ) : (
                ''
            ),
            options: practitionerOptions.map((option) => ({
                value: option.userId,
                label: `${option.firstName} ${option.lastName}`,
            })),
            onChange: onPCPChange,
        },
        {
            label: 'First Name',
            key: 'firstName',
            dataField: ['primaryCarePractitioner'],
            placeHolder: 'Enter First Name',
            mandatory: true,
            fieldType: 'none',
        },
        {
            label: 'Last Name',
            key: 'lastName',
            dataField: ['primaryCarePractitioner'],
            placeHolder: 'Enter Last Name',
            mandatory: true,
            fieldType: 'none',
        },
        {
            label: 'Physician NPI',
            key: 'npi',
            dataField: ['primaryCarePractitioner'],
            placeHolder: 'Enter Physician NPI',
            mandatory: true,
            fieldType: 'none',
        },
    ];

    const tabData = [
        {
            key: '1',
            title: 'Main info',
            view: true,
            edit: true,
            viewCardTitles: [
                {
                    title: 'Primary Patient Info',
                    cols: [
                        showMRN ? { from: 0, to: 5 } : { from: 0, to: 3 },
                        showMRN ? { from: 5, to: 8 } : { from: 3, to: 6 },
                    ],
                },
                {
                    title: 'Primary Contact Info',
                    cols: [showMRN ? { from: 8, to: 12 } : { from: 6, to: 10 }],
                },
            ],
            fields: mainInfoFields,
        },
        {
            key: '2',
            title: 'Primary Admission',
            viewCardTitles: [
                {
                    title: 'Primary Admission Address',
                    cols: [
                        { from: 0, to: 3 },
                        { from: 3, to: 7 },
                        // Extra cols for styling
                        { from: 0, to: 0 },
                    ],
                },
            ],
            edit: true,
            view: false,
            fields: admissionFields,
        },
        {
            key: '3',
            title: 'PCP',
            viewCardTitles: [
                {
                    title: 'Primary Care Practitioner',
                    cols: [
                        { from: 0, to: 4 },
                        // Extra cols for styling
                        { from: 0, to: 0 },
                    ],
                },
            ],
            edit: true,
            view: false,
            fields: practitionerFields,
        },
    ];
    const [activeKey, setActiveKey] = React.useState<string>('1');

    const getDropDownOption = async (_id: string) => {
        try {
            const response = await dropdown_options(_id);
            return response;
        } catch (error) {
            return error;
        }
    };

    const getPractiontionerOptions = async (_id: string) => {
        try {
            const response = await practitioner_Options(_id);
            return response;
        } catch (error) {
            return error;
        }
    };

    const medicalRecordIdValidator = (value: any) => {
        if (!value || value === form.getFieldValue('medicalRecordId_copy')) {
            return Promise.resolve();
        }
        return Promise.reject(MRN_NO_MATCH);
    };

    const handleChange = () => {
        if (!isDirtyFields) setIsDirtyFields(true);
    };

    const handleSubmit = async () => {
        const isValid = await validateFormFields(true);
        if (!isValid) return;

        const values = form.getFieldsValue(true);

        const data = {
            ...values,
            eligibilityStartDate: moment(
                values.eligibilityStartDate.format('YYYY-MM-DD'),
                'YYYY-MM-DD',
            ),
            hospiceOrganizationId: profile?.hospiceOrganizationId,
            demographics: {
                ...values.demographics,
                dateOfBirth: values?.demographics?.dateOfBirth.format(
                    'YYYY-MM-DDT00:00:00',
                ),
            },
        };
        delete data.medicalRecordId_copy;

        const payload = { IsAdmitted: true, Patient: data };
        try {
            const response = await postPatientRecordAPI(payload);
            if (!!response?.data) {
                setPatientId(response?.data?.id);
                setStatus({
                    done: true,
                    error: false,
                    changes: false,
                });
            } else {
                if (
                    setDuplicatedMRN &&
                    response?.error?.data?.title.includes('already exists')
                ) {
                    setDuplicatedMRN(form.getFieldsValue().medicalRecordId);
                }
                setStatus({
                    done: false,
                    error: true,
                    changes: false,
                });
            }
        } catch (error: any) {
            setStatus({
                done: false,
                error: true,
                changes: false,
            });
            console.error('Error occurred:', error);
        }
    };

    const validateFormFields: (
        validateAll?: boolean,
    ) => Promise<boolean> = async (validateAll = false) => {
        let errors;
        if (validateAll) {
            // Validate all fields
            errors = await form
                .validateFields()
                .then((i) => i)
                .catch((err) => err);
        }

        if (!validateAll) {
            // Validate only current step fields
            const selectedTab = tabData.find((i) => i.key === activeKey);
            const fieldNameList = selectedTab?.fields.map((e) =>
                e.dataField ? [...e.dataField, e.key] : e.key,
            );
            errors = await form
                .validateFields(fieldNameList)
                .then((i) => i)
                .catch((err) => err);
        }

        const warningsExist = errors?.errorFields?.length;
        if (warningsExist > 0) {
            Utility.errorToast('Fix all field warnings!');
            return false;
        }
        return true;
    };

    const postPatientRecordAPI = async (data: any | null) => {
        try {
            const response: any = await postPatientRecord(data);
            return response;
        } catch (error) {
            return error;
        }
    };

    const onNextKeyChange = async () => {
        const currentIndex = Number(activeKey) - 1;
        const nextIndex = currentIndex + 1;
        if (nextIndex < tabData.length) {
            setActiveKey((prevVal) => String(Number(prevVal) + 1));
        }
    };

    const onPrevKeyChange = () => {
        const currentIndex = Number(activeKey) - 1;
        const prevIndex = currentIndex - 1;
        if (prevIndex >= 0) {
            setActiveKey((prevVal) => String(Number(prevVal) - 1));
        }
    };

    const onKeyChange = (key: string) => {
        setActiveKey(key);
    };

    const handleMultipleBtn = async (type: string) => {
        if (type === 'Previous') {
            return onPrevKeyChange();
        }

        const isValid = await validateFormFields();
        if (!isValid) return;

        if (type === 'Next') {
            onNextKeyChange();
        } else if (type === 'Cancel') {
            navigate('/dashboard');
            setStatus({
                done: false,
                error: false,
                changes: false,
            });
        } else if (['Complete & Admit', 'SAVE'].includes(type)) {
            await handleSubmit();
        }
    };

    const renderModalContent = (type: string) => {
        return (
            <>
                <div className="display-flex">
                    {type === 'done' ? (
                        <CheckCircleOutlined
                            rev={undefined}
                            className={modalContents.doneModal.iconStyle}
                        />
                    ) : (
                        <ExclamationCircleOutlined
                            rev={undefined}
                            className={modalContents.WarningModal.iconStyle}
                        />
                    )}
                    <p className="para-styles">
                        {type === 'done'
                            ? modalContents.doneModal.label
                            : modalContents.WarningModal.label}
                    </p>
                </div>
                <div className="error-btn">
                    {type === 'done'
                        ? modalContents.doneModal.message
                        : modalContents.WarningModal.message}
                </div>
            </>
        );
    };

    return (
        <div className="tab-styles">
            <Tabs
                defaultActiveKey={tabData[0].key}
                activeKey={activeKey}
                onChange={onKeyChange}
                tabBarExtraContent={
                    <div className={'display-flex'}>
                        {activeKey === '1' ? (
                            ''
                        ) : (
                            <Button
                                type={'primary'}
                                className="button-margin"
                                onClick={() =>
                                    handleMultipleBtn(
                                        activeKey === '1' ? '' : 'Previous',
                                    )
                                }
                            >
                                {activeKey === '1' ? null : 'Previous'}
                            </Button>
                        )}
                        <Button
                            disabled={
                                (updateMode &&
                                activeKey === '3' &&
                                isDirtyFields === false
                                    ? true
                                    : false) || isLoading
                            }
                            type="primary"
                            loading={isLoading}
                            onClick={() =>
                                handleMultipleBtn(
                                    activeKey === '3'
                                        ? updateMode
                                            ? 'SAVE'
                                            : 'Complete & Admit'
                                        : 'Next',
                                )
                            }
                        >
                            {activeKey === '3'
                                ? updateMode
                                    ? 'SAVE'
                                    : 'Complete & Admit'
                                : 'Next'}
                        </Button>
                    </div>
                }
            >
                {tabData.map((tab) => (
                    <Tabs.TabPane tab={tab.title} key={tab.key}>
                        <Card className="card-width">
                            <Form
                                className="patient-form"
                                onValuesChange={handleChange}
                                name="editForm"
                                form={form}
                                {...(!updateMode
                                    ? { initialValues: data }
                                    : {})}
                                preserve={true}
                            >
                                <PatientAdmitionForm
                                    selectedTab={tab}
                                    fields={tab.fields}
                                />
                            </Form>
                        </Card>
                    </Tabs.TabPane>
                ))}
            </Tabs>
            <CustomModal
                setShowModal={setShowModal}
                showModal={showModal}
                modalContents={modalContents}
            />
            {(status.done || status.error) && (
                <Modal
                    open={status.done || status.error}
                    closable={false}
                    footer={
                        status.error
                            ? [
                                  <Button
                                      key="cancel"
                                      type="primary"
                                      onClick={() =>
                                          setStatus({
                                              done: false,
                                              error: false,
                                              changes: true,
                                          })
                                      }
                                  >
                                      {'Cancel'}
                                  </Button>,
                                  <Button
                                      key="confirm"
                                      type="primary"
                                      onClick={() => buttonOnClick()}
                                  >
                                      {'Continue'}
                                  </Button>,
                              ]
                            : [
                                  <Button
                                      key="confirm"
                                      type="primary"
                                      onClick={async (e) => {
                                          buttonOnClick();
                                      }}
                                  >
                                      {'OK'}
                                  </Button>,
                              ]
                    }
                >
                    {renderModalContent(status.error ? 'error' : 'done')}
                </Modal>
            )}
        </div>
    );
};
export default PatientAdmition;

function CustomModal({
    showModal,
    setShowModal,
    modalContents,
}: Readonly<{
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    modalContents: {
        doneModal: { label: string; message: string; iconStyle: string };
        WarningModal: { label: string; message: string; iconStyle: string };
    };
}>) {
    return (
        <Modal
            open={showModal}
            closable={false}
            centered={true}
            footer={[
                <Button
                    key="confirm"
                    type="primary"
                    onClick={() => setShowModal(false)}
                >
                    OK
                </Button>,
            ]}
        >
            <div className="display-flex align-item-center">
                <CheckCircleOutlined
                    rev={undefined}
                    className={modalContents.doneModal.iconStyle}
                />
                <p className="para-styles">{modalContents.doneModal.label}</p>
            </div>
            <div className="error-btn">{modalContents.doneModal.message}</div>
        </Modal>
    );
}
