import React, { useState } from 'react';
import { Button, Input, Typography, DatePicker, Modal, Form } from 'antd';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import {
   LeftOutlined,
   ExclamationCircleOutlined,
   CloseCircleOutlined,
} from '@ant-design/icons';
import '../../styles/_searchPatient.scss';
import { usePatientSearchResultMutation } from '../../features/patient/patientService';

const SearchPatient = () => {
   const [searchPatient] = usePatientSearchResultMutation();
   const { Title } = Typography;
   const [searchValidate, setSearchValidate] = useState(false);
   const [modalVisible, setModalVisible] = useState(false);
   const [responseWarning, setResponseWarning] = useState(false);
   const [medicalRecord, setMedicalRecord] = useState(false);
   const navigate = useNavigate();
   const [form] = Form.useForm();
   const initialValues = {
      hospisRX_id: '',
      firstName: '',
      lastName: '',
      dateOfBirth: null,
   };

   const handleInputChange = (fieldName, value) => {
      form.setFieldsValue({ [fieldName]: value });
   };

   const fetchPatientResults = async (searchRequest) => {
      try {
         const response = await searchPatient(searchRequest);
         return response;
      } catch (error) {
         return error;
      }
   };

   const inputFields = [
      { label: 'First Name', type: 'text', state_id: 'firstName' },
      { label: 'Last Name', type: 'text', state_id: 'lastName' },
      { label: 'Date of Birth', type: 'date', state_id: 'dateOfBirth' },
   ];

   const modalContents = {
      searchValidateModal: {
         label: 'Search Validation Error',
         message: `You must either enter HospisRx Patient ID OR the combination of 
                  partial first name , last name and DOB to perform a patient search`,
         iconStyle: 'searchValidateModalIcon',
      },
      responseWarningModal: {
         label: 'Patient Not Found',
         message: `No Patients were found in HospisRx that match the search criteria. Would you like to query your EMR for this Patient Record?`,
         iconStyle: 'searchWarningModalIcon',
      },
   };

   const validateSearch = async (values) => {
      const { hospisRX_id, firstName, lastName, dateOfBirth } = values;
      return !!(!!hospisRX_id || (!!firstName && !!lastName && !!dateOfBirth));
   };

   const handleSubmit = async (values) => {
      const validation = await validateSearch(values);
      const { hospisRX_id, firstName, lastName, dateOfBirth } = values;
      try {
         if (validation) {
            setSearchValidate(false);
            const searchRequest = {
               medicalRecordId: hospisRX_id,
               firstName: firstName,
               lastName: lastName,
               dateOfBirth: dateOfBirth
                  ? `${moment(dateOfBirth).format('YYYY-MM-DD')}T00:00:00`
                  : null,
            };

            const response = await fetchPatientResults(searchRequest);

            if (response?.data?.data) {
               if (response?.data?.data.length === 0) {
                  setModalVisible(true);
                  setResponseWarning(true);
                  return;
               }
               navigate('/patient-results');
            } else {
               console.log('something went wrong');
            }
         } else {
            setModalVisible(true);
            setSearchValidate(true);
         }
      } catch (error) {
         console.error('Error fetching patient results:', error);
      }
   };

   const handleCancel = (type) => {
      setModalVisible(false);
      type === 'validate'
         ? setSearchValidate(false)
         : setResponseWarning(false);
   };

   const handleConfirm = (type) => {
      if (type === 'validate') {
         setModalVisible(false);
         setSearchValidate(false);
      }
      if (type === 'response') {
         setModalVisible(false);
         setResponseWarning(false);
         setMedicalRecord(true);
      }
   };

   const renderModalContent = (type) => {
      return (
         <>
            <div className="display-flex">
               {type === 'validate' ? (
                  <ExclamationCircleOutlined
                     className={modalContents.searchValidateModal.iconStyle}
                  />
               ) : (
                  <CloseCircleOutlined
                     className={modalContents.responseWarningModal.iconStyle}
                  />
               )}
               <p className="para-styles">
                  {type === 'validate'
                     ? modalContents.searchValidateModal.label
                     : modalContents.responseWarningModal.label}
               </p>
            </div>
            <div className="validate-btn">
               {type === 'validate'
                  ? modalContents.searchValidateModal.message
                  : modalContents.responseWarningModal.message}
            </div>
         </>
      );
   };

   return (
      <>
         <Form
            form={form}
            onFinish={handleSubmit}
            initialValues={initialValues}
         >
            <div className="search-patient">
               <LeftOutlined
                  className="left-arrow"
                  onClick={() => navigate('/dashboard')}
                  rev={undefined}
               />

               <Title level={1} className="title-class">
                  {!medicalRecord
                     ? `Search For Patient`
                     : `Search Medical Record`}
               </Title>
            </div>
            <Form.Item name="hospisRX_id">
               <div>
                  <label htmlFor="id" className="custom-label">
                     {!medicalRecord
                        ? `HospiceChoiceRx Patient ID`
                        : `Medical Record Number`}
                  </label>
                  <Input
                     id="hospisRX_id"
                     placeholder="Enter HospiceChoiceRx ID"
                     className="custom-width-padding"
                     onChange={(e) => handleInputChange('id', e.target.value)}
                  />
               </div>
            </Form.Item>
            <Title level={2} className="or-element">
               or
            </Title>
            {inputFields?.map((field, index) => (
               <Form.Item name={field.state_id} key={field.state_id}>
                  <div className="position-relative">
                     <label htmlFor={field.state_id} className="custom-label">
                        {field.label}
                     </label>
                     {field.type === 'date' ? (
                        <DatePicker
                           getPopupContainer={(trigger) =>
                              trigger.parentElement
                           }
                           id={field.state_id}
                           className="custom-width-padding"
                           style={{ width: '96%' }}
                           onChange={(date) =>
                              handleInputChange(field.state_id, date)
                           }
                           disabledDate={(current) => {
                              return current && current > new Date();
                           }}
                           format={'MM-DD-YYYY'}
                        />
                     ) : (
                        <Input
                           autoComplete="off"
                           id={field.state_id}
                           placeholder={`Enter ${field.label}`}
                           className="custom-width-padding"
                           onChange={(e) =>
                              handleInputChange(field.state_id, e.target.value)
                           }
                        />
                     )}
                  </div>
               </Form.Item>
            ))}

            <Button
               type="primary"
               htmlType="submit"
               className="search-btn-main"
            >
               {!medicalRecord ? `Search` : `Locate Medical Record`}
            </Button>
         </Form>
         {(searchValidate || responseWarning) && (
            <Modal
               open={modalVisible}
               closable={false}
               footer={[
                  <Button
                     key="cancel"
                     onClick={() =>
                        handleCancel(searchValidate ? 'validate' : 'response')
                     }
                  >
                     Cancel
                  </Button>,
                  <Button
                     key="confirm"
                     type="primary"
                     onClick={() =>
                        handleConfirm(searchValidate ? 'validate' : 'response')
                     }
                  >
                     {searchValidate ? 'Ok' : 'Yes'}
                  </Button>,
               ]}
            >
               {renderModalContent(searchValidate ? 'validate' : 'response')}
            </Modal>
         )}
      </>
   );
};

export default SearchPatient;
