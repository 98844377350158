import React, { useMemo, useState } from 'react';
import 'antd/dist/antd.css';
import { Avatar, Button, Dropdown, Layout, Menu } from 'antd';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import {
   AiFillHeart,
   AiOutlineBell,
   AiOutlineLogout,
   AiOutlineMenuFold,
   AiOutlineMenuUnfold,
   AiOutlineSetting,
   AiOutlineUser,
   RiUserSettingsLine,
} from 'react-icons/all';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useAuth } from '../../../hooks/useAuth.ts';
import { logOut } from '../../../features/auth/authSlice';
import { motion } from 'framer-motion/dist/framer-motion';
import SideMenuItems from './SideMenuItems';
const { Header, Content, Footer, Sider } = Layout;

const MasterLayoutComponent = () => {
   const dispatch = useDispatch();
   const { profile } = useAuth();
   const authACs = bindActionCreators({ logOut }, dispatch);
   const [collapsed, setCollapsed] = useState(false);
   let navigate = useNavigate();
   const { pathname } = useLocation();
   const onClick = (e) => {
      navigate(e.keyPath.reverse().join('/'));
   };

   const menu = useMemo(
      () => (
         <Menu
            className="user-menu"
            items={[
               {
                  key: 'fullName',
                  label: (
                     <div className="d-flex align-items-center justify-content-start p-2">
                        <Avatar
                           className="user-avatar me-2"
                           icon={<AiOutlineUser />}
                        />
                        <div className="d-flex flex-column">
                           <span className="text-uppercase">
                              {profile.firstName} {profile.lastName}
                           </span>
                           <span>developer</span>
                        </div>
                     </div>
                  ),
               },
               {
                  key: 'preferences',
                  label: (
                     <span>
                        <RiUserSettingsLine className="me-2" />
                        Preferences
                     </span>
                  ),
                  onClick: (i) => navigate('/settings/notifications'),
               },
               {
                  key: 'settings',
                  label: (
                     <span>
                        <AiOutlineSetting className="me-2" />
                        Settings
                     </span>
                  ),
               },
               {
                  type: 'divider',
               },
               {
                  key: 'logout',
                  label: (
                     <span>
                        <AiOutlineLogout className="me-2" />
                        Logout
                     </span>
                  ),
                  onClick: (i) => authACs.logOut(),
               },
            ]}
         />
      ),
      [profile],
   );
   return (
      <Layout className="root-layout">
         <Sider
            breakpoint="lg"
            trigger={null}
            collapsible
            collapsed={collapsed}
            onCollapse={(collapsed, type) => {
               setCollapsed(collapsed);
            }}
            className="slider-layout main-slider shadow"
            style={{ backgroundColor: '#FFF', color: '#00a9e8' }}
         >
            <div className="logo">
               <img
                  src={
                     collapsed
                        ? '/assets/rx-images/HCRx_Logo.png'
                        : '/assets/rx-images/hospisrx-logo.png'
                  }
               />
            </div>
            <Menu
               theme="dark"
               mode="inline"
               className="main-side-menu main-menu-icons"
               onClick={onClick}
               items={SideMenuItems}
               style={{ backgroundColor: '#FFF', color: '#00a9e8' }}
            />
         </Sider>
         <Layout className="site-layout">
            <Header
               className="affix-header d-flex justify-content-between align-items-center pe-3 main-menu-icons shadow-sm"
               style={{ padding: 0 }}
            >
               <Button
                  type="text"
                  icon={
                     collapsed ? (
                        <AiOutlineMenuUnfold className="main-icon" />
                     ) : (
                        <AiOutlineMenuFold className="main-icon" />
                     )
                  }
                  onClick={(e) => setCollapsed(!collapsed)}
               />
               <div className="d-flex align-items-center">
                  <Button
                     type="text"
                     icon={<AiOutlineBell />}
                     className="main-icon me-2"
                  />
                  <Dropdown overlay={menu} placement="bottomLeft">
                     <Avatar
                        className="user-avatar shadow-sm"
                        icon={<AiOutlineUser />}
                     />
                  </Dropdown>
               </div>
            </Header>
            <Content>
               <motion.div
                  key={pathname}
                  initial={{ y: '30px', opacity: 0 }}
                  style={{ minHeight: 'calc(100vh - 199px)' }}
                  animate={{ y: 0, opacity: 1 }}
                  className="site-layout-background content-wrap shadow-sm"
               >
                  <Outlet />
               </motion.div>
               <Footer style={{ textAlign: 'center' }}>
                  © 2024 HospiceChoice Rx.
               </Footer>
            </Content>
         </Layout>
      </Layout>
   );
};

export default MasterLayoutComponent;
