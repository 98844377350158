import React, {useCallback, useEffect, useMemo, useState,useRef} from 'react';
import {Checkbox} from 'antd';
import * as ets from "../../utilities/extensions";

const CheckboxGroup = Checkbox.Group;


const CheckList = ({data}) => {
    // const statusUpdate = useRef();
    const {key, items, onChange, defaultState={},status} = data;
    const [checked, setChecked] = useState(key in defaultState ? defaultState[key] : []);

    const onChangeCheck = useCallback((list) => {
            setChecked([...list]);
    }, [checked]);
    
    // statusUpdate.current = status;
    // useEffect(()=>{
    //     return(()=>{
    //         if( statusUpdate.current=== true)
    //         {
    //             setChecked([]);
    //         }
           
    //     })
    // },[ statusUpdate.current]);

    useEffect(() => { ets.isFunction(onChange) && onChange({[key]: checked}); }, [checked, key]);

    useEffect(() => {
        if(key in defaultState){
            setChecked( [...defaultState[key]]);
        }
    }, [defaultState, key]);
   
    const checkItems = useMemo(() => items.map(i=>({label:i.title, value:i.value})), [items]);
    return (
        <CheckboxGroup options={checkItems} value={checked} onChange={onChangeCheck} />
    );
};


export default CheckList;
