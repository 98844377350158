import React, {useState} from 'react';
import {Collapse} from "antd";

const NoneBlock = ({data}) => {
    const {title, type, key} = data;
    const [expanded, setExpanded] = useState(key);
    const handleChange = (accordingId, isExpanded) => {
        setExpanded(isExpanded ? accordingId : "0");
    };
    return (
        <Collapse isExpaned={expanded === key}
                   accordianId={key}
                   onChange={handleChange}>
            <Collapse title={title}>
                The {type} is not supported.
            </Collapse>
        </Collapse>

    );
};


export default NoneBlock;
