import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Button, Tag } from 'antd';
import {
    IoChevronForwardOutline,
    IoHomeSharp,
 } from 'react-icons/all';
 import SessionManageTable from './SessionManageTable';


const SessionManage = () => {

    return (
        <div className="d-flex flex-column">
        <Breadcrumb separator={<IoChevronForwardOutline />}>
           <Breadcrumb.Item key="root">
              <Link to="/">
                 <IoHomeSharp />
              </Link>
           </Breadcrumb.Item>
           <Breadcrumb.Item key="/account-team">
              <Link to="/account-team">Account Team</Link>
           </Breadcrumb.Item>
           <Breadcrumb.Item>Device / Session Revocation</Breadcrumb.Item>
        </Breadcrumb>
        <section className="pt-2 flex-column">
            <SessionManageTable />
         </section>
        </div>
    );
}

export default SessionManage;
