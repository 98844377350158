import { DatePicker, Form, Input, Select } from 'antd';
import React, { ReactNode, CSSProperties } from 'react';
import { Rule } from 'antd/lib/form';
import { REQUIRED_MESSAGE } from '../../utilities/validationMessages';
import moment from 'moment';

export const PatientFormItem: React.FC<{
    name: string;
    label: string;
    required: boolean;
    fieldType: string;
    placeholder: string;
    dataField: string[];
    validator?: (rule: Rule, value: any) => Promise<any>;
    note?: string | ReactNode;
    options?: Array<{ label: string; value: string; original?: any }>;
    onChange?: (
        value: string | React.ChangeEvent<HTMLInputElement>,
    ) => Promise<void>;
    inputStyle?: CSSProperties;
}> = ({
    name,
    label,
    required,
    fieldType,
    placeholder,
    dataField,
    note,
    inputStyle,
    options = [],
    validator,
    onChange,
}) => {
    const fieldName = dataField ? [...dataField, name] : name;
    if (fieldType === 'none') return <></>;

    return (
        // See ant-form-item-extra css style for more information
        <Form.Item
            extra={note}
            style={{
                ...(inputStyle ? inputStyle : {}),
                ...(note ? { marginBottom: 0 } : {}),
            }}
        >
            <label className="custom-label">
                {required ? label + '*' : label}
            </label>
            {fieldType === 'input' && (
                <Form.Item
                    className="custom-div"
                    name={fieldName}
                    rules={[
                        {
                            required: required,
                            message: `${label} is ${REQUIRED_MESSAGE}`,
                        },
                        ...(validator ? [{ validator }] : []),
                    ]}
                    noStyle
                >
                    <Input
                        className="input-field"
                        placeholder={placeholder}
                        {...(onChange ? { onChange: onChange } : {})}
                    />
                </Form.Item>
            )}
            {fieldType === 'dropdown' && (
                <Form.Item
                    className="custom-div"
                    name={fieldName}
                    rules={[
                        {
                            required: required,
                            message: `${label} is ${REQUIRED_MESSAGE}`,
                        },
                        ...(validator ? [{ validator }] : []),
                    ]}
                    noStyle
                >
                    <Select
                        style={inputStyle ? inputStyle : { width: '96%' }}
                        placeholder={`Select ${label}`}
                        options={options}
                        {...(onChange ? { onChange: onChange } : {})}
                    />
                </Form.Item>
            )}
            {fieldType === 'datepicker' && (
                <Form.Item
                    className="custom-div"
                    name={fieldName}
                    rules={[
                        {
                            required: required,
                            message: `${label} is ${REQUIRED_MESSAGE}`,
                        },
                        ...(validator ? [{ validator }] : []),
                    ]}
                    noStyle
                >
                    <DatePicker
                        getPopupContainer={(trigger) =>
                            trigger.parentElement as HTMLElement
                        }
                        className="input-field"
                        placeholder={placeholder}
                        format={'MM-DD-YYYY'}
                    />
                </Form.Item>
            )}
            {fieldType === 'view' && (
                <Form.Item className="custom-div" noStyle shouldUpdate>
                    {({ getFieldValue }) => getFieldValue(fieldName) || ''}
                </Form.Item>
            )}
        </Form.Item>
    );
};
