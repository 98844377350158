import React, { useEffect, useState } from 'react';
import { DatePicker, Select, Form, Button, Modal, Checkbox } from 'antd';
import {
    useGetDurationReasonsQuery,
    useGetGetAllTeamsQuery,
    useGetTeamPatientsMutation,
    useAddPatientConsultMutation,
    useGetConsultNoteTypeOptionsQuery,
} from '../../features/appointment/appointmentService';
import TextArea from 'antd/es/input/TextArea';
import moment from 'moment';
import {
    IsArrayWithLength,
    IsEmptyObject,
    isNotNullOrUndefined,
} from '../../utilities/extensions';
import { bindActionCreators } from '@reduxjs/toolkit';
import { setSelectedAppointment } from '../../features/appointment/appointmentSlice';
import { useDispatch } from 'react-redux';
import { loadingIcon } from '../../utilities/uiExtensions';

const teamPatientDisplay = ({
    firstName = '',
    lastName = '',
    hrxPatientId = '',
}) => `${firstName} ${lastName} (${hrxPatientId})`;

const minDate = moment();

const CreateMeetingForm = ({ clinicalConsult = { id: 0 }, onClosDrawer }) => {
    const dispatch = useDispatch();
    const apACs = bindActionCreators({ setSelectedAppointment }, dispatch);
    const [form] = Form.useForm();
    const [componentSize, setComponentSize] = useState('100%');
    const [teamPatients, setTeamPatients] = useState([]);
    const [disabledSubmit, setDisabledSubmit] = useState(true);

    const { data: { duration = [], reason = [] } = {} } =
        useGetDurationReasonsQuery();
    const { data: { data: teams = [] } = {} } = useGetGetAllTeamsQuery();
    const [getTeamPatients, { isLoading }] = useGetTeamPatientsMutation();
    const { data: options = [], isLoading: isConsultNotesLoading } =
        useGetConsultNoteTypeOptionsQuery();
    const [AddPatientConsult, { isLoading: addInProcess }] =
        useAddPatientConsultMutation();

    const onFormLayoutChange = (changedValues, allValues, size) => {
        setComponentSize(size);
        setDisabledSubmit(
            Object.keys(allValues).find(
                (key) =>
                    key !== 'meetingCompleted' &&
                    key !== 'notes' &&
                    ((key === '' &&
                        IsArrayWithLength(allValues[key]) === false) ||
                        allValues[key] === undefined),
            ),
        );
    };

    const onTeamChanged = async (value, option, name) => {
        if (name === 'hospiceTeamId') {
            setTeamPatients([]);
            form.setFieldsValue({ patientIds: [] });
            const { data } = await getTeamPatients(value).unwrap();
            setTeamPatients(data);
        }
    };

    const onFinish = async (values) => {
        const cData = {
            ...clinicalConsult,
            ...values,
            scheduledDateTime:
                values.scheduledDateTime?.format('YYYY-MM-DD HH:mm a'),
            consultNotes:
                values.consultNotes && Object.keys(values.consultNotes)?.length
                    ? values.consultNotes
                    : null,
        };
        const { id, ...fData } = cData;
        await AddPatientConsult(id === 0 ? fData : cData).unwrap();
        if (cData.meetingCompleted) apACs.setSelectedAppointment({ id: 0 });
        onClosDrawer();
        Modal.success({
            content: 'Consult Information has been successfully saved.',
        });
    };

    useEffect(() => {
        if (!IsEmptyObject(clinicalConsult) && clinicalConsult.id !== 0) {
            const pIds = clinicalConsult.listOfPatients.map((p) => p.key) ?? [];
            const scheduledDateTime =
                clinicalConsult.scheduledDateTime != null
                    ? moment(clinicalConsult.scheduledDateTime)
                    : null;
            onTeamChanged(clinicalConsult.hospiceTeamId, null, 'hospiceTeamId');
            form.setFieldsValue({
                scheduledDateTime,
                minuteDuration: clinicalConsult.duration,
                consultReason: clinicalConsult.consultReason,
                hospiceTeamId: clinicalConsult.hospiceTeamId,
                patientIds: pIds,
                notes: clinicalConsult.notes,
                meetingCompleted: clinicalConsult.meetingCompleted,
                consultNotes: clinicalConsult.consultNotes.reduce((acc, i) => {
                    acc[i.key] = i.value;
                    return acc;
                }, {}),
            });
            setDisabledSubmit(
                IsArrayWithLength(pIds) === false ||
                    isNotNullOrUndefined(scheduledDateTime) === false ||
                    isNotNullOrUndefined(clinicalConsult.consultReason) ===
                        false ||
                    isNotNullOrUndefined(clinicalConsult.hospiceTeamId) ===
                        false,
            );
        }
    }, [clinicalConsult.id]);

    return (
        <Form
            form={form}
            layout="vertical"
            onValuesChange={onFormLayoutChange}
            size={componentSize}
            onFinish={onFinish}
        >
            <Form.Item
                label="Date and Time"
                name="scheduledDateTime"
                rules={[
                    {
                        required: clinicalConsult.id === 0,
                        message: 'Please select Date',
                    },
                ]}
            >
                <DatePicker
                    placeholder="Select Date"
                    className="w-100"
                    showTime
                    use12Hours
                    format="YYYY-MM-DD HH:mm A"
                    allowClear={false}
                    disabledDate={(c) => !c || c.isBefore(minDate)}
                />
            </Form.Item>
            <Form.Item
                label="Duration"
                name="minuteDuration"
                rules={[{ required: true, message: 'Please select Duration' }]}
            >
                <Select
                    placeholder={'Duration'}
                    options={duration.map((d) => ({
                        label: d.key,
                        value: d.value,
                    }))}
                    allowClear={false}
                />
            </Form.Item>
            <Form.Item
                label="Consult Reason"
                name="consultReason"
                rules={[
                    { required: true, message: 'Please select Consult Reason' },
                ]}
            >
                <Select
                    placeholder={'Reason'}
                    options={reason.map((d) => ({
                        label: d.value,
                        value: d.key,
                    }))}
                    allowClear={false}
                />
            </Form.Item>
            <Form.Item
                label="Team"
                name="hospiceTeamId"
                rules={[{ required: true, message: 'Please select Team' }]}
            >
                <Select
                    placeholder={'Team Name'}
                    options={teams.map((d) => ({
                        label: d.name,
                        value: d.teamId,
                    }))}
                    allowClear={false}
                    onSelect={(e, o) => onTeamChanged(e, o, 'hospiceTeamId')}
                />
            </Form.Item>
            <Form.Item
                label="Patient(s)"
                name="patientIds"
                rules={[
                    { required: true, message: 'Please select Patient(s)' },
                ]}
            >
                <Select
                    placeholder={'Patient(s)'}
                    mode="multiple"
                    maxTagCount="responsive"
                    disabled={form.getFieldValue('hospiceTeamId') == null}
                    options={teamPatients.map((d) => ({
                        label: teamPatientDisplay(d),
                        value: d.patientId,
                    }))}
                    allowClear={false}
                />
            </Form.Item>
            {clinicalConsult.meetingCompleted &&
                isConsultNotesLoading &&
                loadingIcon}
            {clinicalConsult.meetingCompleted &&
                !!options.length &&
                options.map((key) => (
                    <Form.Item
                        key={key.value}
                        label={`${key.name} Notes`}
                        name={['consultNotes', `${key.value}`]}
                        rules={[{ required: false }]}
                    >
                        <TextArea placeholder={`${key.name} Notes`} rows={4} />
                    </Form.Item>
                ))}
            {clinicalConsult.canShowMeetingCompleted && (
                <Form.Item
                    name="meetingCompleted"
                    valuePropName="checked"
                    rules={[{ required: false }]}
                >
                    <Checkbox>Consult completed</Checkbox>
                </Form.Item>
            )}
            <Form.Item shouldUpdate className="d-flex justify-content-end">
                {() => (
                    <Button
                        type="primary"
                        htmlType="submit"
                        disabled={disabledSubmit}
                        loading={addInProcess}
                    >
                        Save
                    </Button>
                )}
            </Form.Item>
        </Form>
    );
};

export default CreateMeetingForm;
